import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfirmDialog, DialogContainer, DialogHeader, DialogWrapper, UserCard, UserCardWrapper } from './UserListDialog.styled';

import { EIcon, IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';
import { cn } from '../../../../shared/lib';
import CommonButton from '../../../../shared/ui/Button/ui/CommonButton';
import { IUser } from '../../../../store/redux/user/user.interface';
import { sendMessage } from '../../../../store/redux/websocket/chat-reducer';
import { getCookie } from '../../../../utils/cookies';
import { FlexWithAlign, IconWrapper, Text2Xs } from '../../../../utils/styleUtils';
import { Input, InputType, Text, TextPType, TextType } from '../../../../shared/ui';
import useMatchMedia from '../../../../shared/lib/hooks/useMatchMedia';

interface IProps {
  onClose: () => void;
  userData: IUser[];
  state?: string;
}

const UserListDialog: FC<IProps> = ({ onClose, userData, state }) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [chatName, setChatName] = useState<string>('');
  const isButtonActive = selectedUsers.length > 0;
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCardClick = (user: IUser) => {
    if (user.id !== getCookie('id')) {
      const isSelected = selectedUsers.some((selectedUser) => selectedUser.id === user.id);

      if (isSelected) {
        setSelectedUsers((prevSelectedUsers) => prevSelectedUsers.filter((selectedUser) => selectedUser.id !== user.id));
      } else {
        setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, user]);
      }
    }
  };

  const handleConfirmationClose = () => {
    setSelectedUsers([]);
    setConfirmationOpen(false);
  };

  const handleCreateChat = () => {
    if (isButtonActive && chatName) {
      const withUserId = selectedUsers.map((user) => ({ user_id: user.id }));

      const data = {
        type: 'create_chat',
        data: {
          chat_name: chatName,
          created_by_user_id: getCookie('id'),
          with_user_id: withUserId
        }
      };
      dispatch(sendMessage(JSON.stringify(data)) as any);
      setConfirmationOpen(false);
      setSelectedUsers([]);
      onClose();
    }
  };

  // const handleRename = () => {
  //   if (chatName) {
  //     handleRenameChat({ chat_id: chatIdUpdated, new_name: chatName });
  //     setConfirmationOpen(false);
  //     setSelectedUsers([]);
  //     onClose();
  //   }
  // };
  //
  // useEffect(() => {
  //   setChatName(chatNameUpdate);
  // }, []);

  return (
    <DialogContainer ref={dialogRef}>
      {state === 'rename' ? (
        <DialogWrapper>
          <DialogHeader
            $align={'center'}
            $gap={'10px'}
          >
            <IconWrapper className={'h25 main'}>
              <IconInstance name={EIcon.logomarkwhite} />
            </IconWrapper>
            {/*<PageTextLg className={'ultraDark'}>Изменение названия чата "{chatNameUpdate}"</PageTextLg>*/}
          </DialogHeader>
          <ConfirmDialog>
            <ul>
              {selectedUsers.map((user) => (
                <li key={user.id}>
                  <IconWrapper className={'h22 medium-gray gray'}>
                    <IconInstance name={EIcon.userfilled} />
                  </IconWrapper>
                  <Text2Xs className={'medium'}>{user.fio}</Text2Xs>
                </li>
              ))}
            </ul>
            <Input
              size={InputType.M}
              type='text'
              label='Название чата'
              value={chatName}
              onChange={(e) => setChatName(e.target.value)}
            />
            <FlexWithAlign
              $justify={'flex-end'}
              className={'full'}
            >
              <CommonButton
                className={'unset'}
                typeBtn={'flat'}
                onClick={onClose}
              >
                Отмена
              </CommonButton>
              <CommonButton
                typeBtn={'accent'}
                className={'h50'}
                // onClick={handleRename}
              >
                {t('Создать')}
              </CommonButton>
            </FlexWithAlign>
          </ConfirmDialog>
        </DialogWrapper>
      ) : (
        <DialogWrapper>
          <DialogHeader>
            {isMobile ? null : (
              <Text $tag={TextType.H4}>
                {isConfirmationOpen
                  ? selectedUsers.length === 1
                    ? t('Создание чата')
                    : t('Создание группового чата')
                  : t('Создание чата')}
              </Text>
            )}
          </DialogHeader>

          {isConfirmationOpen ? (
            <ConfirmDialog>
              <Input
                size={InputType.M}
                type='text'
                label='Название чата'
                value={chatName}
                onChange={(e) => setChatName(e.target.value)}
              />
              <ul>
                {selectedUsers.map((user) => (
                  <FlexWithAlign
                    $column
                    $gap={'0'}
                    key={user.id}
                  >
                    <Text
                      $tag={TextType.P}
                      $pSize={TextPType.P15}
                      fontWeight={700}
                    >
                      {user.fio}
                    </Text>

                    <Text
                      $tag={TextType.P}
                      $pSize={TextPType.P13}
                      color={'text02'}
                    >
                      {user.Email}
                    </Text>
                  </FlexWithAlign>
                ))}
              </ul>

              <FlexWithAlign
                $justify={'flex-start'}
                className={cn(isMobile && 'column-reverse', 'full')}
              >
                <CommonButton
                  typeBtn={'gray'}
                  size={'M'}
                  fullWidth={isMobile}
                  onClick={handleConfirmationClose}
                >
                  Отмена
                </CommonButton>
                <CommonButton
                  typeBtn={'accent'}
                  size={'M'}
                  fullWidth={isMobile}
                  disabled={!isButtonActive || chatName === ''}
                  onClick={handleCreateChat}
                >
                  {t('Создать')}
                </CommonButton>
              </FlexWithAlign>
            </ConfirmDialog>
          ) : (
            <FlexWithAlign
              $column
              $gap={'20px'}
            >
              <UserCardWrapper
                $column
                $gap={'0px'}
              >
                {userData
                  .filter((user) => user.id !== getCookie('id'))
                  .map((user) => (
                    <UserCard
                      key={user.id}
                      isSelected={selectedUsers.some((selectedUser) => selectedUser.id === user.id)}
                      onClick={() => handleCardClick(user)}
                    >
                      <span className={cn(selectedUsers.some((selectedUser) => selectedUser.id === user.id) && 'active')}>
                        <IconInstance name={EIcon.success} />
                      </span>
                      <FlexWithAlign
                        $column
                        $gap={'0'}
                      >
                        <Text
                          $tag={TextType.P}
                          $pSize={TextPType.P15}
                          fontWeight={700}
                        >
                          {user.fio}
                        </Text>

                        <Text
                          $tag={TextType.P}
                          $pSize={TextPType.P13}
                          color={'text02'}
                        >
                          {user.Email}
                        </Text>
                      </FlexWithAlign>
                    </UserCard>
                  ))}
              </UserCardWrapper>
              <FlexWithAlign
                $justify={'flex-start'}
                className={cn(isMobile && 'column-reverse')}
              >
                <CommonButton
                  typeBtn={'gray'}
                  size={'M'}
                  fullWidth={isMobile}
                  onClick={onClose}
                >
                  Закрыть
                </CommonButton>
                <CommonButton
                  typeBtn={'primary'}
                  size={'M'}
                  fullWidth={isMobile}
                  onClick={() => setConfirmationOpen(true)}
                  disabled={!isButtonActive}
                >
                  Продолжить
                </CommonButton>
              </FlexWithAlign>
            </FlexWithAlign>
          )}
        </DialogWrapper>
      )}
    </DialogContainer>
  );
};

export default UserListDialog;
