import { ITarif, TarifState } from './tarif.interface';
import { createSlice } from '@reduxjs/toolkit';
import { getHistoryAsync, getMyBalanceAsync, getMyTarifAsync, setMyTarifAsync, verifyPayAsync } from './tarif.actions';
import { PENDING } from '../../../utils/state';

const initialState: TarifState = {
  tarifs: [],
  activeTarif: {} as ITarif,
  confirmationUrl: '',
  state: PENDING,
  paymentState: '',
  balance: 0,
  history: []
};

export const tarifSlice = createSlice({
  name: 'tarif',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyTarifAsync.pending, (state) => {
        state.state = 'PENDING';
      })
      .addCase(getMyTarifAsync.fulfilled, (state, action) => {
        state.activeTarif = action.payload;
        state.state = 'FULFILLED';
      })
      .addCase(getMyTarifAsync.rejected, (state) => {
        state.state = 'REJECTED';
      })
      .addCase(getMyBalanceAsync.pending, (state) => {
        state.state = 'PENDING';
      })
      .addCase(getMyBalanceAsync.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
        state.state = 'FULFILLED';
      })
      .addCase(getMyBalanceAsync.rejected, (state) => {
        state.state = 'REJECTED';
      })
      .addCase(getHistoryAsync.pending, (state) => {
        state.state = 'PENDING';
      })
      .addCase(getHistoryAsync.fulfilled, (state, action) => {
        state.history = action.payload.History;
        state.state = 'FULFILLED';
      })
      .addCase(getHistoryAsync.rejected, (state) => {
        state.state = 'REJECTED';
      })
      .addCase(setMyTarifAsync.pending, (state) => {
        state.paymentState = 'PENDING';
      })
      .addCase(setMyTarifAsync.fulfilled, (state, action) => {
        state.confirmationUrl = action.payload.url;
        state.paymentState = 'FULFILLED';
      })
      .addCase(setMyTarifAsync.rejected, (state) => {
        state.paymentState = 'REJECTED';
      })
      .addCase(verifyPayAsync.pending, (state) => {
        state.paymentState = 'PENDING';
      })
      .addCase(verifyPayAsync.fulfilled, (state) => {
        state.paymentState = 'FULFILLED';
      })
      .addCase(verifyPayAsync.rejected, (state) => {
        state.paymentState = 'REJECTED';
      });
  }
});

export const { reducer } = tarifSlice;
