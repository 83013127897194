import React, { StrictMode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot, Root } from 'react-dom/client';
import './index.css';
import App from './app/App';
import { ErrorBoundary } from './app/providers/ErrorBoundary';
import { ThemeProvider } from './app/providers/ThemeProvider';
import reportWebVitals from './reportWebVitals';
import './utils/lang';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ForceUpdateProvider } from './shared/lib/render/forceUpdate';
import { Loader } from './shared/ui';
import { store } from './store/redux/store';

declare global {
  interface Window {
    MSStream?: any;
  }
}

const root: Root = createRoot(document.getElementById('root') as HTMLElement);

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true
//   });
// }

if (!root) {
  throw new Error('В приложение не вмонтирован root div !!');
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const userAgent = navigator.userAgent;
if (userAgent.toLowerCase().indexOf('android') > -1) {
  document.documentElement.classList.add('android');
} else if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
  document.documentElement.classList.add('ios');
}

root.render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ErrorBoundary>
            <ForceUpdateProvider>
              <ThemeProvider>
                <Suspense fallback={<Loader />}>
                  <App />
                </Suspense>
              </ThemeProvider>
            </ForceUpdateProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </StrictMode>
);
serviceWorkerRegistration.register();
reportWebVitals();
