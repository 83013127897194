import { useQuery } from '@tanstack/react-query';
import { UserService } from '../../../../shared/api/model/service/user.service';
import { USERS_QUERY, USERS_SCHEDULE_QUERY, USER_PROFILE_QUERY, USER_SCHEDULE_QUERY } from '../../../../shared/const/query.const';
import {
  IEmployeeSchedule,
  IUser,
  IUserAllScheduleQueryData,
  IUserData,
  IUserProfileData,
  IUserSchedule,
  IUserScheduleQueryData
} from '../user.interface';

export function useUsersQuery() {
  return useQuery<IUserData, Error, IUser[]>({
    queryKey: [USERS_QUERY],
    queryFn: () => UserService.fetchUsers(),
    select: (data: IUserData | undefined) => data?.users || []
  });
}
export function useEmployeesQuery() {
  return useQuery<IUserData, Error, IUser[]>({
    queryKey: [USERS_QUERY],
    queryFn: () => UserService.fetchUsers(),
    select: (data: IUserData | undefined) => data?.users.filter((user) => user.role !== 'admin') || []
  });
}

export function useProfileUserQuery(id: string | undefined) {
  return useQuery<IUserProfileData, Error, IUser>({
    queryKey: [USER_PROFILE_QUERY, id],
    queryFn: () => UserService.fetchUserById(id),
    select: (data: IUserProfileData) => data.users
  });
}
export function useUserScheduleQuery(values: IUserScheduleQueryData) {
  return useQuery<IUserSchedule[], Error>({
    queryKey: [USER_SCHEDULE_QUERY, values],
    queryFn: () => UserService.fetchUserScheduleForPeriod(values),
    select: (data: IUserSchedule[]) => data || [],
    enabled: !!values.id
  });
}

export function useAllUsersScheduleQuery(values: IUserAllScheduleQueryData) {
  return useQuery({
    queryKey: [USERS_SCHEDULE_QUERY, values],
    queryFn: () => UserService.fetchUserSchedule(values),
    select: (data: IEmployeeSchedule[]) => data.flat() || [],
    enabled: !!values.ids
  });
}
