import React from 'react';

export function SidebarCompany() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M23 21C23 21.2652 22.8946 21.5196 22.7071 21.7071C22.5196 21.8946 22.2652 22 22 22H2C1.73478 22 1.48043 21.8946 1.29289 21.7071C1.10536 21.5196 1 21.2652 1 21C1.00105 20.8255 1.04776 20.6543 1.13547 20.5034C1.22319 20.3525 1.34886 20.2273 1.5 20.14V4.5C1.5 3.83696 1.76339 3.20107 2.23223 2.73223C2.70107 2.26339 3.33696 2 4 2H8.75C9.41304 2 10.0489 2.26339 10.5178 2.73223C10.9866 3.20107 11.25 3.83696 11.25 4.5V20H12.75V6.7C12.75 6.51435 12.8238 6.3363 12.955 6.20503C13.0863 6.07375 13.2643 6 13.45 6H20C20.663 6 21.2989 6.26339 21.7678 6.73223C22.2366 7.20107 22.5 7.83696 22.5 8.5V20.14C22.6511 20.2273 22.7768 20.3525 22.8645 20.5034C22.9522 20.6543 22.9989 20.8255 23 21Z'
        fill='#467FF3'
      />
      <path
        d='M18.6191 17.5H16.6191C16.3539 17.5 16.0996 17.3946 15.912 17.2071C15.7245 17.0196 15.6191 16.7652 15.6191 16.5C15.6191 16.2348 15.7245 15.9804 15.912 15.7929C16.0996 15.6054 16.3539 15.5 16.6191 15.5H18.6191C18.8844 15.5 19.1387 15.6054 19.3262 15.7929C19.5138 15.9804 19.6191 16.2348 19.6191 16.5C19.6191 16.7652 19.5138 17.0196 19.3262 17.2071C19.1387 17.3946 18.8844 17.5 18.6191 17.5Z'
        fill='#467FF3'
      />
      <path
        d='M18.6191 13H16.6191C16.3539 13 16.0996 12.8946 15.912 12.7071C15.7245 12.5196 15.6191 12.2652 15.6191 12C15.6191 11.7348 15.7245 11.4804 15.912 11.2929C16.0996 11.1054 16.3539 11 16.6191 11H18.6191C18.8844 11 19.1387 11.1054 19.3262 11.2929C19.5138 11.4804 19.6191 11.7348 19.6191 12C19.6191 12.2652 19.5138 12.5196 19.3262 12.7071C19.1387 12.8946 18.8844 13 18.6191 13Z'
        fill='#467FF3'
      />
      <path
        d='M7.36914 8.5H5.36914C5.10392 8.5 4.84957 8.39464 4.66203 8.20711C4.4745 8.01957 4.36914 7.76522 4.36914 7.5C4.36914 7.23478 4.4745 6.98043 4.66203 6.79289C4.84957 6.60536 5.10392 6.5 5.36914 6.5H7.36914C7.63436 6.5 7.88871 6.60536 8.07625 6.79289C8.26378 6.98043 8.36914 7.23478 8.36914 7.5C8.36914 7.76522 8.26378 8.01957 8.07625 8.20711C7.88871 8.39464 7.63436 8.5 7.36914 8.5Z'
        fill='#467FF3'
      />
      <path
        d='M7.32992 13H5.41992C5.15471 13 4.90035 12.8946 4.71281 12.7071C4.52528 12.5196 4.41992 12.2652 4.41992 12C4.41992 11.7348 4.52528 11.4804 4.71281 11.2929C4.90035 11.1054 5.15471 11 5.41992 11H7.32992C7.59514 11 7.84949 11.1054 8.03703 11.2929C8.22456 11.4804 8.32992 11.7348 8.32992 12C8.32992 12.2652 8.22456 12.5196 8.03703 12.7071C7.84949 12.8946 7.59514 13 7.32992 13Z'
        fill='#467FF3'
      />
      <path
        d='M7.36914 17.5H5.36914C5.10392 17.5 4.84957 17.3946 4.66203 17.2071C4.4745 17.0196 4.36914 16.7652 4.36914 16.5C4.36914 16.2348 4.4745 15.9804 4.66203 15.7929C4.84957 15.6054 5.10392 15.5 5.36914 15.5H7.36914C7.63436 15.5 7.88871 15.6054 8.07625 15.7929C8.26378 15.9804 8.36914 16.2348 8.36914 16.5C8.36914 16.7652 8.26378 17.0196 8.07625 17.2071C7.88871 17.3946 7.63436 17.5 7.36914 17.5Z'
        fill='#467FF3'
      />
    </svg>
  );
}
