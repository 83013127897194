import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { Icon, LoggedMenuWrapper, Menu, MenuSist, UserBox } from './LoggedMenu.styled';

import { useThemeContext } from '../../../../app/providers/ThemeProvider';
import { Theme } from '../../../../shared/const/theme';
import { cn } from '../../../../shared/lib';
import { useActions } from '../../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../../shared/lib/hooks/useMatchMedia';
import { useOutside } from '../../../../shared/lib/hooks/useOutside';
import { useTypedSelector } from '../../../../shared/lib/hooks/useTypedSelector';
import { Avatar, AvatarRounded, AvatarSize, Text, TextPType, TextType } from '../../../../shared/ui';
// import { Size, Switcher, Type } from '../../../../shared/ui/Switcher';
import { getCookie } from '../../../../utils/cookies';
import { FlexWithAlign } from '../../../../utils/styleUtils';
import { EIcon, IconNew as IconInstance } from '../../../icons/medium-new-icons/icon';
import { useMobileSubmenuData } from '../../sidebar/sidebar.mobile.data';

const LoggedMenu: FC = () => {
  const { ref, isShow, setIsShow } = useOutside(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { submenu } = useMobileSubmenuData();
  const { fetchUserByIdAsync } = useActions();
  const { profileUser } = useTypedSelector((state) => state.user);
  const { activeFilial } = useTypedSelector((state) => state.filial);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const { setTheme, currentTheme } = useThemeContext();
  const toggleTheme = currentTheme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
  const onButtonClick = () => {
    setTheme(toggleTheme);
  };

  useEffect(() => {
    fetchUserByIdAsync(getCookie('id'));
  }, []);

  const name = profileUser?.fio;
  const dolgnost = profileUser?.position;
  const email = profileUser?.Email;

  const { logout } = useActions();
  const logoutHandler = async () => {
    logout();
    await queryClient.invalidateQueries();
  };

  return (
    <LoggedMenuWrapper ref={ref}>
      <UserBox
        onClick={() => setIsShow(!isShow)}
        $gap='13px'
        $align={'center'}
        className={cn(isShow && 'active')}
      >
        <Avatar
          title={profileUser?.fio}
          avatar={profileUser?.avatar}
          size={AvatarSize.S}
          randomBg
          rounded={AvatarRounded.FULL}
        />
        <IconInstance name={EIcon.chevrondownsmall} />
      </UserBox>
      <Menu
        ref={ref}
        className={cn(isShow ? 'open' : 'close')}
      >
        <div className='user'>
          <Avatar
            title={profileUser?.fio}
            avatar={profileUser?.avatar}
            size={AvatarSize.L}
            randomBg
            rounded={AvatarRounded.FULL}
          />
          <FlexWithAlign
            $column
            $gap='0'
          >
            <Text
              $tag={TextType.H5}
              fontWeight={700}
            >
              {name?.split(' ').slice(0, 1).join(' ')}
            </Text>
            <Text
              $tag={TextType.P}
              $pSize={TextPType.P13}
            >
              {email}
            </Text>
          </FlexWithAlign>
        </div>
        <MenuSist $column>
          <div onClick={() => setIsShow(false)}>
            <Link to={'/profile'} />
            <Icon>
              <IconInstance name={EIcon.user} />
            </Icon>
            <Text
              $tag={TextType.P}
              $pSize={TextPType.P15}
              fontWeight={700}
            >
              {t('Личный кабинет')}
            </Text>
          </div>
          {isMobile &&
            submenu.map((item) => (
              <button key={item.title}>
                <Link
                  to={item.to}
                  onClick={() => setIsShow(false)}
                />
                <Icon>{item.icon}</Icon>
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P13}
                >
                  {t(item.title)}
                </Text>
              </button>
            ))}
          <div onClick={() => setIsShow(false)}>
            <Link to={'/management/summary'} />
            <Icon>
              <IconInstance name={EIcon.filial} />
            </Icon>
            <Text
              $tag={TextType.P}
              $pSize={TextPType.P15}
              fontWeight={700}
            >
              {activeFilial?.name || ''}
            </Text>
          </div>
          {/*<div>*/}
          {/*  <Icon>*/}
          {/*    <IconInstance name={EIcon.moon} />*/}
          {/*  </Icon>*/}
          {/*  <Text*/}
          {/*    $tag={TextType.P}*/}
          {/*    $pSize={TextPType.P15}*/}
          {/*    fontWeight={700}*/}
          {/*  >*/}
          {/*    {t('Темная тема')}*/}
          {/*  </Text>*/}
          {/*  <Switcher*/}
          {/*    $type={Type.TOGGLE}*/}
          {/*    $size={Size.M}*/}
          {/*    value={currentTheme !== Theme.LIGHT}*/}
          {/*    setIsValue={onButtonClick}*/}
          {/*  />*/}
          {/*</div>*/}
          <div onClick={logoutHandler}>
            <Link to={'/auth'} />
            <Icon>
              <IconInstance name={EIcon.doorarrowrightoutline} />
            </Icon>
            <Text
              $tag={TextType.P}
              $pSize={TextPType.P15}
              fontWeight={700}
            >
              {t('Выход')}
            </Text>
          </div>
        </MenuSist>
      </Menu>
    </LoggedMenuWrapper>
  );
};

export default LoggedMenu;
