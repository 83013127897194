export enum AppRoutes {
  AUTH = 'auth',
  REGISTER = 'register',
  REGISTER_CONFIRM = 'register-confirm',
  RESET_PASSWORD = 'reset-password',
  NEW_PASSWORD = 'new-password',
  SUCCESS_PASSWORD = 'success-password',
  MAIN = 'main',
  SUPPORT = 'support',
  MARKETING = 'marketing',
  CLIENT = 'client',
  FINANCE = 'finance',
  CASH_REGISTERS = 'cash-registers',
  FINANCE_PRODUCTS = 'finance-products',
  CASH_REGISTERS_SETTINGS = 'cash-registers-settings',
  TASKBOOK = 'taskbook',
  PRODUCT_CABINET = 'product-cabinet',
  PRODUCT_EMPLOYEE = 'product-employee',
  CALL_DIALOG_INIDLETIME = 'call-dialog-inidletime',
  CALL_PRODUCT = 'call-product',
  MANAGEMENT_SUMMARY = 'management-summary',
  MANAGEMENT_SCHEDULE = 'management-schedule',
  MANAGEMENT_SERVICE_CATEGORY = 'management-service-category',
  MANAGEMENT_EMPLOYEE = 'management-employee',
  MANAGEMENT_TIMETABLE = 'management-timetable',
  MANAGEMENT_SETTINGS = 'management-settings',
  EMPLOYEES_SUMMARY = 'employees-summary',
  EMPLOYEES_TIMETABLE = 'employees-timetable',
  ANALYTICS = 'analytics',
  PROFILE = 'profile',
  PORTAL_TARIF = 'portal-tarif',
  PROTAL_REDIRECT_PAYMENT = 'protal-redirect-payment',
  NOT_FOUND = 'not-found',
  MESSENGER = 'messenger',
  MESSENGER_MOBILE_CHAT = 'messenger-mobile-chat',
  MESSENGER_MOBILE_LIST = 'messenger-mobile-list',
  USERRECORDING = 'user-recording',
  USERRECORDINGALL = 'user-recording-all',
  MOREMENU = 'more-menu'
}
