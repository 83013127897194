import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';
import { createSlice } from '@reduxjs/toolkit';
import { SaleproductsState } from './saleproducts.interface';
import { fetchSaleproductsAsync } from './saleproducts.actions';

const initialState: SaleproductsState = {
  saleproduct: [],
  state: PENDING
};

export const saleproductsSlice = createSlice({
  name: 'saleproducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSaleproductsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchSaleproductsAsync.fulfilled, (state, action) => {
        state.saleproduct = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchSaleproductsAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer } = saleproductsSlice;
