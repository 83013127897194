export const getRouteMain = () => '/';
export const getRouteAuth = () => '/auth';
export const getRouteRegister = () => '/registration';
export const getRouteRegisterConfirm = () => '/confirm/:confirmKey';
export const getRouteNewPassword = () => '/password/update/:refreshToken';
export const getRouteSupport = () => '/support';
export const getRouteMarketing = () => '/marketing';
export const getRouteResetPassword = () => '/password/recovery';
export const getRouteSussessPasword = () => '/password/success';
export const getRouteFinance = () => '/finance';
export const getRouteCashRegister = () => '/finance/cash-registers';
export const getRouteCashRegisterSettings = () => '/finance/payments';
export const getRouteManagementProducts = () => '/management/products';
export const getRouteProductCabinets = () => '/products/cabinets/:productsPageType';
export const getRouteProductEmployee = () => '/products/employee/:productsPageType';
export const getRouteClient = () => '/clients';
export const getRouteTaskbook = () => '/taskbook/:taskPageType';
export const getRouteCallDialog = () => '/call/dialog/inidletime';
export const getRouteCallProduct = () => '/call/product';
export const getRouteManagementSummary = () => '/management/summary';
export const getRouteManagementSchedule = () => '/management/schedule';
export const getRouteManagementServiceCategory = () => '/management/service-categories';
export const getRouteManagementEmployee = () => '/management/employee';
export const getRouteManagementTimetable = () => '/management/timetable';
export const getRouteManagementSettings = () => '/management/settings';
export const getRouteEmployeesSummary = () => '/employees';
export const getRouteEmployeesTimetable = () => '/timetable';
export const getRouteAnalytics = () => '/analytics/:analyticsId';
export const getRouteProfile = () => '/profile';
export const getRoutePortalTarif = () => '/portalsettings/tarif';
export const getRoutePortalRedirectPayment = () => '/portalsettings/redirectpaymentpage';
export const getRouteNotFound = () => '*';
export const getRouteMessenger = () => '/messenger/:roomId';
export const getRouteMessengerList = () => '/messenger-list';
export const getRouteMessengerChat = () => '/messenger-list/:roomId';
export const getRouteUserRecording = () => '/user-recording/:date/:time/:master/:service/:tarif';
export const getRouteUserRecordingAll = () => '/user-recording-all/:id/:filial_id';
export const getMoreMenu = () => '/more'
