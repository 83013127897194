import styled from 'styled-components';

import { FlexWithAlign } from '../../../../utils/styleUtils';
import { scaleIn, scaleOut } from '../../../shared/modal/create/service/sidebar/CreateServiceSidebar.styled';
import {
  DURATION,
  MODAL_GAP,
  MODAL_TEXT_GAP,
  RADIUS_M,
  RADIUS_S,
  SHADOW,
  SHADOW_INPUT, SHADOW_INPUT_HOGER,
  Z_INDEX
} from '../../../../styles';

export const LoggedMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
`;
export const UserBox = styled(FlexWithAlign)`
  svg {
    transition: 0.3s ease;
    color: ${(props) => props.theme.color.primary};
  }
  &:hover,
  &.active {
    svg {
      transform: translateY(3px);
    }
  }
`;

export const Menu = styled.ul`
  display: flex;
  padding: 2rem;
  gap: ${MODAL_GAP};
  flex-direction: column;
  position: absolute;
  transform-origin: top right;
  top: calc(100% + 8px);
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  right: 20px;
  border-radius: ${RADIUS_S};
  z-index: 10;
  box-shadow: ${SHADOW};
  background: ${(props) => props.theme.color.elevation01};
  &.open {
    animation: ${scaleIn} .3s ease;
    visibility: visible;
  }
  &.close {
    animation: ${scaleOut} .3s ease;
    visibility: hidden;
    transition: .3s ease;
    >* {
      visibility: hidden;
    }
  }
  
    .user {
      display: flex;
      gap: 12px;
      align-items: center;
      .icon {
        width: 50px;
        height: 50px;
      }
      div {
        div {
          width: 50px;
          height: 50px;
          background: ${(props) => props.theme.color.mainLight};
        }
      }
    }
    
  }
`;
export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => props.theme.text.text02};
  }
  .icon {
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 50%;
    border: 1.5px solid ${(props) => props.theme.color.secondaryDark};
    &:before {
      content: '';
      border-radius: 50%;
      width: 8px;
      height: 8px;
      border: 1.5px solid ${(props) => props.theme.color.secondaryDark};
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
`;

export const MenuSist = styled(FlexWithAlign)`
  > div {
    box-shadow: ${SHADOW_INPUT};
    background: ${(props) => props.theme.color.inverse};
    border-radius: ${RADIUS_M};
    padding: 1rem;
    display: flex;
    gap: ${MODAL_TEXT_GAP};
    position: relative;
    transition-property: background, box-shadow;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    &:hover{
      box-shadow: ${SHADOW_INPUT_HOGER};
      background: ${(props) => props.theme.color.base02};
    }
    > a {
      position: absolute;
      inset: 0;
      z-index: ${Z_INDEX};
      width: 100%;
      height: 100%;
    }
  }
`;
