import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { useFormik } from 'formik';

import { Box, ColorClassifInput, ColoredCircle, NameClassifInput, Wrapper } from './CreateServiceCategoryModal.styled';

import { ModalGrid } from '../../../../../../pages/Recording/lib/CommonRecording.styled';
import { cn } from '../../../../../../shared/lib';
import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../../../../shared/lib/hooks/useMatchMedia';
import { Input, InputType, Text, TextPType, TextType } from '../../../../../../shared/ui';
import DinamycHeight from '../../../../../../shared/ui/AutoHeight/DinamycHeight';
import CommonButton from '../../../../../../shared/ui/Button/ui/CommonButton';
import Dropdown from '../../../../../../shared/ui/Dropdown/ui/Dropdown';
import { useAddClassificator, useDeleteClassificator } from '../../../../../../store/redux/classificators/hooks/useClassificatorsMutation';
import { useClassificatorsQuery } from '../../../../../../store/redux/classificators/hooks/useClassificatorsQuery';
import { useCreateProduct, useUpdateProduct } from '../../../../../../store/redux/products/hooks/useProductsMutation';
import { MODAL_GAP, MODAL_TEXT_GAP } from '../../../../../../styles';
import { FlexWithAlign, FormStyle } from '../../../../../../utils/styleUtils';
import { EIcon, IconNew as IconInstance } from '../../../../../icons/medium-new-icons/icon';
import { DinamycWrapper } from '../../recording/group/CreateRecordingGroupModal.styled';
import { ButtonDelete } from '../../../../../../styles/table.styled';
import { useProductsQuery } from '../../../../../../store/redux/products/hooks/useProductsQuery';

interface IProps {
  modalPayload?: any;
  edit?: boolean;
  closeModal: () => void;
}

const colors = [
  {
    value: 'rgba(54, 130, 219, 1)',
    label: 'Синий',
    color: 'rgba(54, 130, 219, 1)'
  },
  {
    value: 'rgba(47, 173, 150, 1)',
    label: 'Зеленый',
    color: 'rgba(47, 173, 150, 1)'
  },
  {
    value: 'rgba(252, 187, 20, 1)',
    label: 'Желтый',
    color: 'rgba(252, 187, 20, 1)'
  },
  {
    value: 'rgba(184, 71, 78, 1)',
    label: 'Красный',
    color: 'rgba(184, 71, 78, 1)'
  },
  {
    value: 'rgba(123, 67, 158, 1)',
    label: 'Фиолетовый',
    color: 'rgba(123, 67, 158, 1)'
  }
];

const ClassifierItem: React.FC<{ name: string; color: string | undefined; onRemove: () => void; className?: string }> = ({
  name,
  color,
  onRemove,
  className
}) => (
  <ModalGrid
    key={name}
    className={className}
  >
    <NameClassifInput>{name}</NameClassifInput>
    <ColorClassifInput>
      <span style={{ background: color }}></span>
      <p>
        {color === 'rgba(54, 130, 219, 1)'
          ? 'Синий'
          : color === 'rgba(47, 173, 150, 1)'
          ? 'Зеленый'
          : color === 'rgba(252, 187, 20, 1)'
          ? 'Желтый'
          : color === 'rgba(184, 71, 78, 1)'
          ? 'Красный'
          : color === 'rgba(123, 67, 158, 1)' && 'Фиолетовый'}
      </p>
    </ColorClassifInput>
    <ButtonDelete
      type='button'
      onClick={(e) => {
        e.preventDefault();
        onRemove();
      }}
      className='flex'
    >
      <IconInstance name={EIcon.trash} />
    </ButtonDelete>
  </ModalGrid>
);

const CreateServiceCategoryModal: React.FC<IProps> = (props) => {
  const { modalPayload: serviceCategory, edit, closeModal } = props;
  const { data: products, refetch } = useProductsQuery();
  const { mutateAsync: createProduct, isPending } = useCreateProduct();
  const { mutateAsync: addClassificator } = useAddClassificator();
  const { mutateAsync: deleteClassificator } = useDeleteClassificator();
  const { mutateAsync: updateProduct } = useUpdateProduct();
  const [isDuplicateClassifier, setIsDuplicateClassifier] = useState(false);
  const { fetchProductsAsync } = useActions();
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const { data: classificators } = useClassificatorsQuery();
  const [classifiers, setClassifiers] = useState<any>([]);
  const { t } = useTranslation();
  const [classifierColors, setClassifierColors] = useState<string[]>([]);
  const [classifierName, setClassifierName] = useState('');
  const [classifierColor, setClassifierColor] = useState('');
  const [tempClassifiers, setTempClassifiers] = useState<any>([]);
  const [pending, setPending] = useState(false);
  const [nameError, setNameError] = useState(false);

  const [formValid, setFormValid] = useState(false);

  const productEdit = products?.filter((item) => item.id === serviceCategory?.id)[0];

  const [isOpen, setIsOpen] = useState(productEdit && productEdit.id ? true : false);
  useEffect(() => {
    if (productEdit?.id) {
      setTempClassifiers(
        classificators
          ?.filter((item) => item.productid === productEdit?.id)
          ?.map((item) => ({
            ...item,
            name: item.name.replace('#', '')
          }))
      );
    }
  }, [classificators]);
  const addClassifier = (e: any) => {
    e.preventDefault();
    if (classifiers.length < 5) {
      const newClassifier = { name: classifierName, color: classifierColor, productid: '' };
      if (
        (tempClassifiers || []).some(
          (existingClassifier: { name: string; color: string }) =>
            existingClassifier.name === newClassifier.name && existingClassifier.color === newClassifier.color
        )
      ) {
        setIsDuplicateClassifier(true);
        toast(t('Этот классификатор уже добавлен.'));

        return;
      }

      if ((tempClassifiers || []).some((tempItem: { name: string }) => tempItem.name === newClassifier.name)) {
        setIsDuplicateClassifier(true);
        toast(t('Этот классификатор уже добавлен.'));

        return;
      }
      setTempClassifiers((prevTempClassifiers: any) => (prevTempClassifiers || []).concat(newClassifier));

      setClassifiers([...classifiers, classifierName]);
      setClassifierColors([...classifierColors, classifierColor]);
      setClassifierName('');
      setIsDuplicateClassifier(false);
      setClassifierColor('');
    } else {
      toast(t('Вы можете добавить не более 5 классификаторов.'));
    }
  };

  const initialValues = {
    id: '',
    name: '',
    comment: ''
  };

  const isClassificatorUnique = (name: string, productid: string) => {
    return !classificators?.some(
      (item) =>
        ((item.productid === productid || item.productid === null) && item.name === name) ||
        (tempClassifiers &&
          tempClassifiers.some(
            (tempItem: { productid: string; name: string }) => tempItem.productid === productid && tempItem.name === name
          ))
    );
  };

  const formik = useFormik({
    initialValues: productEdit
      ? {
          id: productEdit.id,
          name: productEdit.name
        }
      : initialValues,
    onSubmit: (values: any) => {}
  });

  useEffect(() => {
    if (formik.values.name) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formik.values]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!formValid || isPending) {
      if (formik.values.name === '') {
        setNameError(true);
      }

      return;
    }
    setPending(true);

    try {
      let productId = '';
      if (edit) {
        const res = await updateProduct(formik.values);
        productId = formik.values.id;
        if (res.status === 'ok') {
          toast.success(t('Категория успешно обновлена'));
        }
      } else {
        const res = await createProduct(formik.values);
        productId = res.id;
        if (res.status === 'ok') {
          toast.success(t('Категория успешно Добавлена'));
        }
      }
      if (tempClassifiers) {
        setClassifiers([...classifiers, ...tempClassifiers]);
        let totalClassifiers = [];
        if (classifierName && classifierColor) {
          totalClassifiers = [...tempClassifiers, { name: classifierName, color: classifierColor, productid: '' }];
        } else {
          totalClassifiers = tempClassifiers;
        }
        const isUnique = totalClassifiers.every(async (classifier: { name: string; color: string }) => {
          const classificatorsEvent = {
            name: `#${classifier.name}`,
            productid: productId,
            color: classifier.color
          };
          if (!isClassificatorUnique(classifier.name, productId)) {
            return false;
          }
          const res = await addClassificator(classificatorsEvent);
          if (res.status === 'ok') {
            toast.success(t('Классификатор успешно добавлен'));
          }

          return true;
        });
        if (isUnique) {
          setTempClassifiers([]);
        }
      }
      await refetch();
      closeModal?.();
    } catch (error: any) {
      setPending(false);
      toast.error(error.message || t('Произошла ошибка'));
    }
  };

  const removeClassifier = async (classifierName: string) => {
    const filteredClassificator = classificators?.find((item) => item.name === `#${classifierName}`)?.id;
    setTempClassifiers(tempClassifiers.filter((classifier: { name: string }) => classifier.name !== classifierName));
    if (filteredClassificator) {
      const response = await deleteClassificator(filteredClassificator);
      if (response.status === 'ok') {
        toast.success(t('Классификатор удален'));
      } else {
        toast.error(response.description || t('Произошла ошибка'));
      }
    }
  };

  useEffect(() => {
    if (nameError) {
      formik.values.name !== '' && setNameError(false);
    }
  }, [formik.values.name]);

  return (
    <Wrapper>
      <FormStyle onSubmit={handleSubmit}>
        {isMobile ? null : (
          <Text
            $tag={TextType.H5}
            fontWeight={700}
          >
            {edit ? `${t('Редактирование категории')}` : t('Добавить категорию услуг')}
          </Text>
        )}

        <Box className='form'>
          <Input
            label={t('Название*')}
            value={formik.values.name}
            onChange={formik.handleChange}
            name='name'
            error={nameError && 'Введите название'}
          />

          {productEdit && productEdit.id ? (
            <Text
              $tag={TextType.P}
              $pSize={TextPType.P19}
              color={'text02'}
              fontWeight={700}
            >
              {t('Классификатор')}
            </Text>
          ) : (
            <div className='inviteemployeer'>
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P13}
              >
                {t('Добавить классификатор')}
              </Text>
              <input
                type='checkbox'
                name='offline'
                value={'false'}
                onChange={() => setIsOpen((prev) => !prev)}
                onBlur={formik.handleBlur}
                className='inviteemployeer-input'
                checked={isOpen}
              />
              <span className={cn(isOpen && 'active')}>
                <IconInstance name={EIcon.success} />
              </span>
            </div>
          )}
          <DinamycWrapper>
            <DinamycHeight>
              <FlexWithAlign
                $column
                $gap={MODAL_GAP}
              >
                {isOpen && (
                  <>
                    {tempClassifiers
                      ?.filter((item: { productid: string }) => item.productid === productEdit?.id || item.productid === '')
                      ?.map((tempClassifier: { name: string; color: string }, index: number) => (
                        <ClassifierItem
                          key={index}
                          name={tempClassifier.name}
                          color={tempClassifier.color}
                          onRemove={() => removeClassifier(tempClassifier.name)}
                          className={'classificator'}
                        />
                      ))}
                    <FlexWithAlign
                      $align={'center'}
                      $gap={MODAL_GAP}
                    >
                      <Input
                        size={InputType.M}
                        label={isMobile ? t('Классификатор') : t('Напишите классификатор')}
                        value={classifierName}
                        onChange={(e) => setClassifierName(e.target.value)}
                      />
                      <Dropdown
                        position={!isMobile ? 'top' : 'bottom'}
                        size={InputType.M}
                        key={classifierColors.join('-')}
                        className='selectedService'
                        getOptionLabel={(option) => (
                          <FlexWithAlign
                            $align={'center'}
                            $gap={'8px'}
                          >
                            <ColoredCircle
                              style={{ background: option.color }}
                              className='additionalColor'
                            ></ColoredCircle>

                            <span className={'dropdownLabel'}>{option.label}</span>
                          </FlexWithAlign>
                        )}
                        options={colors}
                        onChange={(selected: any) => {
                          setClassifierColor(selected.value);
                        }}
                        currentValue={classifierColor || ''}
                        labelTitle={isMobile ? t('Цвет') : t('Цвет классификатора')}
                      />
                    </FlexWithAlign>

                    <FlexWithAlign
                      $align={'center'}
                      $justify={'flex-end'}
                    >
                      <CommonButton
                        disabled={classifiers.length >= 5 || !classifierName || !classifierColor}
                        onClick={addClassifier}
                        size={'M'}
                        typeBtn={'flat'}
                        gap={MODAL_TEXT_GAP}
                      >
                        <IconInstance name={EIcon.plus} />
                        {t('Добавить классификатор')}
                      </CommonButton>
                    </FlexWithAlign>
                  </>
                )}
              </FlexWithAlign>
            </DinamycHeight>
          </DinamycWrapper>

          <FlexWithAlign
            $align='center'
            $justify='flex-start'
            className={cn(isMobile && 'column-reverse full')}
            $gap={MODAL_GAP}
          >
            <CommonButton
              typeBtn='gray'
              size={'M'}
              onClick={(e: any) => {
                e.preventDefault();
                closeModal();
              }}
              fullWidth={isMobile}
            >
              {t('Отмена')}
            </CommonButton>
            <CommonButton
              typeBtn='primary'
              size={'M'}
              fullWidth={isMobile}
            >
              {edit ? t('Сохранить') : t('Добавить')}
            </CommonButton>
          </FlexWithAlign>
        </Box>
      </FormStyle>
    </Wrapper>
  );
};

export default CreateServiceCategoryModal;
