import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { Wrapper } from './CreateFinancePaymentModal.styled';

import { cn } from '../../../../../../shared/lib';
import CommonButton from '../../../../../../shared/ui/Button/ui/CommonButton';
import { apiPost, apiPut } from '../../../../../../utils/apiInstance';
import { FlexWithAlign, FormStyle } from '../../../../../../utils/styleUtils';
import { validationServiceWithGroupSchema } from '../../../../../../utils/validation-input';
import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import { EIcon, IconNew as IconInstance } from '../../../../../icons/medium-new-icons/icon';
import Dropdown from '../../../../../../shared/ui/Dropdown/ui/Dropdown';
import { paymentpurposeDown } from '../finance-payment-expenses/finance-payments.data';
import { Input, InputType, Text, Textarea, TextPType, TextType } from '../../../../../../shared/ui';
import { CustomCheckboxWrap } from '../../employeers/CreateEmployeersModal.styled';
import { Controls } from '../../../../../../shared/ui/TopTabs/ui/TopTabs.styled';
import useMatchMedia from '../../../../../../shared/lib/hooks/useMatchMedia';

interface IProps {
  closeModal?: () => void;
  edit?: boolean;
  modalPayload?: any;
}

const CreateFinancePaymentModal: React.FC<IProps> = (props) => {
  const { closeModal, modalPayload } = props;
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [productError, setProductError] = useState(false);
  const [kontragentError, setKontragentError] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const { user } = useTypedSelector((state1) => state1.user);
  const { fetchUsersAsync, fetchSalesAsync, fetchSaleproductsAsync, fetchListKassaAsync, fetchClientsAsync } = useActions();
  const { saleproduct } = useTypedSelector((state) => state.saleproducts);
  const { clients, state } = useTypedSelector((state) => state.clients);
  const { kassa } = useTypedSelector((state) => state.finance);
  const { financeActiveDate } = useTypedSelector((state1) => state1.calendar);
  const formatedDate = dayjs(financeActiveDate).format('YYYY-MM-DD');
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const getSalesByDates = (dates: string) => {
    fetchSalesAsync(dates);
  };

  useEffect(() => {
    fetchSaleproductsAsync();
    fetchClientsAsync();
    fetchUsersAsync();
    fetchListKassaAsync();
  }, []);
  useEffect(() => {
    if (user && clients) {
      const combinedOptions = [...user, ...clients];
      setOptions(combinedOptions);
    }
  }, [state]);

  const initialValues = {
    kassa: '',
    comment: '',
    price: 0,
    product: '',
    type: 'product',
    kontragent: '',
    method: 'Доход',
    name: modalPayload?.name
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
    validateOnMount: true,
    validationSchema: validationServiceWithGroupSchema
  });

  useEffect(() => {
    formik.setFieldValue('kassa', modalPayload?.id);
  }, []);

  useEffect(() => {
    formik.setFieldValue('price', saleproduct?.find((item) => item.id === formik.values.product)?.price);
  }, [formik.values.product]);

  const createKassa = async (values: any) => {
    setPending(true);
    const res = await apiPut('/sales', values);
    if (res?.status === 200) {
      closeModal!();
      fetchListKassaAsync();
      await getSalesByDates(formatedDate);
      toast.success('Платеж успешно проведен!');
    } else {
      setPending(false);
      toast.error(res.data.description);
    }
  };

  const editKassa = async (values: any) => {
    setPending(true);
    const res = await apiPost(`/sales/expenses`, values);
    if (res?.status === 200) {
      closeModal!();
      toast.success('Платеж успешно проведен!');
      fetchListKassaAsync();
      await getSalesByDates(formatedDate);
    } else {
      setPending(false);
      toast.error('Произошла ошибка при выполнении платежа, повторите попытку!');
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      !formik.values.product ||
      formik.values.kontragent === '' ||
      formik.values.price === 0 ||
      formik.values.price === undefined ||
      pending
    ) {
      if (!formik.values.product) {
        setProductError(true);
      }
      if (formik.values.kontragent === '') {
        setKontragentError(true);
      }
      if (formik.values.price === 0 || formik.values.price === undefined) {
        setPriceError(true);
      }
      return;
    }

    if (formik.values.method === 'Расход') {
      editKassa(formik.values);
    } else {
      createKassa(formik.values);
    }
  };
  useEffect(() => {
    if (productError) {
      formik.values.product !== '' && setProductError(false);
    }
  }, [formik.values.product]);
  useEffect(() => {
    if (kontragentError) {
      formik.values.kontragent !== '' && setKontragentError(false);
    }
  }, [formik.values.kontragent]);
  useEffect(() => {
    if (priceError) {
      (formik.values.price !== 0 || formik.values.price !== undefined) && setPriceError(false);
    }
  }, [formik.values.price]);

  return (
    <Wrapper>
      {isMobile ? (
        <Controls className={'modal'}>
          <Text
            $tag={TextType.P}
            $pSize={TextPType.P17}
            fontWeight={700}
          >
            {t('Новый платеж')}
          </Text>
          <button onClick={closeModal}>
            <IconInstance name={EIcon.close} />
          </button>
        </Controls>
      ) : (
        <Text
          $tag={TextType.H5}
          fontWeight={700}
        >
          {t('Новый платеж')}
        </Text>
      )}
      <FormStyle onSubmit={handleSubmit}>
        <FlexWithAlign>
          <CustomCheckboxWrap
            onClick={(e) => {
              e.preventDefault();
              formik.setFieldValue('method', 'Доход');
              formik.setFieldValue('comment', '');
              formik.setFieldValue('price', parseInt(''));
              formik.setFieldValue('product', '');
              formik.setFieldValue('kontragent', '');
            }}
          >
            <div className={cn('check-wrapper', formik.values.method === 'Доход' && 'active')}>
              <span></span>
            </div>
            <FlexWithAlign
              $column
              $align={'flex-start'}
              $gap={'0'}
            >
              {!isMobile && (
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P11}
                  color={'text02'}
                >
                  {t('Приход в кассу')}
                </Text>
              )}
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P13}
              >
                {t('Поступление')}
              </Text>
            </FlexWithAlign>
          </CustomCheckboxWrap>
          <CustomCheckboxWrap
            onClick={(e) => {
              e.preventDefault();
              formik.setFieldValue('method', 'Расход');
              formik.setFieldValue('comment', '');
              formik.setFieldValue('price', parseInt(''));
              formik.setFieldValue('product', '');
              formik.setFieldValue('kontragent', '');
            }}
          >
            <div className={cn('check-wrapper', formik.values.method === 'Расход' && 'active')}>
              <span></span>
            </div>
            <FlexWithAlign
              $column
              $align={'flex-start'}
              $gap={'0'}
            >
              {!isMobile && (
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P11}
                  color={'text02'}
                >
                  {t('Расходная операция')}
                </Text>
              )}
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P13}
              >
                {t('Расход')}
              </Text>
            </FlexWithAlign>
          </CustomCheckboxWrap>
        </FlexWithAlign>
        <Dropdown
          size={InputType.M}
          onChange={(option: any) => {
            formik.setFieldValue('kassa', option.value);
          }}
          className={'kassa'}
          onBlur={formik.handleBlur}
          options={kassa}
          getOptionLabel={(option) => (
            <FlexWithAlign
              $align={'center'}
              $gap={'4px'}
            >
              <span className={'dropdownLabel'}>{option.label}</span>
              <span>
                {new Intl.NumberFormat('ru-RU', {
                  style: 'currency',
                  currency: 'RUB'
                }).format(parseInt(option.additional?.summa))}
              </span>
            </FlexWithAlign>
          )}
          currentValue={kassa.find((item) => item.id === formik.values.kassa)?.name || formik.values.kassa}
          labelTitle={t('Касса')}
          formik={formik}
        ></Dropdown>
        {formik.values.method === 'Доход' ? (
          <Dropdown
            size={InputType.M}
            onChange={(option: any) => {
              formik.setFieldValue('kontragent', option.value);
            }}
            error={kontragentError && 'Выберите покупателя'}
            key={clients?.find((item) => item.name === formik.values.kontragent)?.name || formik.values.kontragent}
            onBlur={formik.handleBlur}
            options={clients}
            currentValue={clients?.find((item) => item.name === formik.values.kontragent)?.name || formik.values.kontragent}
            labelTitle={t('Покупатель')}
            formik={formik}
          />
        ) : formik.values.method === 'Расход' ? (
          <Dropdown
            size={InputType.M}
            onChange={(option: any) => {
              formik.setFieldValue('kontragent', option.value);
            }}
            error={kontragentError && 'Выберите контрагента'}
            key={user?.find((item) => item.fio === formik.values.kontragent)?.fio || formik.values.kontragent}
            onBlur={formik.handleBlur}
            currentValue={user?.find((item) => item.fio === formik.values.kontragent)?.fio || formik.values.kontragent}
            options={user}
            labelTitle={t('Контрагенты')}
            formik={formik}
          />
        ) : (
          <Input
            label={t('Название платежа')}
            value={''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          >
            <IconInstance name={EIcon.billheadoutline} />
            <IconInstance name={EIcon.arrowdown} />
          </Input>
        )}

        {formik.values.method === 'Доход' ? (
          <Dropdown
            size={InputType.M}
            onChange={(option: any) => {
              formik.setFieldValue('product', option.value);
            }}
            onBlur={formik.handleBlur}
            options={saleproduct}
            currentValue={saleproduct?.find((item) => item.name === formik.values.product)?.name || formik.values.product}
            labelTitle={t('Товар')}
            error={productError && 'Выберите товар'}
            formik={formik}
            key={'1'}
          />
        ) : formik.values.method === 'Расход' ? (
          <Dropdown
            size={InputType.M}
            onChange={(option: any) => {
              formik.setFieldValue('product', option.value);
              formik.setFieldValue('price', parseInt(''));
            }}
            key={'2'}
            onBlur={formik.handleBlur}
            options={paymentpurposeDown}
            error={productError && 'Выберите назначение платежа'}
            currentValue={paymentpurposeDown.find((item) => item.label === formik.values.product)?.label || formik.values.product}
            labelTitle={t('Название платежа')}
            formik={formik}
          />
        ) : (
          <Input
            label={t('Название платежа')}
            value={''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          >
            <IconInstance name={EIcon.billheadoutline} />
            <IconInstance name={EIcon.arrowdown} />
          </Input>
        )}
        <Input
          key={'price'}
          label={formik.values.price !== parseInt('') ? t('Сумма') : ''}
          type={'currency'}
          value={formik.values.price}
          max={9999999}
          error={priceError && 'Введите сумму платежа'}
          onChange={formik.handleChange}
          name='price'
        />
        <FlexWithAlign className='formElement textarea'>
          <Textarea
            placeholder={'Комментарий'}
            content={formik.values.comment}
            onChange={formik.handleChange}
            name='comment'
          />
        </FlexWithAlign>
        <FlexWithAlign $justify={'flex-start'}>
          <CommonButton
            typeBtn='gray'
            size={'M'}
            type='submit'
            onClick={(e) => {
              e.preventDefault();
              closeModal?.();
            }}
          >
            {t('Отменить')}
          </CommonButton>
          <CommonButton
            typeBtn='primary'
            size={'M'}
            type='submit'
          >
            {t('Сохранить')}
          </CommonButton>
        </FlexWithAlign>
      </FormStyle>
    </Wrapper>
  );
};

export default CreateFinancePaymentModal;
