import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CabinetsState, ICabinet } from './cabinets.interface';
import { FULFILLED, INIT, PENDING, REJECTED } from '../../../utils/state';
import { fetchCabinetsAsync, setActiveCabinet, setCabinets, setCabinetsState } from './cabinets.actions';
import { sort } from 'rambda';

const initialState: CabinetsState = {
  cabinets: [],
  state: INIT,
  activeCabinet: undefined
};

export const cabinetsSlice = createSlice({
  name: 'cabinets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setCabinets, (state, action: PayloadAction<ICabinet[]>) => {
        state.cabinets = action.payload;
      })
      .addCase(setCabinetsState, (state, action: PayloadAction<CabinetsState>) => {
        state.state = action.payload.state;
        if (action.payload.cabinets) {
          state.cabinets = action.payload.cabinets;
        }
      })
      .addCase(setActiveCabinet, (state, action: PayloadAction<ICabinet>) => {
        state.activeCabinet = action.payload;
      })
      .addCase(fetchCabinetsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchCabinetsAsync.fulfilled, (state, action) => {
        const sorted = sort((a: any, b: any) => a.id - b.id, action.payload);

        state.cabinets = sorted;
        state.activeCabinet = state.cabinets[0];
        state.state = FULFILLED;
      })
      .addCase(fetchCabinetsAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer } = cabinetsSlice;
