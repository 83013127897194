import React, { FC, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { HeaderInfo, OptionItem, SettingsButton, SettingsOption } from './MessengerHeader.styled';
import LogoImg from '../../../../../assets/images/logo.png';
import TelegramImg from '../../../../../assets/images/telegram.png';

import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { sendMessage } from '../../../../../store/redux/websocket/chat-reducer';
import { firstTwoLetters, getColorForSymbol } from '../../../lib/messenger.helper';
import { EIcon, IconNew as IconInstance } from '../../../../../components/icons/medium-new-icons/icon';
import { useOutside } from '../../../../../shared/lib/hooks/useOutside';
import { cn, useModalActions } from '../../../../../shared/lib';
import { Input, Modal, ModalSize, Text, TextPType, TextType } from '../../../../../shared/ui';
import { CircleWrapper } from '../../../../../styles/circles.styled';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { MODAL_GAP } from '../../../../../styles';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import { useTranslation } from 'react-i18next';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';

const MessengerHeader: FC = () => {
  const { roomId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userChats } = useTypedSelector((state) => state.chat);
  const { activeFilial } = useTypedSelector((state) => state.filial);
  const findChat = userChats.find((item) => item.chat_id === roomId);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const { ref, isShow, setIsShow } = useOutside(false);
  const { setChatRoomId, setOpendialog } = useActions();
  const [value, setValue] = useState(findChat?.chat_name);
  const nav = useNavigate();
  const modalRef = useRef(null);

  const [isModal, setIsModal] = useState(false);

  const onShowModal = useCallback(() => {
    setIsModal(true);
  }, []);
  const { onCloseModal } = useModalActions(setIsModal);

  useEffect(() => {
    setValue(findChat?.chat_name);
  }, [userChats]);

  const sendDeleteChat = (chat_id: string | undefined) => {
    if (chat_id) {
      dispatch(sendMessage(JSON.stringify({ type: 'delete_chat', data: { chat_id } })) as any);
      nav('/messenger/telebot');
      if (activeFilial) {
        setChatRoomId('telebot');
        dispatch(sendMessage(JSON.stringify({ type: 'get_records', data: { filial_id: activeFilial.id } })) as any);
      }
    }
  };
  const sendRenameChat = () => {
    dispatch(sendMessage(JSON.stringify({ type: 'rename_chat', data: { chat_id: findChat?.chat_id, new_name: value } })) as any);
    onCloseModal();
  };

  function getCountUsers() {
    let number = findChat ? findChat.users.length : 0;
    number = Math.abs(number) % 100;
    const remainder = number % 10;

    if (findChat?.is_tech_support === true || roomId === 'support' || roomId === 'telebot') {
      return <h5> </h5>;
    }

    if (number > 10 && number < 20) {
      return <h5>{number} Пользователей</h5>;
    }

    if (remainder > 1 && remainder < 5) {
      return <h5>{number} Пользователя</h5>;
    }

    if (remainder === 1) {
      return <h5>{number} Пользователь</h5>;
    }

    return <h5>{number} Пользователей</h5>;
  }

  return (
    <>
      <HeaderInfo>
        <CircleWrapper
          size={'32'}
          color={'white'}
          style={{ background: `${findChat ? getColorForSymbol(firstTwoLetters(findChat.chat_name)) : '#526ED3'}` }}
          className={'header'}
        >
          {findChat?.is_tech_support === true || roomId === 'support' ? (
            <img
              alt={'telebon-supprot'}
              src={LogoImg}
            />
          ) : roomId === 'telebot' ? (
            <img
              alt={'telegram-avatar'}
              src={TelegramImg}
            />
          ) : findChat ? (
            firstTwoLetters(findChat.chat_name)
          ) : (
            ''
          )}
        </CircleWrapper>
        <div>
          <Text
            $tag={TextType.P}
            $pSize={isMobile ? TextPType.P17 : TextPType.P17}
            fontWeight={600}
          >
            {findChat?.is_tech_support === true || roomId === 'support'
              ? 'Служба поддержки'
              : roomId === 'telebot'
              ? 'Telegram Bot'
              : findChat
              ? findChat.chat_name
              : null}
          </Text>
          <Text
            $tag={TextType.P}
            $pSize={isMobile ? TextPType.P13 : TextPType.P13}
            color={'text02'}
          >
            {getCountUsers()}
          </Text>
        </div>
        {isMobile && (
          <button>
            <Link to={'/messenger-list'}>
              <IconInstance name={EIcon.arrowleftoutline} />
            </Link>
          </button>
        )}
      </HeaderInfo>
      <div ref={ref}>
        {roomId === 'telebot' || roomId === 'support' ? null : (
          <SettingsButton
            onClick={(e) => {
              e.stopPropagation();
              setIsShow(!isShow);
            }}
            className={cn(isShow ? 'open' : 'close')}
          >
            <IconInstance name={EIcon.moreverticaloutline} />
          </SettingsButton>
        )}

        <SettingsOption
          $column
          className={cn(isShow ? 'open' : 'close')}
        >
          {!isMobile && (
            <OptionItem
              onClick={(e) => {
                e.stopPropagation();
                setIsShow(false);
                sendDeleteChat(roomId);
              }}
            >
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P13}
              >
                Удалить чат
              </Text>
            </OptionItem>
          )}
          <OptionItem onClick={onShowModal}>
            <Text
              $tag={TextType.P}
              $pSize={TextPType.P13}
            >
              Изменить название
            </Text>
          </OptionItem>
        </SettingsOption>
      </div>
      {isModal && (
        <Modal
          isOpen={isModal}
          onClose={onCloseModal}
          ref={modalRef}
          size={ModalSize.XS}
        >
          <Suspense fallback={''}>
            <FlexWithAlign
              $column
              $gap={MODAL_GAP}
            >
              <Text>{t('Изменить название чата')}</Text>
              <Input
                label={'Название чата'}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <FlexWithAlign
                $gap={MODAL_GAP}
                $align={'center'}
              >
                <CommonButton
                  typeBtn={'gray'}
                  size={'M'}
                  onClick={onCloseModal}
                >
                  {t('Отменить')}
                </CommonButton>
                <CommonButton
                  typeBtn={'primary'}
                  size={'M'}
                  onClick={sendRenameChat}
                >
                  {t('Сохранить')}
                </CommonButton>
              </FlexWithAlign>
            </FlexWithAlign>
          </Suspense>
        </Modal>
      )}
    </>
  );
};

export default MessengerHeader;
