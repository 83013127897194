import React, { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import dayjs from 'dayjs';

import { FilialWrapper, Header, Info, TodayWrapper } from './CommonHeader.styled';

import { useTypedSelector } from '../../../../shared/lib/hooks/useTypedSelector';
import { Avatar, AvatarRounded, AvatarSize, Text, TextPType, TextType } from '../../../../shared/ui';
import { EIcon } from '../../../icons/medium-new-icons/icon';
import LoggedMenu from '../logget-menu/LoggedMenu';
import NotesMenu from '../notes-menu/NotesMenu';
import PlusMenu from '../plus-menu/PlusMenu';

dayjs.locale('ru');

type IType = {
  children?: React.ReactNode;
};

const CommonHeader: FC<IType> = () => {
  const [isInfo, setIsInfo] = useState(false);
  const { pathname } = useLocation();
  const { activeFilial } = useTypedSelector((state) => state.filial);
  const isLinkActive = (to: string, pathname: string) => {
    const toPath = to.split('/')[1];
    const locationPath = pathname.split('/')[1];

    return toPath === locationPath;
  };
  const userAgent = navigator.userAgent;

  return (
    <Header>
      <TodayWrapper $gap={'4px'}>
        <Text
          $tag={TextType.H5}
          fontWeight={/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream ? 500 : 800}
          color={'accent'}
        >
          {dayjs(new Date()).format('D')}
        </Text>
        <Text
          $tag={TextType.P}
          $pSize={TextPType.P13}
          color={'accent'}
        >
          {dayjs(new Date()).format('dd')}
        </Text>
      </TodayWrapper>
      {activeFilial && (
        <FilialWrapper
          $gap={'5px'}
          $align={'center'}
          $justify={'center'}
        >
          <Text
            $tag={TextType.P}
            $pSize={TextPType.P13}
            fontWeight={/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream ? 500 : 800}
            color={'primary'}
          >
            {activeFilial.name}
          </Text>
        </FilialWrapper>
      )}
      <Avatar
        svg={EIcon.questionempty}
        onMouseEnter={() => setIsInfo(true)}
        onMouseLeave={() => setIsInfo(false)}
        size={AvatarSize.S}
        background={'thinBlue'}
        randomBg
        rounded={AvatarRounded.FULL}
      >
        <Link to={'/support'} />
        {isInfo && <Info>Обратная связь</Info>}
      </Avatar>
      <PlusMenu />
      <NotesMenu />
      <LoggedMenu />
    </Header>
  );
};

export default CommonHeader;
