import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { EditFilialTimeWrapper, FilialTimeCheck } from './EditFilialTimeModal.styled';

import { ModalGrid } from '../../../../../pages/Recording/lib/CommonRecording.styled';
import { cn } from '../../../../../shared/lib';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';
import { useOutside } from '../../../../../shared/lib/hooks/useOutside';
import { InputType, Text, TextPType, TextType } from '../../../../../shared/ui';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import Timepicker from '../../../../../shared/ui/Timepicker/ui/Timepicker';
import { MODAL_GAP, MODAL_TEXT_GAP } from '../../../../../styles';
import { apiPost } from '../../../../../utils/apiInstance';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { Form } from '../../create/cabinet/CreateCabinetModal.styled';

interface IProps {
  closeModal?: () => void;
  modalPayload?: any;
}

interface TimeworkValues {
  timestart: string;
  timeend: string;
}

interface Timework {
  [key: string]: TimeworkValues;
}

interface FormValues {
  address: string;
  name: string;
  phone: string;
  rucovoditel: string;
  timework: Timework[];
  timestart: string;
  timeend: string;
  yurlico: string;
  selectedDays: string[];
  avatar?: string;
}

const EditFilialTimeModal: React.FC<IProps> = (props) => {
  const { modalPayload, closeModal } = props;
  const [pending, setPending] = useState(false);
  const { ref, isShow, setIsShow } = useOutside(true);
  const { t } = useTranslation();
  const { fetchFilialsAsync } = useActions();
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [formValid, setFormValid] = useState(false);

  // useEffect(() => {
  //   if (!isShow) {
  //     closeModal?.();
  //   }
  // }, [isShow]);

  const days = [
    { label: 'Понедельник', value: 'monday', mobileLabel: 'Пн' },
    { label: 'Вторник', value: 'tuesday', mobileLabel: 'Вт' },
    { label: 'Среда', value: 'wednesday', mobileLabel: 'Ср' },
    { label: 'Четверг', value: 'thursday', mobileLabel: 'Чт' },
    { label: 'Пятница', value: 'friday', mobileLabel: 'Пт' },
    { label: 'Суббота', value: 'saturday', mobileLabel: 'Сб' },
    { label: 'Воскресенье', value: 'sunday', mobileLabel: 'Вс' }
  ];

  const initialValues: FormValues = {
    address: modalPayload?.address || '',
    name: modalPayload?.name || '',
    phone: modalPayload?.phone || '',
    rucovoditel: modalPayload?.rucovoditel || '',
    avatar: modalPayload?.avatar || '',
    yurlico: modalPayload?.yurlico || '',
    timework: modalPayload?.timework || [
      {
        monday: { timestart: '', timeend: '' },
        tuesday: { timestart: '', timeend: '' },
        wednesday: { timestart: '', timeend: '' },
        thursday: { timestart: '', timeend: '' },
        friday: { timestart: '', timeend: '' },
        saturday: { timestart: '', timeend: '' },
        sunday: { timestart: '', timeend: '' }
      }
    ],
    timestart: '',
    timeend: '',
    selectedDays: []
  };

  if (
    modalPayload?.timework &&
    modalPayload.timework.length > 0 &&
    modalPayload.timework[0]?.monday?.timestart &&
    modalPayload.timework[0]?.monday?.timeend
  ) {
    initialValues.timestart = modalPayload.timework[0].monday.timestart;
    initialValues.timeend = modalPayload.timework[0].monday.timeend;
    const selectedDays = days.filter((day) => initialValues.timework[0][day.value].timestart !== '').map((day) => day.value);
    initialValues.selectedDays = selectedDays;
  }

  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: async (values) => {
      try {
        setPending(true);
        const res = await apiPost(`/filial/${modalPayload.id}`, values);
        if (res?.status === 200) {
          setPending(false);
          closeModal && closeModal();
          await fetchFilialsAsync();
          toast.success('Режим работы изменён успешно');
        } else {
          setPending(false);
          toast.error(res.data.description);
        }
      } catch (error) {
        setPending(false);
        console.error('Error:', error);
        toast.error('An error occurred.');
      }
    }
  });
  const handleDayClick = (day: string) => {
    const isDaySelected = formik.values.selectedDays.includes(day);

    const updatedDays = isDaySelected
      ? formik.values.selectedDays.filter((selectedDay) => selectedDay !== day)
      : [...formik.values.selectedDays, day];

    const updatedTimework = formik.values.timework.map((item) => {
      const updatedItem = { ...item };
      days.forEach(({ value }) => {
        updatedItem[value] = {
          timestart: updatedDays.includes(value) ? formik.values.timestart : '',
          timeend: updatedDays.includes(value) ? formik.values.timeend : ''
        };
      });

      return updatedItem;
    });

    formik.setFieldValue('selectedDays', updatedDays);
    formik.setFieldValue('timework', updatedTimework);
  };

  useEffect(() => {
    if (
      formik.values.timestart.length === 5 &&
      formik.values.timeend.length === 5 &&
      formik.values.timestart !== '' &&
      formik.values.timeend !== '' &&
      formik.values.timestart < formik.values.timeend
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formik.values]);

  const handleTimeChange = (field: 'timestart' | 'timeend', value: string) => {
    formik.setFieldValue(field, value);
    formik.setFieldValue(
      'timework',
      formik.values.timework.map((item) => {
        const updatedItem = { ...item };
        formik.values.selectedDays.forEach((day) => {
          updatedItem[day] = {
            ...updatedItem[day],
            [field]: value
          };
        });

        return updatedItem;
      })
    );
  };
  const resetSelection = () => {
    formik.setFieldValue('selectedDays', []);
  };
  const isDayActive = (day: string) => formik.values.selectedDays.includes(day);

  return (
    <FlexWithAlign
      $column
      $gap={MODAL_GAP}
    >
      {isMobile ? null : (
        <Text
          $tag={TextType.H5}
          fontWeight={700}
        >
          {t('Изменить расписание')}
        </Text>
      )}
      <EditFilialTimeWrapper ref={ref}>
        <Form onSubmit={formik.handleSubmit}>
          <FlexWithAlign
            className={'full'}
            $column
            $gap={MODAL_GAP}
          >
            <FlexWithAlign
              className={'fitI drop'}
              $align={'flex-start'}
              $column
              $gap={MODAL_TEXT_GAP}
            >
              <Text
                $tag={TextType.P}
                $pSize={isMobile ? TextPType.P15 : TextPType.P17}
                color={isMobile ? undefined : 'text02'}
                fontWeight={isMobile ? 700 : undefined}
              >
                {t('Режим работы')}
              </Text>
              <FlexWithAlign
                $gap={'1rem'}
                className={'full'}
                $column={!isMobile}
              >
                {days.map((day) => (
                  <>
                    {isMobile ? (
                      <FilialTimeCheck>
                        <button
                          key={day.value}
                          className={cn(isDayActive(day.value) && 'active', 'btn')}
                          onClick={(e) => {
                            e.preventDefault();
                            handleDayClick(day.value);
                          }}
                        >
                          {t(day.mobileLabel)}
                        </button>
                      </FilialTimeCheck>
                    ) : (
                      <button
                        key={day.value}
                        className={cn(isDayActive(day.value) && 'active', 'btn')}
                        onClick={(e) => {
                          e.preventDefault();
                          handleDayClick(day.value);
                        }}
                      >
                        {t(day.label.charAt(0).toUpperCase() + day.label.slice(1))}
                      </button>
                    )}
                  </>
                ))}
              </FlexWithAlign>
            </FlexWithAlign>
            <FlexWithAlign
              $column
              $gap={MODAL_TEXT_GAP}
            >
              <Text
                $tag={TextType.P}
                $pSize={isMobile ? TextPType.P15 : TextPType.P17}
                color={isMobile ? undefined : 'text02'}
                fontWeight={isMobile ? 700 : undefined}
              >
                {t('Рабочее время')}
              </Text>
              <ModalGrid className='input-wrap'>
                <Timepicker
                  label={'Начало'}
                  size={InputType.M}
                  value={formik.values.timestart}
                  onChange={(e: { target: { value: string } }) => handleTimeChange('timestart', e.target.value)}
                />
                <span className='input-divide'></span>

                <Timepicker
                  label={'Окончание'}
                  size={InputType.M}
                  value={formik.values.timeend}
                  onChange={(e: { target: { value: string } }) => handleTimeChange('timeend', e.target.value)}
                  lowerLimit={formik.values.timestart}
                />
              </ModalGrid>
            </FlexWithAlign>
          </FlexWithAlign>
          <FlexWithAlign
            $align={'center'}
            $gap={MODAL_GAP}
            $column
            className={'full'}
          >
            <CommonButton
              typeBtn='primary'
              type='submit'
              fullWidth
              size={'M'}
              disabled={!formValid}
            >
              {t('Сохранить')}
            </CommonButton>{' '}
            <CommonButton
              size={'M'}
              fullWidth
              typeBtn={'gray'}
              onClick={() => resetSelection()}
            >
              {t('Сбросить выбор')}
            </CommonButton>
            <CommonButton
              typeBtn='gray'
              size={'M'}
              fullWidth
              type='submit'
              onClick={(e) => {
                e.preventDefault();
                closeModal?.();
              }}
            >
              {t('Отменить')}
            </CommonButton>
          </FlexWithAlign>
        </Form>
      </EditFilialTimeWrapper>
    </FlexWithAlign>
  );
};

export default EditFilialTimeModal;
