import { ChangeEventHandler, InputHTMLAttributes } from 'react';

type HTMLInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'readOnly' | 'size'>;

export enum InputType {
  L = 'l',
  M = 'm',
  S = 's'
}
export interface InputProps extends HTMLInputProps {
  label: string;
  type?: string;
  value: string | any;
  onChange: ChangeEventHandler<HTMLInputElement>;
  name?: string;
  className?: string;
  error?: any;
  ref?: any;
  size?: InputType;
  autofocus?: boolean;
}
