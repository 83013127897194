import React from 'react';

export function SidebarMessenger() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M21.9996 12.0439V12.2032C21.9225 14.8046 20.8393 17.2759 18.976 19.1013C17.1128 20.9267 14.6134 21.9652 11.9996 22H11.8796C10.4539 21.9835 9.04862 21.6609 7.75961 21.0542C7.53998 20.9539 7.29132 20.9362 7.05961 21.0044L5.75961 21.3429C5.3363 21.4568 4.89024 21.4569 4.46687 21.3432C4.04351 21.2295 3.65799 21.0061 3.34961 20.6957C3.03697 20.3895 2.81266 20.0052 2.70008 19.5832C2.5875 19.1611 2.59079 18.7167 2.70961 18.2963L3.04961 17.0219C3.12577 16.7821 3.1079 16.5224 2.99961 16.2951C2.34288 14.9448 2.00116 13.4641 2 11.9638C1.99884 10.4635 2.33827 8.98232 2.99291 7.63097C3.64755 6.27962 4.60048 5.09305 5.78049 4.15992C6.9605 3.2268 8.33707 2.57126 9.80732 2.24229C11.2776 1.91331 12.8035 1.91942 14.271 2.26015C15.7386 2.60088 17.1098 3.26742 18.2823 4.20996C19.4547 5.1525 20.398 6.34666 21.0417 7.7032C21.6854 9.05975 22.0129 10.5436 21.9996 12.0439Z'
        fill='#467FF3'
      />
      <path
        d='M7.99922 13.05C8.57912 13.05 9.04922 12.5799 9.04922 12C9.04922 11.4201 8.57912 10.95 7.99922 10.95C7.41932 10.95 6.94922 11.4201 6.94922 12C6.94922 12.5799 7.41932 13.05 7.99922 13.05Z'
        fill='#467FF3'
      />
      <path
        d='M11.9992 13.05C12.5791 13.05 13.0492 12.5799 13.0492 12C13.0492 11.4201 12.5791 10.95 11.9992 10.95C11.4193 10.95 10.9492 11.4201 10.9492 12C10.9492 12.5799 11.4193 13.05 11.9992 13.05Z'
        fill='#467FF3'
      />
      <path
        d='M16.05 13.05C16.6299 13.05 17.1 12.5799 17.1 12C17.1 11.4201 16.6299 10.95 16.05 10.95C15.4701 10.95 15 11.4201 15 12C15 12.5799 15.4701 13.05 16.05 13.05Z'
        fill='#467FF3'
      />
    </svg>
  );
}
