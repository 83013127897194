import { ReactNode } from 'react';

export enum TextType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
  SPAN = 'span'
}

export enum TextPType {
  P19 = 'p19',
  P17 = 'p17',
  P15 = 'p15',
  P13 = 'p13',
  P11 = 'p11',
  P9 = 'p9'
}

export interface TextProps {
  children?: ReactNode;
  $tag?: TextType;
  small?: boolean;
  muted?: boolean;
  className?: string;
  fontFamily?: string;
  color?: 'primary' | 'accent' | 'text02' | 'base01' | 'black' | 'positive' | 'negative';
  fontWeight?: 100 | 500 | 600 | 700 | 800 | 900;
  $pSize?: TextPType;
}
