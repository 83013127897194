import React, { FC } from 'react';
import { EIcon, IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';
import { InputType, ISearchProps } from './Search.props';
import { InputSearch } from './Search.styled';
import { cn } from '../../../lib';

const Search: FC<ISearchProps> = (props) => {
  const { value, onChange, className, placeholder, type, size = InputType.S } = props;
  return (
    <InputSearch
      size={size}
      className={className}
      $type={type}
    >
      <label className={cn(value && value.length && 'active')}>
        <span>Поиск</span>
        <input
          value={value}
          onChange={onChange}
          type={'text'}
          autoComplete={'off'}
          placeholder={' '}
        />
      </label>
      <div className={'input-field-border'}></div>
      <div className={'input-search-icon will-animate'}>
        <IconInstance name={EIcon.searchmodal} />
      </div>
    </InputSearch>
  );
};

export default Search;
