import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { Wrapper } from './CreateFinanceProductModal.styled';
import { apiPost, apiPut } from '../../../../../../utils/apiInstance';
import { FlexWithAlign, FormStyle } from '../../../../../../utils/styleUtils';
import { validationServiceWithGroupSchema } from '../../../../../../utils/validation-input';
import CommonButton from '../../../../../../shared/ui/Button/ui/CommonButton';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import { Input, InputType, Text, Textarea, TextType } from '../../../../../../shared/ui';
import toast from 'react-hot-toast';

interface IProps {
  closeModal?: () => void;
  edit?: boolean;
  modalPayload?: any;
}

const CreateFinanceProductModal: React.FC<IProps> = (props) => {
  const { closeModal, edit, modalPayload } = props;
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const { user } = useTypedSelector((state1) => state1.user);
  const { fetchUsersAsync, fetchSaleproductsAsync, fetchListKassaAsync, fetchClientsAsync } = useActions();
  const [nameError, setNameError] = useState(false);
  const [priceError, setPriceError] = useState(false);

  const { clients, state } = useTypedSelector((state) => state.clients);

  useEffect(() => {
    fetchUsersAsync();
    fetchClientsAsync();
  }, []);
  useEffect(() => {
    if (user && clients) {
      const combinedOptions = [...user, ...clients];
      setOptions(combinedOptions);
    }
  }, [state]);

  const initialValues = {
    description: '',
    name: '',
    price: 0
  };

  const formik = useFormik({
    initialValues: modalPayload ? modalPayload : initialValues,
    onSubmit: () => {},
    validateOnMount: true,
    validationSchema: validationServiceWithGroupSchema
  });

  const createKassa = async (values: any) => {
    setPending(true);
    await apiPut('/saleproduct', values);
    setPending(false);
    fetchSaleproductsAsync();
    closeModal!();
    toast.success(`Товар успешно создан`);
    fetchListKassaAsync();
  };

  const editKassa = async (values: any) => {
    setPending(true);
    await apiPost(`/saleproduct/${modalPayload?.id}`, { ...values, price: Number(formik.values.price) });
    setPending(false);
    fetchSaleproductsAsync();
    closeModal!();
    toast.success(`Товар успешно изменён`);
    fetchListKassaAsync();
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formik.values.name === '' || formik.values.price === 0 || pending) {
      if (formik.values.name === '') {
        setNameError(true);
      }
      if (formik.values.price === 0) {
        setPriceError(true);
      }

      return;
    }

    if (modalPayload?.id) {
      editKassa(formik.values);
    } else {
      createKassa(formik.values);
    }
  };

  useEffect(() => {
    if (nameError) {
      formik.values.name !== '' && setNameError(false);
    }
  }, [formik.values.name]);
  useEffect(() => {
    if (priceError) {
      formik.values.price !== 0 && setPriceError(false);
    }
  }, [formik.values.price]);

  return (
    <Wrapper>
      <Text
        $tag={TextType.H4}
        fontWeight={700}
      >
        {modalPayload ? t('Редактировать товар') : t('Добавить товар')}
      </Text>

      <FormStyle onSubmit={handleSubmit}>
        <Input
          size={InputType.M}
          label={t('Название товара*')}
          autoFocus={!modalPayload}
          value={formik.values.name}
          name={'name'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={nameError && 'Введите название'}
        ></Input>
        <Input
          label={t('Цена товара*')}
          name={'price'}
          type={'currency'}
          size={InputType.M}
          value={formik.values.price}
          onChange={formik.handleChange}
          error={priceError && 'Введите цену товара'}
        />
        <FlexWithAlign className='formElement textarea'>
          <Textarea
            placeholder={'Описание товара'}
            name={'description'}
            content={formik.values.description}
            onChange={formik.handleChange}
          />
        </FlexWithAlign>
        <FlexWithAlign $justify={'flex-start'}>
          <CommonButton
            typeBtn='gray'
            type='submit'
            size={'M'}
            onClick={(e) => {
              e.preventDefault();
              closeModal?.();
            }}
          >
            {t('Отменить')}
          </CommonButton>
          <CommonButton
            typeBtn='primary'
            size={'M'}
            type='submit'
          >
            {edit ? t('Сохранить') : t('Добавить')}
          </CommonButton>
        </FlexWithAlign>
      </FormStyle>
    </Wrapper>
  );
};

export default CreateFinanceProductModal;
