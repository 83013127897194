import React from 'react';

import { EIcon, IconNew as IconInstance } from '../../icons/medium-new-icons/icon';
import { useTypedSelector } from '../../../shared/lib/hooks/useTypedSelector';

export function useSubmenuData() {
  const { websocketroomId } = useTypedSelector((state) => state.chat);
  const submenu = [
    // {
    //   title: 'Главная',
    //   icon: <IconInstance name={EIcon.home} />,
    //   to: '/',
    //   click: true
    // },
    {
      title: 'Журнал записей',
      icon: <IconInstance name={EIcon.sidebarrecording} />,
      to: `/products/employee/calendar`,
      click: true
    },
    {
      title: 'Сотрудники',
      icon: <IconInstance name={EIcon.sidebaremployee} />,
      to: '/employees',
      click: true
    },
    {
      title: 'График работы',
      icon: <IconInstance name={EIcon.sidebarschedule} />,
      to: '/timetable',
      click: true
    },
    {
      title: 'Клиенты',
      icon: <IconInstance name={EIcon.sidebarclients} />,
      to: '/clients',
      click: true,
      endTab: true
    },
    // {
    //   title: 'Вызов',
    //   icon: <IconInstance name={EIcon.phone} />,
    //   to: '/call/dialog/inidletime',
    //   click: false,
    //   mark: true
    // },

    {
      title: 'Онлайн запись',
      icon: <IconInstance name={EIcon.sidebaronlinerecording} />,
      to: '/marketing',
      click: true
    },
    {
      title: 'Мессенджер',
      icon: <IconInstance name={EIcon.sidebarmessenger} />,
      to: `/messenger/${websocketroomId ? websocketroomId : 'telebot'}`,
      click: true,
      mark: true
    },
    {
      title: 'Задачи',
      icon: <IconInstance name={EIcon.sidebartasks} />,
      to: '/taskbook/blocks',
      click: true,
      endTab: true
    },
    {
      title: 'Компания',
      icon: <IconInstance name={EIcon.sidebarcompany} />,
      to: '/management/summary',
      click: true
    },
    {
      title: 'Финансы',
      icon: <IconInstance name={EIcon.sidebarfinance} />,
      to: '/finance/cash-registers',
      click: true
    },
    {
      title: 'Аналитика',
      icon: <IconInstance name={EIcon.sidebaranalytics} />,
      to: '/analytics/1',
      click: true
    }
  ];

  return { submenu };
}
