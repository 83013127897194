import React, { FC, Suspense, useCallback, useRef, useState } from 'react';
import { BtnConteiner, MobileBtn } from '../MessengerSide.styled';
import { Drawer, Loader, Modal, ModalSize } from '../../../../../shared/ui';
import UserListDialog from '../../dialog/UserListDialog';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { useModalActions } from '../../../../../shared/lib';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import { EIcon, IconNew as IconInstance } from '../../../../../components/icons/medium-new-icons/icon';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';
import { useTranslation } from 'react-i18next';

const MessengerOptions: FC = () => {
  const { user } = useTypedSelector((state) => state.user);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const { t } = useTranslation();
  const modalRef1 = useRef(null);

  const [isModal, setIsModal] = useState(false);

  const onShowModal = useCallback(() => {
    setIsModal(true);
  }, []);

  const { onCloseModal } = useModalActions(setIsModal);

  return (
    <BtnConteiner>
      <div className={'animated-menu-icon'}></div>
      {isMobile ? (
        <MobileBtn onClick={onShowModal}>
          <IconInstance name={EIcon.plus} />
        </MobileBtn>
      ) : (
        <CommonButton
          size={'M'}
          onlyIcon
          typeBtn={'gray'}
          onClick={onShowModal}
          className={'btn-icon'}
        >
          <IconInstance name={EIcon.plus} />
        </CommonButton>
      )}

      {isModal && !isMobile && (
        <Modal
          isOpen={isModal}
          onClose={onCloseModal}
          size={ModalSize.M}
          ref={modalRef1}
        >
          <Suspense fallback={<Loader />}>
            <UserListDialog
              onClose={onCloseModal}
              userData={user}
            />
          </Suspense>
        </Modal>
      )}
      {isModal && isMobile && (
        <Drawer
          lazy
          isOpen={isModal}
          onClose={onCloseModal}
          header={t('Создание чата')}
        >
          <Suspense fallback={<Loader />}>
            <UserListDialog
              onClose={onCloseModal}
              userData={user}
            />
          </Suspense>
        </Drawer>
      )}
    </BtnConteiner>
  );
};

export default MessengerOptions;
