import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import MessengerForm from './form/MessengerForm';
import MessengerHeader from './header/MessengerHeader';
import { MessengerHeaderWrapper } from './header/MessengerHeader.styled';
import Messages from './messages/Messages';
import { ChatBackground, ChatsContainer, MainColumn, TableBody } from './MessengerChat.styled';

import { useActions } from '../../../../shared/lib/hooks/useActions';
import { getCookie } from '../../../../utils/cookies';
import useMatchMedia from '../../../../shared/lib/hooks/useMatchMedia';
import { Portal } from '../../../../shared/ui';

const MessengerChat: FC = () => {
  const { roomId } = useParams();
  const { fetchUserByIdAsync } = useActions();
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  useEffect(() => {
    fetchUserByIdAsync(getCookie('id'));
  }, []);

  return (
    <>
      <MainColumn>
        <ChatsContainer>
          <TableBody>
            {isMobile ? (
              <Portal>
                <MessengerHeaderWrapper
                  $align={'center'}
                  $justify={'space-between'}
                >
                  <MessengerHeader />
                </MessengerHeaderWrapper>
              </Portal>
            ) : (
              <MessengerHeaderWrapper
                $align={'center'}
                $justify={'space-between'}
              >
                <MessengerHeader />
              </MessengerHeaderWrapper>
            )}

            <ChatBackground />
            <Messages />
            {roomId !== 'telebot' ? <MessengerForm /> : <div style={{ height: '5vh' }}></div>}
          </TableBody>
        </ChatsContainer>
      </MainColumn>
      {/*{<MessengerSide className={isShow ? 'open' : 'close'} ref={ref}>flksjfej</MessengerSide>}*/}
    </>
  );
};

export default MessengerChat;
