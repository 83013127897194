import { ForwardedRef, ReactNode } from 'react';

export enum ModalSize {
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
  XXS = 'xxs',
  RECORDING = 'recording',
  PUSH = 'push',
  IMPORT_CLIENTS = 'import-clients'
}
export enum ModalType {
  CENTER = 'center',
  SIDE = 'side',
  SIDE_OVERLAY = 'side-overlay',
  TOP = 'top'
}

export interface ModalProps {
  className?: string;
  children?: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  lazy?: boolean;
  ref?: ForwardedRef<HTMLDivElement>;
  size?: ModalSize;
  type?: ModalType;
}
