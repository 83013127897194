import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { addDateBy, timeStringToNumber } from './date-events';

import { IEmployeeScheduleQuery } from '../components/shared/modal/update/employee-schedule/schedule/EmployeeSchedule.data';
import { IUserSchedule } from '../store/redux/user/user.interface';
import { IFilial } from '../store/redux/filial/filial.interface';

export const transformStringCountServices = (count: number) => {
  let transformedString = '';
  if (count % 10 === 1 && (count < 10 || count > 20)) {
    transformedString = 'услуга';
  } else if (count % 10 >= 2 && count % 10 <= 4 && (count < 10 || count > 20)) {
    transformedString = 'услуги';
  } else if (((count % 10 >= 5 && count % 10 <= 9) || count % 10 === 0) && (count < 10 || count > 20)) {
    transformedString = 'услуг';
  } else {
    transformedString = 'услуг';
  }

  return transformedString;
};

export const transformStringCountSeats = (count: number) => {
  let transformedString = '';
  if (count % 10 === 1 && (count < 10 || count > 20)) {
    transformedString = 'место';
  } else if (count % 10 >= 2 && count % 10 <= 4 && (count < 10 || count > 20)) {
    transformedString = 'места';
  } else if (((count % 10 >= 5 && count % 10 <= 9) || count % 10 === 0) && (count < 10 || count > 20)) {
    transformedString = 'мест';
  } else {
    transformedString = 'мест';
  }

  return transformedString;
};

export const getNumberCompleteYears = (date: string) => {
  if (!date) {
    return 'Дата не указана';
  }
  const birthdate = new Date(date);
  const currentdate = new Date();

  let age = currentdate.getFullYear() - birthdate.getFullYear();
  let ageStr = '';
  if (
    currentdate.getMonth() < birthdate.getMonth() ||
    (currentdate.getMonth() === birthdate.getMonth() && currentdate.getDate() < birthdate.getDate())
  ) {
    age--;
  }

  if (age % 10 === 1 && (age < 10 || age > 20)) {
    ageStr = ' год';
  } else if (age % 10 >= 2 && age % 10 <= 4 && (age < 10 || age > 20)) {
    ageStr = ' года';
  } else if (((age % 10 >= 5 && age % 10 <= 9) || age % 10 === 0) && (age < 10 || age > 20)) {
    ageStr = ' лет';
  } else {
    ageStr = ' лет';
  }

  return age + ageStr;
};

export const transformFetchFilialScheduleData = (filial: IFilial | undefined) => {
  const resultArr: IEmployeeScheduleQuery | any[] = [];

  if (!filial) {
    return resultArr;
  }
  if (filial.timework && filial.timework[0]) {
    // const resultArr: IEmployeeScheduleQuery | any[] = [];
    const timeWorkObj = filial.timework[0];
    const throughWeek = addDateBy(new Date(), 6);
    for (let currDate = new Date(); currDate <= throughWeek; currDate.setDate(currDate.getDate() + 1)) {
      const currWeekDay = dayjs(currDate).locale('en').format('dddd').toLocaleLowerCase();
      if (timeWorkObj[currWeekDay].timestart !== '' && timeWorkObj[currWeekDay].timeend !== '') {
        resultArr.push({
          date: dayjs(new Date(currDate)).format('YYYY-MM-DD'),
          timestart: timeWorkObj[currWeekDay].timestart,
          timeend: timeWorkObj[currWeekDay].timeend
        });
      }
    }

    // return resultArr;
  }

  return resultArr;
};
export const rangeArr = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const numberToTime = (num: number) => {
  return num <= 9 ? '0' + String(num) + ':00' : String(num) + ':00';
};

export const dateToDateTime = (date: string, time: string) => {
  return date + ' ' + time;
};

export const generateRandomString = () => Math.random().toString(36).substring(3, 16);

export const transformEmployeeScheduleData = (employeeScheduleData: IUserSchedule[]) => {
  let resultArr: any[] = [];
  if (employeeScheduleData && employeeScheduleData.length > 0) {
    resultArr = employeeScheduleData.map((day) =>
      rangeArr(timeStringToNumber(day.timestart), timeStringToNumber(day.timeend) - 1, 1).map((hour) => {
        return {
          id: generateRandomString(),
          day: dayjs(day.date).locale('ru').format('dd').toLocaleLowerCase(),
          date: dayjs(day.date).locale('en').format('ddd MMM DD YYYY'),
          time: numberToTime(hour),
          timeEnd: numberToTime(hour + 1)
        };
      })
    );
  }

  return resultArr.flat(1);
};

export const transformEmployeeSheduleToApiData = (
  employeeSheduleArr: { id?: string; day?: string; date: string; time: string; timeEnd: string }[]
) => {
  const uniqueDates = new Set();
  let outputArray: any = [];
  if (employeeSheduleArr && employeeSheduleArr.length > 0) {
    employeeSheduleArr.forEach((item) => {
      if (!uniqueDates.has(item.date)) {
        uniqueDates.add(item.date);
      }
    });
    outputArray = Array.from(uniqueDates).map((item) => {
      return {
        date: item,
        timestart: employeeSheduleArr.filter((evt) => evt.date === item).reduce((acc, curr) => (acc.time > curr.time ? curr : acc)).time,
        timeend: employeeSheduleArr.filter((evt) => evt.date === item).reduce((acc, curr) => (acc.timeEnd < curr.timeEnd ? curr : acc))
          .timeEnd
      };
    });
  }

  return outputArray;
};
export const formatPhoneNumber = (phoneNumber: string) => {
  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

  const formattedPhoneNumber = numericPhoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3-$4-$5');

  return formattedPhoneNumber;
};

export const transformTarifName = (tarifName: string) => {
  if (tarifName === 'Standart') {
    return 'Профессиональный';
  } else if (tarifName === 'Trial') {
    return 'Пробный';
  } else {
    return 'Базовый';
  }
};

export const convertCurrency = (amount: string | number) => {
  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0
  }).format(+amount);
};

