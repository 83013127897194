import React, { memo, useEffect, useRef, useState } from 'react';

import { ErrorText, IconContainer, InputContainer, InputContent, InputContentCurrency, Label } from './Input.styled';

import 'react-datepicker/dist/react-datepicker.css';
import { InputProps } from './Input.props';

import { cn } from '../../../lib';

import { CurrencyInputOnChangeValues } from 'react-currency-input-field';

export const Input = memo((props: InputProps) => {
  const { label, type, autofocus, size, className, children, value, onChange, name, error = null, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus) {
      setIsFocused(true);
      ref.current?.focus();
    }
  }, [autofocus]);

  const onBlur = () => {
    setIsFocused(false);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const handleInputChange = (value: string | undefined, name: string | undefined, values: CurrencyInputOnChangeValues | undefined) => {
    if (name !== undefined) {
      if (value !== undefined) {
        onChange({
          target: {
            value: '0',
            name: name
          }
        } as React.ChangeEvent<HTMLInputElement>);
      }
      onChange({
        target: {
          value: values?.float || '',
          name: name
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');
    const digitsOnly = pastedData.replace(/\D/g, '');

    let formattedNumber: string;
    if (digitsOnly.length === 10) {
      formattedNumber = `+7 ${digitsOnly.slice(0, 3)} ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 8)}-${digitsOnly.slice(8, 10)}`;
    } else if (digitsOnly.length === 11 && (digitsOnly.startsWith('7') || digitsOnly.startsWith('8'))) {
      formattedNumber = `+7 ${digitsOnly.slice(1, 4)} ${digitsOnly.slice(4, 7)}-${digitsOnly.slice(7, 9)}-${digitsOnly.slice(9, 11)}`;
    } else {
      formattedNumber = digitsOnly;
    }

    onChange({
      target: {
        value: formattedNumber,
        name: name
      }
    } as React.ChangeEvent<HTMLInputElement>);

    event.preventDefault();
  };

  return (
    <InputContainer
      size={size}
      ref={ref}
      className={cn(isFocused && 'focused', { error: !!error }, className)}
    >
      <Label
        size={size}
        htmlFor={name}
        className={cn({
          active: (value && value.length) || (value && Number(value)) > 0 || (type === 'number' && Number(value) === 0),
          error: !!error
        })}
      >
        <span>{label}</span>

        {type === 'currency' ? (
          <InputContentCurrency
            className={cn({
              active: (value && value.length) || (value && Number(value)) > 0,
              error: !!error
            })}
            name={name}
            suffix={' ₽'}
            allowNegativeValue={false}
            type={'text'}
            id={name}
            min={0}
            value={value ? value.toString() : undefined}
            onValueChange={(value, name, values) => handleInputChange(value, name, values)}
          />
        ) : (
          <InputContent
            className={cn({
              active: (value && value.length) || (value && Number(value)) > 0 || (type === 'number' && Number(value) === 0),
              error: !!error
            })}
            autoComplete={'off'}
            onFocus={onFocus}
            //@ts-ignore
            size={size}
            min={1}
            onPaste={type === 'tel' ? handlePaste : () => {}}
            onBlur={onBlur}
            mask={
              type === 'tel'
                ? '+7 999 999-99-99'
                : type === 'dateB'
                ? '99.99.9999'
                : type === 'summ'
                ? '999 999 999,99 ₽'
                : (undefined as any)
            }
            maskChar={null}
            value={value}
            ref={ref}
            onChange={onChange}
            onInput={onChange}
            type={type}
            id={name}
            {...rest}
          />
        )}
      </Label>
      <IconContainer
        className={cn({
          active: value && value.length,
          error: !!error
        })}
      >
        {children}
      </IconContainer>

      {error ? <ErrorText>{error}</ErrorText> : null}
    </InputContainer>
  );
});

Input.displayName = 'CommonInput';
