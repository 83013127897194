import { Dispatch, FC, SetStateAction } from 'react';

import styled from 'styled-components';
import CommonCalendar from '../../../../../../components/shared/calendar/CommonCalendar';
import { scaleIn } from '../../../../../../components/shared/modal/create/service/sidebar/CreateServiceSidebar.styled';
import { RADIUS_M } from '../../../../../../styles';

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${scaleIn} 0.3s ease;
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 20px;
  min-width: 210px;
  max-width: 210px;
  width: 100%;
  &.bigCalendar {
    min-width: 280px;
    max-width: 280px;
    .react-calendar {
      min-width: 280px;
      background: transparent;
      max-width: 280px;
      box-shadow: none;
      .react-calendar__navigation .react-calendar__navigation__arrow {
        padding: 6px;
        border-radius: 50%;
        border: 1px solid ${(props) => props.theme.color.secondaryLightMedium};
      }
      .react-calendar__viewContainer .react-calendar__month-view__days__day {
        min-width: 34px;
        max-width: 34px;
        max-height: 34px;
        min-height: 34px;
        font-size: 13px;
        font-weight: 500;
        > abbr {
          min-width: 34px;
          min-height: 34px;
        }
      }
      .react-calendar__navigation .react-calendar__navigation__label {
        font-size: 14px;
      }
      .react-calendar__navigation .react-calendar__navigation__arrow > svg {
        width: 20px;
        height: 20px;
        color: #272e35;
      }
    }
  }
  &.mobile-sheet-calendar {
    max-width: calc(332px + 1rem);
    margin-bottom: 30px;
    min-height: 370px;
    .react-calendar {
      background: transparent;
      max-width: calc(332px + 1rem);
      box-shadow: none;
      .react-calendar__viewContainer,
      .react-calendar__navigation {
        padding: 1rem 0;
      }
      .react-calendar__navigation .react-calendar__navigation__arrow {
        padding: 6px;
        border-radius: 50%;
        border: 1px solid ${(props) => props.theme.color.secondaryLightMedium};
      }
      .react-calendar__month-view__days {
        //column-gap: 2rem;
        row-gap: 10px;
      }
      .react-calendar__viewContainer .react-calendar__month-view__days__day {
        min-width: 49px;
        max-width: 49px;
        max-height: 32px;
        min-height: 32px;
        font-size: 13px;
        font-weight: 500;
        > abbr {
          min-width: 32px;
          min-height: 32px;
        }
      }
      .react-calendar__viewContainer .react-calendar__tile--active abbr {
        border-radius: ${RADIUS_M};
        background: ${(props) => props.theme.color.primary};
      }
      .react-calendar__navigation .react-calendar__navigation__label {
        font-size: 14px;
      }
      .react-calendar__navigation .react-calendar__navigation__arrow > svg {
        width: 20px;
        height: 20px;
        color: #272e35;
      }
    }
  }
`;

interface IProps {
  currentView?: 'resourceTimeGridDay' | 'resourceTimeGridWeek' | 'resourceDayGridMonth' | 'listWeek';
  className?: string;
  sliderRef?: any;
  setPrev?: any;
  setNext?: any;
  setChosen?: any;
  setOldSlide?: Dispatch<SetStateAction<number>>;
  setActiveSlide2?: Dispatch<SetStateAction<number>>;
}

const RecordingCalendar: FC<IProps> = ({
  className,
  setActiveSlide2,
  setOldSlide,
  setChosen,
  setNext,
  setPrev,
  sliderRef,
  currentView
}) => {
  return (
    <CalendarWrapper className={className}>
      <CommonCalendar
        sliderRef={sliderRef}
        setOldSlide={setOldSlide}
        setActiveSlide2={setActiveSlide2}
        setPrev={setPrev}
        setNext={setNext}
        setChosen={setChosen}
        currentView={currentView}
      />
    </CalendarWrapper>
  );
};

export default RecordingCalendar;
