export const paymentpurposeDown = [
  {
    value: 'Оплата поставки',
    label: 'Оплата поставки'
  },
  {
    value: 'Возврат',
    label: 'Возврат'
  },
  {
    value: 'Выдача зарплаты',
    label: 'Выдача зарплаты'
  },
  {
    value: 'Другое',
    label: 'Другое'
  }
];
