import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { Form, Wrapper } from './CreateClientModal.styled';

import { capitalizeEveryWord, formatPhoneNumber } from '../../../../../shared/lib/helpers/regex.helper';
import { Avatar, AvatarRounded, AvatarSize, Input, InputType, Text, TextPType, TextType } from '../../../../../shared/ui';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import Dropdown from '../../../../../shared/ui/Dropdown/ui/Dropdown';
import { useCreateClient } from '../../../../../store/redux/clients/hooks/useClientsMutation';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { validationClientAddSchema } from '../../../../../utils/validation-input';
import { EIcon, IconNew as IconInstance } from '../../../../icons/medium-new-icons/icon';
import { MODAL_GAP, MODAL_TEXT_GAP } from '../../../../../styles';
import { CheckboxGroupWrapper } from '../../../../../styles/checkbox';
import { cn } from '../../../../../shared/lib';
import { resizeAndConvertToBase64 } from '../../../../../shared/lib/helpers/convertToBase46';
import EmployeeImg from '../../../../../assets/images/employee.png';
import { InputUpload, UploadBlock } from '../employeers/CreateEmployeersModal.styled';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';

interface IProps {
  closeModal: () => void;
  edit?: boolean;
  modalPayload?: any;
}

const statusList = [
  { value: 1, label: 'Лид' },
  { value: 11, label: 'Новый лид' },
  { value: 12, label: 'Принимает решение лид' },
  { value: 13, label: 'Не качественный лид' },
  { value: 14, label: 'Отказ' },
  { value: 2, label: 'Клиент' }, // После завершения первой услуги
  { value: 21, label: 'Новый клиент' }, // При создании клиента
  { value: 22, label: 'Повторный клиент' }, // Клиенты, которые за последний месяц совершили повторный визит
  { value: 23, label: 'Потерянный клиент' }, // Клиенты без визитов на протяжении 60 дней
  { value: 24, label: 'Заканчивается абонемент или услуга' } // Клиенты, у которых заканчивается абонемент по остатку услуг или сроку действия
];

const communicationMethods = [
  { value: 1, label: 'Любой' },
  { value: 2, label: 'Только смс + мессенджеры' }
];
const communicationIntervals = [
  { value: '9-12', label: '9-12' },
  { value: '12-15', label: '12-15' },
  { value: '15-18', label: '15-18' },
  { value: '18-21', label: '18-21' }
];

const CreateClientModal: React.FC<IProps> = (props) => {
  const { closeModal, edit, modalPayload } = props;
  const { data: activeFilial } = useFilialQuery();

  const { mutateAsync: createClientFn } = useCreateClient();
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isWhatsApp, setIsWhatsApp] = useState(false);
  const [isViber, setIsViber] = useState(false);
  const [isTelegram, setIsTelegram] = useState(false);
  const [ageClient, setAgeClient] = useState('');
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const initialValues = {
    balance: 0,
    state: 21,
    email: '',
    birthday: '',
    phone: '',
    name: '',
    filial: activeFilial?.name,
    status: '',
    sex: false,
    dopphone: '',
    comment: '',
    commethod: '',
    comminterval: '',
    telegram: false,
    viber: false,
    whatsapp: false,
    shouldValidatePhone: false,
    avatar: ''
  };
  const formik = useFormik({
    initialValues: modalPayload
      ? {
          ...modalPayload,
          phone: formatPhoneNumber(modalPayload?.phone),
          // dopphone: modalPayload?.dopphone?.indexOf('+') === 0 ? modalPayload.dopphone : '+7'.concat('', modalPayload.dopphone),
          dopphone: modalPayload?.dopphone && modalPayload?.dopphone !== '' ? formatPhoneNumber(modalPayload?.dopphone) : '',
          comminterval:
            communicationIntervals.find(
              (item) => item.value.includes(String(modalPayload.comstart)) && item.value.includes(String(modalPayload.comfinish))
            )?.label || '',
          commethod: communicationMethods.find((item) => item.value === modalPayload.commethod)?.label || '',
          comment: modalPayload?.comment,
          birthday:
            modalPayload?.birthday && modalPayload?.birthday !== '' && modalPayload?.birthday.indexOf('-') > -1
              ? dayjs(modalPayload?.birthday).format('DD.MM.YYYY')
              : modalPayload?.birthday,
          shouldValidatePhone: false
        }
      : initialValues,
    validationSchema: validationClientAddSchema,
    // validateOnChange: false,
    onSubmit: () => {},
    validateOnBlur: true
  });
  const createClient = async (values: any) => {
    setPending(true);
    const { comminterval, sex, commethod } = values;
    const [comstart, comfinish] = comminterval ? comminterval.split('-') : ['', ''];
    const sexBoolean = String(sex) === 'true';
    const name = capitalizeEveryWord(values.name);

    const commethodNum = communicationMethods.find((item) => item.label === commethod)?.value;
    const res = await createClientFn({
      ...values,
      comstart: Number(comstart),
      comfinish: Number(comfinish),
      sex: sexBoolean,
      name: name,
      commethod: commethodNum,
      filial: activeFilial?.id
    });
    if (res?.status === 'ok') {
      closeModal!();
      toast.success(t('Клиент успешно добавлен'));
    } else {
      toast.error(res.description);
      setPending(false);
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      try {
        const resizedBase64 = await resizeAndConvertToBase64(file, 200, 200);

        if (typeof resizedBase64 === 'string') {
          formik.setFieldValue('avatar', resizedBase64);
        } else {
          console.error('Error resizing and converting to base64: Failed to get a string');
        }
      } catch (error) {
        console.error('Error resizing and converting to base64:', error);
      }
    }
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!formik.isValid || pending || formik.values.name === '' || formik.values.phone === '') {
      if (formik.values.name === '') {
        setNameError(true);
      }
      if (formik.values.phone === '') {
        setPhoneError(true);
      }
      return;
    }
    createClient(formik.values);
  };

  useEffect(() => {
    if (nameError) {
      formik.values.name !== '' && setNameError(false);
    }
  }, [formik.values.name]);
  useEffect(() => {
    if (phoneError) {
      formik.values.phone !== '' && setPhoneError(false);
    }
  }, [formik.values.phone]);

  useEffect(() => {
    if (formik.values.dopphone && formik.values.dopphone.length) {
      formik.setFieldValue('shouldValidatePhone', true);
    } else {
      formik.setFieldValue('shouldValidatePhone', false);
    }
  }, [formik.values]);

  return (
    <Wrapper>
      {isMobile ? null : (
        <Text
          $tag={TextType.H5}
          fontWeight={700}
        >
          {modalPayload ? t('Редактирование клиента') : t('Добавление клиента')}
        </Text>
      )}
      <Form onSubmit={handleSubmit}>
        <UploadBlock
          $align={'center'}
          $gap={isMobile ? MODAL_TEXT_GAP : MODAL_GAP}
          $column={isMobile}
        >
          <Avatar
            size={isMobile ? AvatarSize.L : AvatarSize.XL}
            rounded={AvatarRounded.FULL}
            avatar={formik.values.avatar || EmployeeImg}
          />

          <FlexWithAlign
            className={'full'}
            $column
            $gap={MODAL_TEXT_GAP}
            $justify={'space-between'}
          >
            {!isMobile && (
              <FlexWithAlign
                $column
                $gap={'0'}
              >
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P15}
                >
                  {t('Добавьте аватар')}
                </Text>
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P13}
                  color={'text02'}
                >
                  {t('Выберите ваше изображение для аватара.')}
                </Text>
              </FlexWithAlign>
            )}
            <InputUpload>
              <label htmlFor={'avatarInput'}>
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P15}
                >
                  {isMobile ? (
                    <span>{t('Добавьте аватар')}</span>
                  ) : (
                    <>
                      <span>{t('Выберите файл')}</span> {t('или перенесите')}
                    </>
                  )}
                </Text>
                <input
                  type='file'
                  id='avatarInput'
                  accept='image/*'
                  onChange={handleFileChange}
                />
              </label>
            </InputUpload>
          </FlexWithAlign>
        </UploadBlock>
        <FlexWithAlign
          $column
          $gap='20px'
        >
          <FlexWithAlign
            $column
            $gap='20px'
          >
            <Input
              size={InputType.M}
              label={t('Введите Имя*')}
              value={formik.values.name}
              onChange={formik.handleChange}
              name='name'
              onBlur={formik.handleBlur}
              error={formik.errors.name || (nameError && 'Введите Имя')}
            >
              {ageClient !== '' && <div className='age'>{ageClient}</div>}
            </Input>

            <Input
              size={InputType.M}
              label={t('Телефон*')}
              name='phone'
              type={'tel'}
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={(formik.touched.phone && formik.errors.phone) || (phoneError && 'Введите телефон')}
            />

            <Input
              size={InputType.M}
              label={t('Дополнительный телефон')}
              name='dopphone'
              type={'tel'}
              value={formik.values.dopphone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.dopphone && formik.errors.dopphone}
            />
            <Input
              size={InputType.M}
              label={t('Дата рождения')}
              value={formik.values.birthday}
              onChange={formik.handleChange}
              name='birthday'
              type='dateB'
              max={new Date().toISOString().split('T')[0]}
            />

            <Input
              size={InputType.M}
              label={'Email'}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && formik.errors.email}
              name='email'
            />
          </FlexWithAlign>
          <FlexWithAlign
            $column={isMobile}
            $gap={MODAL_GAP}
          >
            <Dropdown
              size={InputType.M}
              className={'labelSmall'}
              onChange={(option: any) => {
                formik.setFieldValue('commethod', option.label);
              }}
              labelTitle={t('Удобный способ связи')}
              options={communicationMethods}
              currentValue={communicationMethods.find((item) => item.label === formik.values.commethod)?.label || formik.values.commethod}
            />
            <Dropdown
              size={InputType.M}
              className={'labelSmall'}
              onChange={(option: any) => {
                formik.setFieldValue('comminterval', option.label);
              }}
              options={communicationIntervals}
              currentValue={
                communicationIntervals.find((item) => item.label === formik.values.comminterval)?.label || formik.values.comminterval
              }
              labelTitle={t('Удобное время')}
            />
          </FlexWithAlign>
          <CheckboxGroupWrapper className={'messangers'}>
            <div className={'checkbox-wrapper'}>
              <label className={cn(formik.values.whatsapp && 'active')}>
                <input
                  type='checkbox'
                  name='whatsapp'
                  value={formik.values.whatsapp}
                  onChange={formik.handleChange}
                />
                <p>{t('Whatsapp')}</p>
                <button
                  className={formik.values.whatsapp ? 'whatsapp-btn active' : 'whatsapp-btn'}
                  type='button'
                  onClick={() => setIsWhatsApp(!isWhatsApp)}
                >
                  <IconInstance name={EIcon.watsapp} />
                </button>
              </label>
            </div>
            <div className={'checkbox-wrapper'}>
              <label className={cn(formik.values.telegram && 'active')}>
                <input
                  type='checkbox'
                  name='telegram'
                  value={formik.values.telegram}
                  onChange={formik.handleChange}
                />
                <p>{t('Telegram')}</p>
                <button
                  className={formik.values.telegram ? 'telegram-btn active' : 'telegram-btn'}
                  onClick={() => setIsTelegram(!isTelegram)}
                  type='button'
                >
                  <IconInstance name={EIcon.telegramempty} />
                </button>
              </label>
            </div>
            <div className={'checkbox-wrapper'}>
              <label className={cn(formik.values.viber && 'active')}>
                <input
                  type='checkbox'
                  name='viber'
                  value={formik.values.viber}
                  onChange={formik.handleChange}
                />
                <p>{t('Viber')}</p>
                <button
                  className={formik.values.viber ? 'active viber-btn' : 'viber-btn'}
                  type='button'
                  onClick={() => setIsViber(!isViber)}
                >
                  <IconInstance name={EIcon.viber} />
                </button>
              </label>
            </div>
          </CheckboxGroupWrapper>
        </FlexWithAlign>

        <FlexWithAlign
          $justify='flex-start'
          $gap={MODAL_GAP}
          className={'controls'}
        >
          <CommonButton
            typeBtn='gray'
            size={'M'}
            onClick={(e: any) => {
              e.preventDefault();
              closeModal();
            }}
          >
            {t('Отмена')}
          </CommonButton>
          <CommonButton
            typeBtn='primary'
            size={'M'}
            type='submit'
          >
            {modalPayload ? t('Сохранить') : t('Добавить')}
          </CommonButton>
        </FlexWithAlign>
      </Form>
    </Wrapper>
  );
};

export default CreateClientModal;
