import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';

import { useTasks } from '../../../../pages/TaskBookPage/lib/hooks/useTasks';
import { LessonsService } from '../../../../shared/api/model/service/lessons.service';
import { TasksService } from '../../../../shared/api/model/service/tasks.service';
import { useActions } from '../../../../shared/lib/hooks/useActions';
import { Text, TextPType, TextType } from '../../../../shared/ui';
import CommonButton from '../../../../shared/ui/Button/ui/CommonButton';
import { useDeleteCabinet } from '../../../../store/redux/cabinets/hooks/useCabinetsMutation';
import { useDeleteClient } from '../../../../store/redux/clients/hooks/useClientsMutation';
import { useLessonsQuery } from '../../../../store/redux/lessons/useLessonsQuery';
import { IActionName } from '../../../../store/redux/modal/modal.interface';
import { useDeleteProduct } from '../../../../store/redux/products/hooks/useProductsMutation';
import { useProductsQuery } from '../../../../store/redux/products/hooks/useProductsQuery';
import { IProduct } from '../../../../store/redux/products/products.interface';
import { useDeleteService } from '../../../../store/redux/subproducts/hooks/useSubproductsMutation';
import { useDeleteUser } from '../../../../store/redux/user/hooks/useUsersMutation';
import { MODAL_GAP } from '../../../../styles';
import { apiDelete } from '../../../../utils/apiInstance';
import { FlexWithAlign } from '../../../../utils/styleUtils';

interface IProps {
  closeModal?: () => void;
  modalPayload?: any;
  actionName?: IActionName;
}
const CommonConfirmDelete: React.FC<IProps> = (props) => {
  const { closeModal, modalPayload, actionName } = props;
  const { refetch: kanbanRefetch } = useTasks();
  const { data: products, refetch: productRefetch } = useProductsQuery();
  const { refetch: lessonsRefetch } = useLessonsQuery();
  const { mutateAsync: deleteUser } = useDeleteUser();
  const { mutateAsync: deleteProductFn } = useDeleteProduct();
  const { mutateAsync: deleteClientFn } = useDeleteClient();
  const { mutateAsync: deleteService } = useDeleteService();
  const { mutateAsync: deleteCabinetFn } = useDeleteCabinet();
  const { t } = useTranslation();
  const {
    fetchListKassaAsync,
    fetchCabinetsAsync,
    fetchClientsAsync,
    fetchFilialsAsync,
    fetchSubproductsAllAsync,
    fetchProductsAsync,
    fetchSaleproductsAsync
  } = useActions();
  const getProductIds = (arr: IProduct[]) => {
    const arrId: string[] = [];
    if (arr.length > 0) {
      arr.forEach((item) => arrId.push(item.id));
    }

    return arrId;
  };

  const getSubproductsAll = (arr: IProduct[]) => {
    const ids = getProductIds(arr);
    fetchSubproductsAllAsync(ids);
  };
  const deleteClient = async (clientid: string) => {
    const res = await deleteClientFn(clientid);
    if (res.status === 'ok') {
      toast.success('Клиент успешно удалён');
    } else {
      toast.error(res.description);
    }
    closeModal?.();
  };

  const deleteProduct = async (productid: string) => {
    const res = await deleteProductFn(productid);
    if (res.status === 'ok') {
      await productRefetch();
      toast.success('Категория успешно удалена');
    } else {
      toast.error(res.data.description);
    }
    closeModal?.();
  };

  const deleteColumn = async (columnId: string) => {
    const res = await apiDelete(`/tasker/columns/${columnId}`);
    if (res?.status === 200) {
      kanbanRefetch();
      toast.success(t('Колонка удалена'));
    } else {
      toast.error(t('Колонка не была удалена'));
    }
    closeModal?.();
  };
  const { mutate: deleteKanbanTask } = useMutation({
    mutationFn: (taskId: string) => TasksService.deleteTask(taskId),
    onSuccess: () => {
      toast.success(t('Задача удалена'));
      kanbanRefetch();
      closeModal?.();
    },
    onError: () => toast(t('Задача не была удалена'))
  });

  const deleteSubProduct = async (productid: string, subproductid: string) => {
    const res = await deleteService({ productid, subproductid });

    if (res?.status === 'ok') {
      if (products) {
        getSubproductsAll(products);
      }
      toast.success(t('Услуга успешно удалена'));
    } else {
      toast.error(res.description);
    }
    closeModal?.();
  };

  const { mutate: deleteLesson } = useMutation({
    mutationFn: (lessonId: string) => LessonsService.deleteLesson(lessonId),
    onSuccess: () => {
      toast.success(t('Запись удалена'));
      lessonsRefetch();
      closeModal && closeModal();
    },
    onError: () => toast.error(t('Запись не была удалена'))
  });

  const deleteFilial = async (filialid: string) => {
    const res = await apiDelete(`/filial/${filialid}`);
    if (res?.status === 200) {
      fetchFilialsAsync();
    } else {
      toast.error(res.data.description);
    }
    closeModal?.();
  };

  const deleteCabinet = async (filialid: string, cabinetId: string) => {
    const res = await deleteCabinetFn({ idcabinet: cabinetId, idfilial: filialid });
    if (res.Status === 'ok') {
      toast.success('Кабинет успешно удален');
    } else {
      toast.error(res.description);
    }
    closeModal?.();
  };

  const deleteSaleproduct = async (saleproductId: string) => {
    const res = await apiDelete(`/saleproduct/${saleproductId}`);
    if (res?.status === 200) {
      fetchSaleproductsAsync();
      toast.success('Товар успешно удален');
    } else {
      toast.error(res.data.description);
    }
    closeModal?.();
  };

  const deleteKassa = async (kassaid: string) => {
    const res = await apiDelete(`/kassa/${kassaid}`);
    if (res?.status === 200) {
      fetchListKassaAsync();
      toast.success('Касса удалена');
    } else {
      toast.error('Не удалось удалить кассу');
    }
    closeModal?.();
  };

  const deleteEmployee = async (userid: string) => {
    const res = await deleteUser(userid);

    if (res.status === 'ok') {
      toast.success(t('Сотрудник удален'));
    } else {
      toast.error(res.description);
    }
    closeModal?.();
  };

  const setFreeTarif = () => {
    closeModal?.();
  };

  const deleteItem = (action?: IActionName, modalPayload?: any) => {
    switch (action) {
      case 'PRODUCT':
        if (modalPayload.id) {
          deleteProduct(modalPayload.id);
        }
        break;
      case 'KASSA':
        if (modalPayload.id) {
          deleteKassa(modalPayload.id);
        }
        break;
      case 'COLUMN':
        if (modalPayload.id) {
          deleteColumn(modalPayload.id);
        }
        break;
      case 'KANBAN_TASK':
        if (modalPayload.id) {
          deleteKanbanTask(modalPayload.id);
        }
        break;
      case 'SUBPRODUCT':
        if (modalPayload.id && modalPayload.idparents) {
          deleteSubProduct(modalPayload.idparents, modalPayload.id);
        }
        break;
      case 'CLIENT':
        if (modalPayload.id) {
          deleteClient(modalPayload.id);
        }
        break;
      case 'FILIAL':
        if (modalPayload.id) {
          deleteFilial(modalPayload.id);
        }
        break;
      case 'EMPLOYEE':
        if (modalPayload.id) {
          deleteEmployee(modalPayload.id);
        }
        break;
      case 'CABINET':
        if (modalPayload.filial && modalPayload.id) {
          deleteCabinet(modalPayload.filial, modalPayload.id);
        }
        break;
      case 'SALEPRODUCT':
        if (modalPayload.id) {
          deleteSaleproduct(modalPayload.id);
        }
        break;
      case 'LESSON':
        if (modalPayload) {
          deleteLesson(modalPayload);
        }
        break;
      case 'TARIF':
        if (modalPayload) {
          setFreeTarif();
        }
        break;
      default:
        return;
    }
  };

  return (
    <FlexWithAlign
      $gap={MODAL_GAP}
      $column={true}
    >
      <FlexWithAlign
        $align={'center'}
        $gap={'10px'}
      >
        <Text $tag={TextType.H4}>
          {t('Удалить')} {modalPayload.name || modalPayload.filial || modalPayload.fio || modalPayload.titleColumn || t('запись')}
        </Text>
      </FlexWithAlign>
      <Text
        $tag={TextType.P}
        $pSize={TextPType.P15}
        color={'text02'}
        small
      >
        {t('Вы действительно хотите удалить')} "
        {modalPayload.name || modalPayload.filial || modalPayload.fio || modalPayload.titleColumn || t('запись')}"?
        {t(' Это действие нельзя будет отменить, и все связанные данные будут утеряны')}
      </Text>
      <FlexWithAlign
        $align='center'
        $justify='flex-start'
        $gap={MODAL_GAP}
      >
        <CommonButton
          typeBtn='gray'
          onClick={() => closeModal?.()}
          size={'M'}
        >
          {t('Отмена')}
        </CommonButton>
        <CommonButton
          typeBtn='danger'
          size={'M'}
          onClick={() => deleteItem(actionName, modalPayload)}
        >
          {t('Удалить')}
        </CommonButton>
      </FlexWithAlign>
    </FlexWithAlign>
  );
};

export default CommonConfirmDelete;
