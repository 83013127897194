import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { Wrapper } from './CreateTimeTable.styled';

import { ModalGrid } from '../../../../../pages/Recording/lib/CommonRecording.styled';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { InputType, Text, TextPType, TextType } from '../../../../../shared/ui';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import Timepicker from '../../../../../shared/ui/Timepicker/ui/Timepicker';
import Tooltip from '../../../../../shared/ui/Tooltip/ui/Tooltip';
import { useAllUsersScheduleQuery, useUsersQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';
import { MODAL_GAP, MODAL_TEXT_GAP } from '../../../../../styles';
import { apiPut } from '../../../../../utils/apiInstance';
import { generateRandomString } from '../../../../../utils/helperFunctions';
import { FlexWithAlign, FormStyle } from '../../../../../utils/styleUtils';
import { IconNew, EIcon } from '../../../../icons/medium-new-icons/icon';
import { useLocation } from 'react-router-dom';

interface IProps {
  closeModal: () => void;
  edit?: boolean;
  modalPayload?: any;
  closeOnOverlayClick?: boolean;
}

const transformedTemplates = [
  { value: 'base', label: 'Базовый' },
  { value: 'replaceable', label: '2 х 2' },
  { value: 'default', label: 'Без шаблона' }
];

const CreateTimeTable: FC<IProps> = (props) => {
  const { closeModal, modalPayload } = props;
  const { setActiveDate, updateTimeTableArr, clearTimeTableArr } = useActions();
  const { chosenMonth } = useTypedSelector((state1) => state1.calendar);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const { timeTableArr, showModal } = useTypedSelector((state) => state.modal);
  const { data: user } = useUsersQuery();
  const { refetch: updateTimeTable } = useAllUsersScheduleQuery({
    ids: user?.map((item) => item.id),
    dateStart: chosenMonth[0],
    dateEnd: chosenMonth[chosenMonth.length - 1]
  });
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [pending, setPending] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const initialValues = {
    timestart: '',
    timeend: '',

    template: 'Без шаблона',
    breaks: [{ bstart: '', bend: '' }]
  };
  const formik = useFormik({
    initialValues: modalPayload
      ? {
          timestart: modalPayload.timestart,
          timeend: modalPayload.timeend,

          template: 'Без шаблона',
          breaks: modalPayload.breaks || [{ bstart: '', bend: '' }]
        }
      : initialValues,
    onSubmit: (values: any) => {}
  });

  useEffect(() => {
    if (
      formik.values.timestart.length === 5 &&
      formik.values.timeend.length === 5 &&
      formik.values.timestart !== '' &&
      formik.values.timeend !== '' &&
      formik.values.timestart < formik.values.timeend
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
    if (formik.values.timestart >= formik.values.timeend) {
      formik.setFieldValue('timeend', '');
    }
  }, [formik.values]);
  const updateEmployeeSchedule = async (employeeId: string, values: any) => {
    setPending(true);

    if (timeTableArr && timeTableArr.length > 0) {
      const res = await apiPut(
        `/scheduser/${employeeId}`,
        timeTableArr.map((timeTable) => {
          return {
            date: dayjs(timeTable.date).format('YYYY-MM-DD'),
            timeend: values.timeend,
            timestart: values.timestart,
            breaks:
              values.breaks && values.breaks.filter((item: any) => item.bstart !== '' && item.bend !== '').length > 0
                ? values.breaks.filter((item: any) => item.bstart !== '' && item.bend !== '')
                : null
          };
        })
      );
      if (res?.status === 200) {
        setPending(false);
        toast.success('График обновлен');
        closeModal!();
        setActiveDate(modalPayload.date);
        updateTimeTable();
      } else {
        setPending(false);
        toast.error(res.data.description);
      }
      clearTimeTableArr();
    } else {
      toast('Выберите хотя бы 1 день');
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    updateEmployeeSchedule(modalPayload.employeeId, formik.values);
  };

  useEffect(() => {
    if (isMobile) {
      timeTableArr.length === 0 && closeModal();
    }
  }, [timeTableArr]);

  // useEffect(() => {
  //   if (modalPayload) {
  //     updateTimeTableArr({ employeeId: modalPayload.employeeId, date: modalPayload.date });
  //   }
  // }, []);

  const addBreakInput = () => {
    if (formik.values.breaks && formik.values.breaks.length >= 5) return;
    formik.setValues({
      ...formik.values,
      breaks: [...formik.values.breaks, { bstart: '', bend: '' }]
    });
  };

  const handleInputChange = (index: number, e: ChangeEvent<HTMLInputElement>, name: string) => {
    const newInputs = [...formik.values.breaks];

    if (name === 'bstart') {
      newInputs[index] = { ...newInputs[index], bstart: e.target?.value };
    } else if (name === 'bend') {
      newInputs[index] = { ...newInputs[index], bend: e.target?.value };
    }
    formik.setFieldValue('breaks', newInputs);
  };

  const deleteBreakInput = (index: number) => {
    formik.setValues({
      ...formik.values,
      breaks: [...formik.values.breaks.filter((item: any, i: number) => index !== i)]
    });
  };

  useEffect(() => {
    if (!pathname.includes('/timetable') && showModal) {
      closeModal!();
    }
  }, [pathname]);
  return (
    <Wrapper>
      <FlexWithAlign
        $gap={MODAL_GAP}
        $column
      >
        {isMobile ? null : (
          <Text
            $tag={TextType.H5}
            fontWeight={700}
          >
            {t('Сохранить график')}
          </Text>
        )}
        <FlexWithAlign
          className='top-wrapper'
          $justify='space-between'
          $gap={'12px'}
          $align={'center'}
        >
          <Text
            $tag={TextType.P}
            $pSize={TextPType.P15}
          >
            {t('Выбрано')}:{timeTableArr.length}
          </Text>
          <button
            className='reset-btn'
            onClick={() => clearTimeTableArr()}
          >
            {t('Сбросить выбор')}
          </button>
        </FlexWithAlign>
        <FlexWithAlign
          $column
          $gap={MODAL_TEXT_GAP}
          className={'form-wrapper'}
        >
          <Text
            $tag={TextType.P}
            $pSize={TextPType.P17}
            color={'text02'}
          >
            {t('Рабочее время')}
          </Text>
          <FormStyle onSubmit={handleSubmit}>
            <ModalGrid className='input-wrap'>
              <Timepicker
                size={InputType.M}
                label={t('Начало')}
                value={formik.values.timestart}
                name={'timestart'}
                onChange={formik.handleChange}
              />
              <span className='input-divide'></span>
              <Timepicker
                size={InputType.M}
                label={t('Конец')}
                value={formik.values.timeend}
                name={'timeend'}
                onChange={formik.handleChange}
                lowerLimit={formik.values.timestart}
              />
            </ModalGrid>
            <FlexWithAlign
              $column
              $gap={'12px'}
            >
              {formik.values.breaks && formik.values.breaks.length > 0 ? (
                <>
                  <FlexWithAlign
                    $align='center'
                    $gap='12px'
                  >
                    <Text
                      $tag={TextType.P}
                      $pSize={TextPType.P17}
                      color={'text02'}
                    >
                      {t('Перерыв')}
                    </Text>
                    {(!formik.values.timestart || !formik.values.timeend) && (
                      <div>
                        <Tooltip
                          text='Заполните начало и окончание рабочего времени'
                          icon={<IconNew name={EIcon.questionempty} />}
                          className={'time-table'}
                        />
                      </div>
                    )}
                  </FlexWithAlign>

                  {formik.values.breaks.map((input: any, i: number) => (
                    <ModalGrid
                      className='input-wrap'
                      key={generateRandomString()}
                    >
                      <Timepicker
                        size={InputType.M}
                        label={t('Начало')}
                        value={formik.values.breaks.find((item: any, itemIndex: number) => itemIndex === i).bstart || input.bstart}
                        name={`bstart-${i}`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(i, e, 'bstart')}
                        lowerLimit={formik.values.timestart}
                        upperLimit={input.bend || formik.values.timeend}
                        disabled={!formik.values.timeend || !formik.values.timestart}
                        isBreak={true}
                      />
                      <span className='input-divide'></span>
                      <Timepicker
                        size={InputType.M}
                        label={t('Конец')}
                        value={formik.values.breaks.find((item: any, itemIndex: number) => itemIndex === i).bend || input.bend}
                        name={`bend-${i}`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(i, e, 'bend')}
                        lowerLimit={input.bstart || formik.values.timestart}
                        upperLimit={formik.values.timeend}
                        disabled={!formik.values.timeend || !formik.values.timestart}
                        isBreak={true}
                      />
                      <CommonButton
                        type='button'
                        onlyIcon
                        className='delete-break-btn'
                        onClick={() => deleteBreakInput(i)}
                      >
                        <IconNew name={EIcon.closefill} />
                      </CommonButton>
                    </ModalGrid>
                  ))}
                </>
              ) : null}
              <div style={{ zIndex: 10 }}>
                <CommonButton
                  type={'button'}
                  typeBtn='primary'
                  fullWidth
                  size='M'
                  onClick={addBreakInput}
                  className={'add-break-btn'}
                  disabled={
                    (formik.values.breaks && formik.values.breaks.length >= 5) || !formik.values.timeend || !formik.values.timestart
                  }
                >
                  {t('Добавить перерыв')}
                </CommonButton>
              </div>
            </FlexWithAlign>
            <FlexWithAlign
              $justify='flex-end'
              $column
              $gap='12px'
              className='btns-wrap'
            >
              <CommonButton
                typeBtn='primary'
                type='submit'
                disabled={!formValid || !timeTableArr || timeTableArr.length === 0}
                size='M'
              >
                <span>{t('Сохранить график')}</span>
              </CommonButton>
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P15}
                fontWeight={800}
                color='black'
                className='divide-text'
              >
                {t('или')}
              </Text>

              <CommonButton
                typeBtn='secondary'
                type='button'
                onClick={() => updateEmployeeSchedule(modalPayload.employeeId, { timeend: '', timestart: '' })}
                size='M'
                disabled={!formValid || !timeTableArr || timeTableArr.length === 0}
              >
                {t('Сделать дни нерабочими')}
              </CommonButton>
            </FlexWithAlign>
          </FormStyle>
        </FlexWithAlign>
      </FlexWithAlign>
    </Wrapper>
  );
};

export default CreateTimeTable;
