import { reducer as analyticsReducer } from './analytics/analytics.slice';
import { reducer as authReducer } from './auth/auth.slice';
import { reducer as cabinetsReducer } from './cabinets/cabinets.slice';
import { reducer as calendarReducer } from './calendar/calendar.slice';
import { reducer as callRecordReducer } from './call-records/callRecords.slice';
import { reducer as classificatorsReducer } from './classificators/classificators.slice';
import { reducer as clientsReducer } from './clients/clients.slice';
import { reducer as filialReducer } from './filial/filial.slice';
import { reducer as financeReducer } from './finance/finance.slice';
import { reducer as lessonsReducer } from './lessons/lessons.slice';
import { reducer as modalReducer } from './modal/modal.slice';
import { reducer as notesReduces } from './notes/notes.slice';
import { reducer as paymentReducer } from './payments/payments.slice';
import { reducer as productsReducer } from './products/products.slice';
import { reducer as recordingReducer } from './recording/recording.slice';
import { reducer as saleproductsReducer } from './saleproducts/saleproducts.slice';
import { reducer as salesReducer } from './sales/sales.slice';
import { reducer as sidebarReducer } from './sidebar/sidebar.slice';
import { reducer as subproductsReducer } from './subproducts/subproducts.slice';
import { reducer as tarifReducer } from './tarif/tarif.slice';
import { reducer as tasksReducer } from './tasks/tasks.slice';
import { reducer as userReducer } from './user/user.slice';
import { reducer as chatReducer } from './websocket/chat-slice';
export const reducers = {
  user: userReducer,
  auth: authReducer,
  modal: modalReducer,
  calendar: calendarReducer,
  filial: filialReducer,
  analytics: analyticsReducer,
  subproducts: subproductsReducer,
  clients: clientsReducer,
  sidebar: sidebarReducer,
  cabinets: cabinetsReducer,
  finance: financeReducer,
  lessons: lessonsReducer,
  recording: recordingReducer,
  callRecords: callRecordReducer,
  tasks: tasksReducer,
  notes: notesReduces,
  classificators: classificatorsReducer,
  payments: paymentReducer,
  products: productsReducer,
  saleproducts: saleproductsReducer,
  sales: salesReducer,
  tarif: tarifReducer,
  chat: chatReducer,
};
