import { ChangeEventHandler } from 'react';

export enum InputType {
  L = 'l',
  M = 'm',
  S = 's'
}

export interface ISearchProps {
  value: string;
  ref?: any;
  className?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  size?: InputType;
  placeholder: string;
  type?: 'gray';
}
