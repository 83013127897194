import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Menu, Wrapper } from './NotesMenu.styled';

import { cn } from '../../../../shared/lib';
import { useOutside } from '../../../../shared/lib/hooks/useOutside';
import { Avatar, AvatarRounded, AvatarSize, Text, TextPType, TextType } from '../../../../shared/ui';
import CommonButton from '../../../../shared/ui/Button/ui/CommonButton';
import { useCreateNote, useDeleteNote } from '../../../../store/redux/notes/hooks/useNotesMutation';
import { useNotesQuery } from '../../../../store/redux/notes/hooks/useNotesQuery';
import { FlexWithAlign, IconWrapper, Padding } from '../../../../utils/styleUtils';
import { EIcon, IconNew as IconInstance } from '../../../icons/medium-new-icons/icon';
import NewNote from '../../../shared/note/new/NewNote';
import Note from '../../../shared/note/Note';
import { Info } from '../common-header/CommonHeader.styled';

const NotesMenu: FC = () => {
  const { t } = useTranslation();
  const [creation, setCreation] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { data: notes, isFetching } = useNotesQuery();
  const { mutateAsync: createNote } = useCreateNote();
  const { mutate: deleteNote } = useDeleteNote();
  const createNew = async (text: string) => {
    setIsPending(true);
    const res = await createNote({ text });

    setCreation(false);
    if (!creation) {
      setTimeout(() => {
        setIsPending(false);
      }, 500);
    }
  };
  const handleDelete = (note: any) => {
    deleteNote(note);
  };

  const { ref, isShow, setIsShow } = useOutside(false);
  const [isInfo, setIsInfo] = useState(false);

  return (
    <Wrapper ref={ref}>
      <Avatar
        svg={EIcon.file}
        onMouseEnter={() => setIsInfo(true)}
        onMouseLeave={() => setIsInfo(false)}
        onClick={() => setIsShow(!isShow)}
        size={AvatarSize.S}
        background={'thinBlue'}
        rounded={AvatarRounded.FULL}
      >
        {isInfo && !isShow && <Info>Заметки</Info>}
      </Avatar>

      <Menu className={cn(isShow && 'active')}>
        <FlexWithAlign className='header'>
          <Text $tag={TextType.H4}>{t('Заметки')}</Text>
        </FlexWithAlign>
        <div className='content'>
          {creation ? (
            <NewNote
              close={() => setCreation(false)}
              createNote={createNew}
            />
          ) : (
            <CommonButton
              typeBtn={'primary'}
              size={'M'}
              onClick={() => setCreation(true)}
              fullWidth
            >
              {t('Создать заметку')}
            </CommonButton>
          )}
          <Padding $size='20px' />
          {notes?.length ? (
            notes.map((note, i) => (
              <Note
                key={note.id + i}
                note={note}
                handleDelete={handleDelete}
              />
            ))
          ) : (
            <FlexWithAlign
              $gap='25px'
              $column
              className='empty'
            >
              <IconWrapper className={'h40'}>
                <IconInstance name={EIcon.file} />
              </IconWrapper>
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P13}
              >
                {t('Заметок нет')}
              </Text>
            </FlexWithAlign>
          )}
          {/* {isFetching && (
            <LoaderLayout>
              <CommonLoader />
            </LoaderLayout>
          )} */}
        </div>
      </Menu>
    </Wrapper>
  );
};

export default NotesMenu;
