import styled from 'styled-components';
import {
  RADIUS_M,
  DURATION,
  HEIGHT_INPUT,
  HEIGHT_S,
  SHADOW_DROPDOWN,
  SHADOW_INPUT,
  RADIUS_S,
  retrigger,
  FONT_M,
  FONT_S,
  HEIGHT_INPUT_M,
  SHADOW_INPUT_HOGER
} from '../../../../styles';
import { closeDropdownMenu, closeDropdownMenuTop, openDropdownMenu, openDropdownMenuTop } from '../../../../utils/styleUtils';

interface IProps {
  $position: 'top' | 'bottom';
}

export interface IInputStyles {
  size?: 'l' | 'm' | 's';
  $direction?: 'vertical' | 'horizontal';
}

export const MultiSelectWrapper = styled.div<IInputStyles>`
  max-width: 100%;
  min-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  width: 100%;
  position: relative;
  height: auto;
`;
export const Label = styled.div<IInputStyles>`
  position: relative;
  min-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  inset: 0;
  cursor: pointer;
  user-select: none;
  background: ${(props) => props.theme.color.inverse};
  box-shadow: ${SHADOW_INPUT};
  color: ${(props) => props.theme.text.text01};
  border-radius: ${RADIUS_M};
  width: 100%;
  height: 100%;
  display: flex;
  transition: box-shadow ${DURATION} ease-in-out;
  align-items: center;
  padding-inline-start: 1rem;
  &:hover {
    box-shadow: ${SHADOW_INPUT_HOGER};
  }
  &:after {
    transition-property: color;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    color: ${(props) => props.theme.color.base03};
    position: absolute;
    inset: 0;
    content: '';
    border-radius: inherit;
    border: 1px solid currentColor;
    pointer-events: none;
  }
  &.focused {
    &:after {
      border-width: 2px;
      color: ${(props) => props.theme.color.primary};
    }
  }
  &.error {
    &:after {
      color: ${(props) => props.theme.text.negative};
    }
  }
`;
export const Placeholder = styled.div<IInputStyles>`
  font: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? FONT_M : FONT_S;
        case 'm':
          return props.size ? FONT_S : FONT_S;
        case 's':
          return props.size ? FONT_S : FONT_S;
        default:
          return FONT_S;
      }
    } else {
      return FONT_S;
    }
  }};
`;
export const Wrapper = styled.div<IInputStyles>`
  position: relative;
  width: 100%;
  height: min-content;
  display: flex;
  overflow-x: auto;
  margin-right: 2.75rem;
  flex-direction: row;
  flex-wrap: ${(props) => {
    if (props.$direction !== undefined) {
      switch (props.$direction) {
        case 'horizontal':
          return props.$direction ? `no-wrap` : `wrap`;
        case 'vertical':
          return props.$direction ? `wrap` : `wrap`;
        default:
          return `wrap`;
      }
    } else {
      return `wrap`;
    }
  }};
  &::-webkit-scrollbar {
    display: none;
  }
  padding: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size
            ? `calc((${HEIGHT_INPUT} - ${HEIGHT_S} - 0.25rem) / 2) 0.4rem`
            : `calc((${HEIGHT_INPUT_M} - ${HEIGHT_S} - 0.25rem) / 2) 0.4rem`;
        case 'm':
          return props.size
            ? `calc((${HEIGHT_INPUT_M} - ${HEIGHT_S} - 0.25rem) / 2) 0.4rem`
            : `calc((${HEIGHT_INPUT_M} - ${HEIGHT_S} - 0.25rem) / 2) 0.4rem`;
        case 's':
          return props.size
            ? `calc((${HEIGHT_INPUT_M} - ${HEIGHT_S} - 0.25rem) / 2) 0.4rem`
            : `calc((${HEIGHT_INPUT_M} - ${HEIGHT_S} - 0.25rem) / 2) 0.4rem`;
        default:
          return `calc((${HEIGHT_INPUT_M} - ${HEIGHT_S} - 0.25rem) / 2) 0.4rem`;
      }
    } else {
      return `calc((${HEIGHT_INPUT_M} - ${HEIGHT_S} - 0.25rem) / 2) 0.4rem`;
    }
  }};
  transition: height ${DURATION} ease-in-out;
`;

export const OptionsContainer = styled.div<IProps>`
  position: absolute;
  top: ${(props) => (props.$position === 'top' ? 'auto' : '100%')};
  bottom: ${(props) => (props.$position === 'bottom' ? 'auto' : '100%')};
  left: 0;
  max-height: 10rem;
  overflow-y: auto;
  width: 100%;
  z-index: 4;
  background: ${(props) => props.theme.color.elevation01};
  border-radius: ${RADIUS_M};
  border: 1px solid ${(props) => props.theme.color.base04};
  box-shadow: ${SHADOW_DROPDOWN};
  margin-top: 8px;
  padding: calc(0.25rem - 0rem) 0.25rem;
  padding-bottom: 44px;

  transition-property: opacity, transform;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  visibility: hidden;
  animation: ${(props) => (props.$position === 'top' ? closeDropdownMenuTop : closeDropdownMenu)} ${DURATION} ease;
  transform-origin: ${(props) => (props.$position === 'top' ? 'bottom' : 'top')};
  > div {
    transition: opacity ${DURATION} ease-in-out;
    opacity: 0;
  }
  &.active {
    visibility: visible;
    animation: ${(props) => (props.$position === 'top' ? openDropdownMenuTop : openDropdownMenu)} ${DURATION} ease;
    transition-property: opacity, transform;
    transform-origin: ${(props) => (props.$position === 'top' ? 'bottom' : 'top')};
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    > div {
      transition: opacity ${DURATION} ease-in-out;
      opacity: 1;
    }
  }
`;

export const Option = styled.div<IInputStyles>`
  min-height: 2rem;
  padding: 0.375rem 0.525rem;
  cursor: pointer;
  margin: 0.125rem 0;
  transition: background ${DURATION} ease-in-out;
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
  animation: ${retrigger} 0.1s;
  &:hover {
    background-color: ${(props) => props.theme.color.inverseHover};
  }
  > div {
    margin-right: 0.75rem;
    height: 1.5rem;
    width: 1.5rem;
    border-right: ${RADIUS_S};
    display: block;
    position: relative;
    flex-shrink: 0;
    span {
      width: 24px;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 24px;
      background: transparent;
      border-radius: ${RADIUS_S};
      transition: background ${DURATION} ease-in-out;
      &:after {
        transition-property: border-color, color;
        transition-duration: ${DURATION};
        transition-timing-function: ease-in-out;
        position: absolute;
        pointer-events: none;
        inset: 0;
        content: '';
        border-radius: inherit;
        border: 1px solid currentColor;
        color: ${(props) => props.theme.color.base04};
      }
      svg {
        color: #fff;
        transition: scale ${DURATION} ease-in-out;
        transform: scale(0);
      }
      &.active {
        background: ${(props) => props.theme.text.link};
        &:after {
          border-color: ${(props) => props.theme.color.primary};
        }
        svg {
          transform: scale(1);
        }
      }
    }
    > input {
      position: absolute;
      inset: 0;
      width: 100%;
      opacity: 0;
      height: 100%;
    }
  }
  > p {
    flex: 1;
    font: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? FONT_M : FONT_S;
          case 'm':
            return props.size ? FONT_S : FONT_S;
          case 's':
            return props.size ? FONT_S : FONT_S;
          default:
            return FONT_S;
        }
      } else {
        return FONT_S;
      }
    }};
    color: ${(props) => props.theme.text.text01};
  }
`;

export const SelectedOption = styled.div<IInputStyles>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.color.base01};
  font: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? FONT_M : FONT_S;
        case 'm':
          return props.size ? FONT_S : FONT_S;
        case 's':
          return props.size ? FONT_S : FONT_S;
        default:
          return FONT_S;
      }
    } else {
      return FONT_S;
    }
  }};
  line-height: 20px;
`;
export const SelectedOptions = styled.div<IInputStyles>`
  margin: 0.125rem 0.5rem 0.125rem -0.25rem;
  margin-inline-end: 0.5rem;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  z-index: 4;
  width: min-content;
  transition: background ${DURATION} ease-in-out;
  &:hover {
    background: ${(props) => props.theme.color.primaryHover};
  }
  background: ${(props) => props.theme.color.primary};
  border-radius: ${RADIUS_M};
  > .tag {
    height: ${HEIGHT_S};
    padding: 0 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: flex;
    width: auto;
    align-items: center;
    font: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? FONT_M : FONT_S;
          case 'm':
            return props.size ? FONT_S : FONT_S;
          case 's':
            return props.size ? FONT_S : FONT_S;
          default:
            return FONT_S;
        }
      } else {
        return FONT_S;
      }
    }};
    gap: 4px;
    button {
      position: relative;
      width: 10px;
      height: 10px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      width: 10px;
      height: 10px;
      color: ${(props) => props.theme.color.base01};
    }
  }
`;

export const SelectArrow = styled.button<IInputStyles>`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.6rem;
  right: 1rem;
  position: absolute;
  opacity: 0.5;
  transition-property: opacity;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;

  > svg {
    color: ${(props) => props.theme.text.text01};
    transition-property: opacity, transform;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
  }
  &.active {
    > svg {
      transform: rotate(180deg);
    }
  }
  &:hover {
    opacity: 1;
  }
`;
