import React, { FC } from 'react';

import { TextType, TextProps } from './Text.props';
import { StyledHTag } from './Text.styled';

export const Text: FC<TextProps> = ({
  $tag = TextType.H6,
  color,
  small,
  fontWeight,
  muted = false,
  className,
  children,
  $pSize,
  ...props
}: TextProps) => {
  return (
    <StyledHTag
      as={$tag}
      $small={small}
      color={color}
      fontWeight={fontWeight}
      className={className}
      $pSize={$pSize}
      $tag={$tag}
      {...props}
    >
      {children}
    </StyledHTag>
  );
};
