import React, { FC } from 'react';
import { EIcon, IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';
import { FilterProps } from './Filter.props';
import { FilterItemWrapper, Options } from './Filter.styled';
import { useOutside } from '../../../lib/hooks/useOutside';
import { cn } from '../../../lib';

export const Filter: FC<FilterProps> = ({ color, label, className, onClick, children, $fSize, $counter, ...props }: FilterProps) => {
  const { ref, isShow, setIsShow } = useOutside(false);

  return (
    <FilterItemWrapper
      color={color}
      className={cn(isShow && 'active', className)}
      $fSize={$fSize}
      ref={ref}

      {...props}
      onClick={() => setIsShow(!isShow)}
    >
      <p>{label}</p>
      {$counter !== undefined && <span>{$counter}</span>}
      <IconInstance name={EIcon.chevrondownsmall} />
      <Options
        onClick={onClick}
        className={cn(isShow ? 'open' : 'close')}
      >
        {children}
      </Options>
    </FilterItemWrapper>
  );
};
