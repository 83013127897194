import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ClassificatorsService } from '../../../../shared/api/model/service/classificators.service';
import { CLASSIFICATORS_QUERY } from '../../../../shared/const/query.const';
import { IClassificator } from '../classificators.interface';

export const useAddClassificator = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Omit<IClassificator, 'id'>) => ClassificatorsService.addClassificator(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CLASSIFICATORS_QUERY] });
      }
    }
  });
};

export const useDeleteClassificator = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => ClassificatorsService.deleteClassificator(id),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CLASSIFICATORS_QUERY] });
      }
    }
  });
};
