import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Container, Icon, Item, ItemLink, Line, Menu, Wrapper } from './PlusMenu.styled';

import { cn } from '../../../../shared/lib';
import { useActions } from '../../../../shared/lib/hooks/useActions';
import { useOutside } from '../../../../shared/lib/hooks/useOutside';
import { useTypedSelector } from '../../../../shared/lib/hooks/useTypedSelector';
import { EIcon, IconNew as IconInstance } from '../../../icons/medium-new-icons/icon';
import { Info } from '../common-header/CommonHeader.styled';
import { Avatar, AvatarRounded, AvatarSize, Text, TextPType, TextType } from '../../../../shared/ui';

const PlusMenu: FC = () => {
  const { ref, isShow, setIsShow } = useOutside(false);
  const { openModal } = useActions();
  const { balance } = useTypedSelector((state) => state.tarif);
  const [isInfo, setIsInfo] = useState(false);
  const { t } = useTranslation();

  const nav = useNavigate();
  const createEmployeer = () => {
    if (balance < 0) return;
    setIsShow(false);
    openModal({ modalName: 'CREATE_EMPLOYEE' });
  };

  const createClient = () => {
    setIsShow(false);
    openModal({ modalName: 'CREATE_CLIENT' });
  };

  const goToRecordPage = () => {
    setIsShow(false);
    nav('/products/employee/calendar');
  };
  const menu = [
    {
      title: t('Создать запись'),
      icon: <IconInstance name={EIcon.sidebarrecording} />,
      action: () => goToRecordPage()
    },
    {
      title: t('Новый клиент'),
      icon: <IconInstance name={EIcon.sidebarclients} />,
      action: createClient,
      endTab: true
    },
    {
      title: t('Добавить услугу'),
      icon: <IconInstance name={EIcon.sidebarcompany} />,
      to: '/management/service-categories'
    },
    {
      title: t('Добавить сотрудника'),
      icon: <IconInstance name={EIcon.sidebaremployee} />,
      action: createEmployeer
    }
  ];

  return (
    <Wrapper ref={ref}>
      <Avatar
        svg={EIcon.plus}
        onMouseEnter={() => setIsInfo(true)}
        onMouseLeave={() => setIsInfo(false)}
        size={AvatarSize.S}
        onClick={() => setIsShow(!isShow)}
        background={'thinBlue'}
        rounded={AvatarRounded.FULL}
      >
        {isInfo && !isShow && <Info>Создание</Info>}
      </Avatar>

      <Menu className={cn(isShow ? 'open' : 'close')}>
        {menu.map((item) => (
          <Fragment key={item.title}>
            <Container>
              {item.to ? (
                <ItemLink
                  to={item.to}
                  onClick={() => setIsShow(false)}
                >
                  <Icon>{item.icon}</Icon>
                  <Text
                    $tag={TextType.P}
                    $pSize={TextPType.P13}
                  >
                    {item.title}
                  </Text>
                </ItemLink>
              ) : (
                <Item onClick={item.action}>
                  <Icon>{item.icon}</Icon>
                  <Text
                    $tag={TextType.P}
                    $pSize={TextPType.P13}
                  >
                    {item.title}
                  </Text>
                </Item>
              )}
            </Container>
            {item.endTab && <Line />}
          </Fragment>
        ))}
      </Menu>
    </Wrapper>
  );
};

export default PlusMenu;
