import { ISetMyTarif } from '../../../../store/redux/tarif/tarif.interface';
import { setCookie } from '../../../../utils/cookies';
import { apiGet, apiPost } from '../../interceptors';

export const TarifService = {
  async getMyTarif() {
    const response = await apiGet('/mytarif');

    return response.data && response.data.tarif ? response.data.tarif : {};
  },
  async getBalanсe() {
    const response = await apiGet('/balance');
    return response.data;
  },
  async getHistory() {
    const response = await apiGet('/history');
    return response.data;
  },
  async setMyTarif(values: ISetMyTarif) {
    const response = await apiPost(`tarif/sale`, values);
    if (response.status === 200) {
      setCookie('paymentIdYooKassa', response.data.payment_id);
    }
    return response.data;
  },

  async verifyPay(paymentId: string) {
    const response = await apiPost('/tarif/verifypay', { paiment_id: paymentId });
    return response.data;
  }
};
