export interface Theme {
  color: any;
  text: any;
  status: any;
}

const themelight: Theme = {
  //BASE PALETTE
  color: {
    //Page primary background and icon fill for color inverted background
    base01: '#FFFFFF',
    // Page secondary background, tile on primary background and background of static marker icons
    base02: '#F6F6F6',

    // Hovered backgrounds for tiles
    base03: '#EDEDED',

    // Table borders, islands and blocks
    base04: '#E0E0E0',

    // Table borders, islands and blocks
    base05: '#B0B0B0',

    // Hovered color for interactive interface icons with --tui-base-05
    base06: '#959595',

    // For blocks with inverted background color
    base07: '#808080',

    // Alternative interface icons color
    base08: '#333333',

    // Icons color over inverted background
    base09: '#000000',

    // Background of buttons, marker icons
    primary: '#467FF3',

    // Hovered state background of buttons, marker icons
    primaryHover: '#6C86E2',

    // Active state background of buttons
    primaryActive: '#314692',

    // Background of input field and secondary buttons
    secondary: '#ebefff',

    // Hovered state background of input field and secondary buttons
    secondaryHover: '#dfe3f3',

    // Active state background of input field and secondary buttons
    secondaryActive: '#d8ddf2',

    // Background of accent icons or buttons
    accent: '#FF7043',

    // Hovered state background of accent icons or buttons
    accentHover: '#FF8D69',

    // Active state background of accent icons or buttons
    accentActive: '#ED5A2B',

    // Background of accent icons or buttons
    clear: '#0000000A',

    // Hovered state background of accent icons or buttons
    clearHover: '#00000014',

    // Active state background of accent icons or buttons
    clearActive: '#0000001F',

    boxShadow: '#0000001A',
    black66: '#00000066',
    black99: '#00000099',
    inverse: '#FFFFFF',

    inverseHover: '#FFFFFF',

    inverseActive: '#FFFFFF',

    elevation01: '#FFFFFF',

    lightRed: '#FF000080',
    thinBlue: '#EDF5FF',
    secondaryBlue: '#EFF3FF',
    hoverBlue: '#6C86E2',
    darkBlue: '#314692',
    lightBg: '#f0f0f0',
    text: '#fff',
    mainLight: '#5089FD',
    secondLight: '#246BFD',
    mainDark: '#292F51',
    mainDarkFull: '#333333',
    dark: '#141414',
    black: '#000000',
    black4: '#0000000A',
    sidebarGray: '#1B1F3B',
    sidebarGray65: '#1B1F3BA6',
    sidebarActive: '#191c39',
    sidebarMedium: '#191C30',
    sidebarMedium90: '#191C30E5',
    success: '#4AC99B',
    warning: '#EFDC31',
    yellow: '#E4AF09',
    orange: '#FF7F41',
    danger: '#FF453A',
    secondaryLight: '#F9F9F9',
    secondaryMedium: '#EAEBEE',
    secondaryLightMedium: '#E5E9EB',
    secondaryUltraLight: '#79818C1F',
    secondaryDark: '#9497A8',
    secondaryDark66: '#666666',
    secondaryFullDark: '#59616B',
    secondaryGrey: '#6C6E7C',
    secondaryMediumLight: '#F5F6F8',
    secondaryMediumDark: '#84879D',
    fieldGray: '#ECF1F7',
    disabled: '#717681',
    active: '#0062FF',
    greyD9: '#D9D9D9',
    grayE0: '#E0E0E0',
    grayD7: '#D7D7D7',
    gray0D: '#0000000D',
    gray66: '#1B1F3B66',
    grayF4: '#F4F4F4'
  },
  //TEXT
  text: {
    //Primary text and hedings
    text01: '#1b1f3b',

    //Secondary text and descriptions
    text02: 'rgba(27, 31, 59, .65)',

    //Inactive and additional points, minor information and helpers
    text03: 'rgba(27, 31, 59, .4)',

    //Link and secondary buttons text
    link: '#467FF3',

    //Hovered state of link and secondary buttons text
    linkHover: '#6c86e2',

    //Positive values: income, stock price grow, etc.
    positive: '#3aa981',

    //Hovered state of positive values: income, stock price grow, etc.
    positiveHover: '#7ac5aa',

    //Negative values: expense, stock price fall, etc.
    negative: '#DD4C1E',

    //Hovered state of negative values: expense, stock price fall, etc.
    negativeHover: '#E38163'
  },
  //STATUS
  status: {
    //Icons or other elements with success status
    successFill: '#4AC99B',

    //Hovered state of background for elements with success status
    successBgHover: '#4AC99B3D',

    //Background for elements with success status
    successBg: '#4AC99B1F',

    //Icons or other elements with error status
    errorFill: '#F45725',

    //Hovered state of background for elements with error status
    errorBgHover: '#F457253D',

    //Background for elements with error status
    errorBg: '#F457251F',

    //Icons or other elements with info status
    infoFill: '#70B6F6',

    //Hovered status of background icons or other elements with info status
    infoBgHover: '#70B6F63D',

    //Icons or other elements with warning status
    infoBg: '#70B6F61F',

    //Icons or other elements with warning status
    warningFill: '#FFC700',

    //Hovered status of background icons or other elements with warning status
    warningBgHover: '#FFC7003D',

    //Icons or other elements with neutral status
    warningBg: '#FFC7001F',

    //Icons or other elements with neutral status
    neutralFull: '#79818C',

    //Hovered status of background icons or other elements with neutral status
    neutralBgHover: '#79818C3D',

    //Background icons or other elements with neutral status
    neutralBg: '#79818C1F'
  }
};

export default themelight;
