import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > div {
    flex-grow: inherit;
  }
  .form-wrapper {
    flex-grow: inherit;

    form {
      flex-grow: inherit;
    }
  }
  .input-wrap {
    //padding: 0 10px;
    gap: 6px;
    grid-template-columns: 1fr 0.17fr 1fr 0.1fr;
    align-items: center;
  }
  .input-divide {
    margin: 0 auto;
    display: inline-block;
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background-color: ${(props) => props.theme.color.base08};
  }
  h3 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    justify-self: left;
  }
  .title-time-table {
    padding: 0 10px;
  }
  ul.react-datepicker__time-list {
    background-color: ${(props) => props.theme.color.base02};
  }
  .top-wrapper {
    padding: 16px;
    background-color: ${(props) => props.theme.color.base03};
    width: 100%;
    height: 44px;
    border-radius: 12px;
  }
  .chosen-text {
    font-size: 15px;
    line-height: 20px;
  }
  .reset-btn {
    color: #5089fd;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px; /* 115.385% */
  }
  form {
    gap: 30px;
  }

  .react-datepicker__time-list-item {
    font-size: 12px !important;
  }
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 93px;
  }
  .react-datepicker-popper {
    left: 0 !important;
    top: 40px !important;
  }
  .btns-wrap {
    align-items: stretch;
    margin-top: auto;
    button {
      width: 100%;
      text-align: center;
    }
    & > span {
      color: #292f51;
      text-align: center;
      font-size: 13px;
      line-height: 15px; /* 115.385% */
    }
  }
  .divide-text {
    text-align: center;
  }
  .add-break-btn {
    z-index: 10;
  }
  .delete-break-btn {
    padding: 0;
    border-radius: 50%;
    background-color: ${(props) => props.theme.color.base02};
    svg {
      width: 16px;
      height: 16px;
      transition: all 0.3s ease;
    }
    &:hover,
    &:focus {
      background-color: ${(props) => props.theme.color.danger};
      svg {
        color: ${(props) => props.theme.color.base01};
      }
    }
  }
`;
