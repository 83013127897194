import { ReactNode } from 'react';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IActionName, IModalName, ITimeTableModal, ModalState } from './modal.interface';

import { scrollController } from '../../../components/layouts/InnerLayout';
import { ModalSize } from '../../../shared/ui';
import { ModalType } from '../../../shared/ui/Modal/ui/Modal.props';
import { IEmployeeScheduleSlotPayload } from '../user/user.interface';

const initialState: ModalState = {
  showModal: false,
  modalName: '',
  modalSize: ModalSize.M,
  modalHeader: '',
  modalPayload: undefined,
  isDanger: false,
  modalType: ModalType.CENTER,
  actionName: '',
  classModal: undefined,
  closeOnOverlayClick: true,
  timeTableArr: [],
  stateNum: 1,
  skeleton: null,
  chosenEmployeeScheduleSlots: []
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        modalName: IModalName;
        modalSize?: ModalSize;
        modalHeader?: string;
        modalType?: ModalType;
        modalPayload?: any;
        isDanger?: boolean;
        actionName?: IActionName;
        classModal?: string;
        closeOnOverlayClick?: boolean;
        stateNum?: number;
        skeleton?: ReactNode;
      }>
    ) => {
      state.showModal = true;
      state.modalSize = action.payload.modalSize;
      state.modalType = action.payload.modalType;
      state.modalHeader = action.payload.modalHeader;
      state.modalName = action.payload.modalName;
      state.modalPayload = action.payload.modalPayload || undefined;
      state.isDanger = action.payload.isDanger || false;
      state.actionName = action.payload.actionName || '';
      state.classModal = action.payload.classModal || undefined;
      state.stateNum = action.payload.stateNum || 1;
      state.skeleton = action.payload.skeleton || null;
    },
    closeModal: (state) => {
      state.showModal = false;
      state.chosenEmployeeScheduleSlots = [];
      scrollController.enabledScroll();
    },
    updateTimeTableArr: (state, action: PayloadAction<ITimeTableModal>) => {
      const resArr: ITimeTableModal[] = state.timeTableArr;
      const itemIndex = state.timeTableArr.findIndex(
        (it) => it.date === action.payload.date && it.employeeId === action.payload.employeeId
      );
      if (itemIndex === -1) {
        state.timeTableArr = [...state.timeTableArr.filter((item) => item.employeeId === action.payload.employeeId), action.payload];
        // state.timeTableArr = [...state.timeTableArr, action.payload];
      } else {
        state.timeTableArr = resArr.filter((it) => it.date !== action.payload.date || it.employeeId !== action.payload.employeeId);
      }
    },
    clearTimeTableArr: (state) => {
      state.timeTableArr = [];
    },
    updateEmployeeScheduleSlots: (state, action: PayloadAction<IEmployeeScheduleSlotPayload>) => {
      const { isEdit, data } = action.payload;
      state.chosenEmployeeScheduleSlots = [
        ...state.chosenEmployeeScheduleSlots.filter((slot) => !data.find((item) => item.date === slot.date)),
        ...data
      ];
    },
    clearEmployeeScheduleSlots: (state) => {
      state.chosenEmployeeScheduleSlots = [];
    }
  }
});

export const { reducer } = modalSlice;
