import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilial } from './filial.interface';
import { FULFILLED, PENDING, REJECTED, STATE } from '../../../utils/state';
import { fetchFilialsAsync, setActiveFilial } from './filial.actions';

interface FilialState {
  filials: IFilial[] | [];
  state: STATE;
  activeFilial: IFilial | undefined;
  error: string | null;
}

const initialState: FilialState = {
  filials: [],
  activeFilial: undefined,
  state: PENDING,
  error: null
};

export const filialSlice = createSlice({
  name: 'filials',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilialsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchFilialsAsync.fulfilled, (state, action) => {
        state.state = FULFILLED;
        state.filials = action.payload;
      })
      .addCase(fetchFilialsAsync.rejected, (state, action) => {
        state.state = REJECTED;
        state.error = action.error.message || null;
      })
      .addCase(setActiveFilial, (state, action: PayloadAction<string>) => {
        const id = action.payload;
        state.activeFilial = state.filials.find((filial) => filial.id === id);
        localStorage.setItem('currentFilialId', id);
      });
  }
});

export const { reducer } = filialSlice;
