import React from 'react';

import { AnalyticsViewPageAsync } from '../../../../pages/AnalyticsPage';
import { AuthPageAsync } from '../../../../pages/AuthPage';
import { CallDialogInIdleTimeAsync, CallProductAsync } from '../../../../pages/CallPage';
import { ClientPageAsync } from '../../../../pages/ClientPage';
import { ConfirmRegistrationAsync } from '../../../../pages/ConfirmRegistration';
import { EmployeersPageAsync } from '../../../../pages/EmployeesPage';
import { EmployeeTimeTablePageAsync } from '../../../../pages/EmployeesPage';
import { CashRegistersAsync, FinancePageAsync, ManagementProductsAsync, PaymentsAsync } from '../../../../pages/FinancePage';
import { MainPageAsync } from '../../../../pages/MainPage';
import {
  ManagementEmployeersPageAsync,
  ManagementSchedulePageAsync,
  ManagementSummaryPageAsync,
  ManagementServiceCategoriesPageAsync,
  ManagementTimeTablePageAsync,
  ManagementSettingsPageAsync
} from '../../../../pages/ManagementPage';
import { MarketingPageAsync } from '../../../../pages/MarketingPage/ui/MarketingPage.async';
import { MessengerAsync } from '../../../../pages/MessengerPage';
import MessengerList from '../../../../pages/MessengerPage/ui/mobile/MessengerList';
import MobileChat from '../../../../pages/MessengerPage/ui/mobile/MobileChat';
import { MorePageAsync } from '../../../../pages/MorePage';
import { NotFoundPageAsync } from '../../../../pages/NotFoundPage';
import { PortalRedirectPaymentPageAsync, PortalSettingsTarifsPageAsync } from '../../../../pages/PortalSettingsPage';
import { ProfilePageAsync } from '../../../../pages/ProfilePage';
import { ProductCabinetAsync, ProductEmployeeAsync } from '../../../../pages/Recording';
import { RegistrationPageAsync } from '../../../../pages/RegistrationPage';
import { NewPasswordAsync, ResetPasswordAsync, SuccessPaswordAsync } from '../../../../pages/ResetPasswordPage';
import { SupportPageAsync } from '../../../../pages/SupportPage';
import { TaskBookPageAsync } from '../../../../pages/TaskBookPage';
import { UserRecordingAsync } from '../../../../pages/UserRecordingPage/UserRecording.async';
import {
  getRouteAnalytics,
  getRouteAuth,
  getRouteCallDialog,
  getRouteCallProduct,
  getRouteCashRegister,
  getRouteCashRegisterSettings,
  getRouteClient,
  getRouteEmployeesSummary,
  getRouteEmployeesTimetable,
  getRouteFinance,
  getRouteManagementProducts,
  getRouteMain,
  getRouteManagementEmployee,
  getRouteManagementSchedule,
  getRouteManagementServiceCategory,
  getRouteManagementSettings,
  getRouteManagementSummary,
  getRouteManagementTimetable,
  getRouteMessenger,
  getRouteNewPassword,
  getRouteNotFound,
  getRoutePortalRedirectPayment,
  getRoutePortalTarif,
  getRouteProductCabinets,
  getRouteProductEmployee,
  getRouteProfile,
  getRouteRegister,
  getRouteRegisterConfirm,
  getRouteResetPassword,
  getRouteSupport,
  getRouteSussessPasword,
  getRouteTaskbook,
  getRouteMarketing,
  getRouteUserRecording,
  getRouteMessengerList,
  getRouteMessengerChat,
  getRouteUserRecordingAll,
  getMoreMenu
} from '../../../../shared/const';
import { AppRoutes } from '../model/const';
import { AppRouteProps } from '../model/types';

/* тут мы храним все роуты приложения */
export const routeConfig: Record<AppRoutes, AppRouteProps> = {
  [AppRoutes.AUTH]: {
    path: getRouteAuth(),
    element: <AuthPageAsync />,
    authOnly: false,
    title: 'Авторизация'
  },
  [AppRoutes.SUPPORT]: {
    path: getRouteSupport(),
    element: <SupportPageAsync />,
    authOnly: true,
    title: 'Служба поддержки'
  },
  [AppRoutes.MAIN]: {
    path: getRouteMain(),
    element: <MainPageAsync />,
    authOnly: true,
    title: 'Рабочий стол'
  },
  [AppRoutes.MARKETING]: {
    path: getRouteMarketing(),
    element: <MarketingPageAsync />,
    authOnly: true,
    title: 'Онлайн запись'
  },
  [AppRoutes.CLIENT]: {
    path: getRouteClient(),
    element: <ClientPageAsync />,
    authOnly: true,
    title: 'Клиенты'
  },
  [AppRoutes.FINANCE]: {
    path: getRouteFinance(),
    element: <FinancePageAsync />,
    authOnly: true,
    title: 'Финансы'
  },
  [AppRoutes.CASH_REGISTERS]: {
    path: getRouteCashRegister(),
    element: <CashRegistersAsync />,
    authOnly: true,
    title: 'Финансы'
  },
  [AppRoutes.CASH_REGISTERS_SETTINGS]: {
    path: getRouteCashRegisterSettings(),
    element: <PaymentsAsync />,
    authOnly: true,
    title: 'Финансы'
  },
  [AppRoutes.FINANCE_PRODUCTS]: {
    path: getRouteManagementProducts(),
    element: <ManagementProductsAsync />,
    authOnly: true,
    title: 'Финансы'
  },
  [AppRoutes.TASKBOOK]: {
    path: getRouteTaskbook(),
    element: <TaskBookPageAsync />,
    authOnly: true,
    title: 'Задачи'
  },
  [AppRoutes.PRODUCT_CABINET]: {
    path: getRouteProductCabinets(),
    element: <ProductCabinetAsync />,
    authOnly: true,
    title: 'Журнал записи'
  },
  [AppRoutes.PRODUCT_EMPLOYEE]: {
    path: getRouteProductEmployee(),
    element: <ProductEmployeeAsync />,
    authOnly: true,
    title: 'Журнал записи'
  },
  [AppRoutes.CALL_PRODUCT]: {
    path: getRouteCallProduct(),
    element: <CallProductAsync />,
    authOnly: true,
    title: 'Вызов'
  },
  [AppRoutes.CALL_DIALOG_INIDLETIME]: {
    path: getRouteCallDialog(),
    element: <CallDialogInIdleTimeAsync />,
    authOnly: true,
    title: 'Вызов'
  },
  [AppRoutes.MANAGEMENT_SUMMARY]: {
    path: getRouteManagementSummary(),
    element: <ManagementSummaryPageAsync />,
    authOnly: true,
    title: 'Компания'
  },
  [AppRoutes.MANAGEMENT_SCHEDULE]: {
    path: getRouteManagementSchedule(),
    element: <ManagementSchedulePageAsync />,
    authOnly: true,
    title: 'Компания'
  },
  [AppRoutes.MANAGEMENT_SERVICE_CATEGORY]: {
    path: getRouteManagementServiceCategory(),
    element: <ManagementServiceCategoriesPageAsync />,
    authOnly: true,
    title: 'Компания'
  },
  [AppRoutes.MANAGEMENT_EMPLOYEE]: {
    path: getRouteManagementEmployee(),
    element: <ManagementEmployeersPageAsync />,
    authOnly: true,
    title: 'Компания'
  },
  [AppRoutes.MANAGEMENT_TIMETABLE]: {
    path: getRouteManagementTimetable(),
    element: <ManagementTimeTablePageAsync />,
    authOnly: true,
    title: 'Компания'
  },
  [AppRoutes.MANAGEMENT_SETTINGS]: {
    path: getRouteManagementSettings(),
    element: <ManagementSettingsPageAsync />,
    authOnly: true,
    title: 'Компания настройки'
  },
  [AppRoutes.EMPLOYEES_SUMMARY]: {
    path: getRouteEmployeesSummary(),
    element: <EmployeersPageAsync />,
    authOnly: true,
    title: 'Сотрудники'
  },
  [AppRoutes.EMPLOYEES_TIMETABLE]: {
    path: getRouteEmployeesTimetable(),
    element: <EmployeeTimeTablePageAsync />,
    authOnly: true,
    title: 'График работы'
  },
  [AppRoutes.ANALYTICS]: {
    path: getRouteAnalytics(),
    element: <AnalyticsViewPageAsync />,
    authOnly: true,
    title: 'Аналитика'
  },
  [AppRoutes.PROFILE]: {
    path: getRouteProfile(),
    element: <ProfilePageAsync />,
    authOnly: true,
    title: 'Личный кабинет'
  },
  [AppRoutes.PORTAL_TARIF]: {
    path: getRoutePortalTarif(),
    element: <PortalSettingsTarifsPageAsync />,
    authOnly: true,
    title: 'Портал'
  },
  [AppRoutes.PROTAL_REDIRECT_PAYMENT]: {
    path: getRoutePortalRedirectPayment(),
    element: <PortalRedirectPaymentPageAsync />,
    authOnly: true,
    title: 'Портал'
  },
  [AppRoutes.NOT_FOUND]: {
    path: getRouteNotFound(),
    element: <NotFoundPageAsync />,
    authOnly: false,
    title: '404'
  },
  [AppRoutes.REGISTER]: {
    path: getRouteRegister(),
    element: <RegistrationPageAsync />,
    authOnly: false,
    title: 'Регистрация'
  },
  [AppRoutes.REGISTER_CONFIRM]: {
    path: getRouteRegisterConfirm(),
    element: <ConfirmRegistrationAsync />,
    authOnly: false,
    title: 'Регистрация'
  },
  [AppRoutes.RESET_PASSWORD]: {
    path: getRouteResetPassword(),
    element: <ResetPasswordAsync />,
    authOnly: false,
    title: 'Восстановление пароля'
  },
  [AppRoutes.NEW_PASSWORD]: {
    path: getRouteNewPassword(),
    element: <NewPasswordAsync />,
    authOnly: false,
    title: 'Восстановление пароля'
  },
  [AppRoutes.SUCCESS_PASSWORD]: {
    path: getRouteSussessPasword(),
    element: <SuccessPaswordAsync />,
    authOnly: false,
    title: 'Восстановление пароля'
  },
  [AppRoutes.MESSENGER]: {
    path: getRouteMessenger(),
    element: <MessengerAsync />,
    authOnly: true,
    title: 'Мессенджер'
  },
  [AppRoutes.MESSENGER_MOBILE_CHAT]: {
    path: getRouteMessengerChat(),
    element: <MobileChat />,
    authOnly: true,
    title: 'Мессенджер'
  },
  [AppRoutes.MESSENGER_MOBILE_LIST]: {
    path: getRouteMessengerList(),
    element: <MessengerList />,
    authOnly: true,
    title: 'Мессенджер'
  },
  [AppRoutes.USERRECORDING]: {
    path: getRouteUserRecording(),
    element: <UserRecordingAsync />,
    authOnly: false,
    title: 'Запись'
  },
  [AppRoutes.USERRECORDINGALL]: {
    path: getRouteUserRecordingAll(),
    element: <UserRecordingAsync />,
    authOnly: false,
    title: 'Все записи'
  },
  [AppRoutes.MOREMENU]: {
    path: getMoreMenu(),
    element: <MorePageAsync />,
    authOnly: true,
    title: 'Меню'
  }
};
