import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';
import { createSlice } from '@reduxjs/toolkit';
import { SalesState } from './sales.interface';
import { fetchSalesAsync } from './sales.actions';

const initialState: SalesState = {
  sale: [],
  state: PENDING
};

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchSalesAsync.fulfilled, (state, action) => {
        state.sale = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchSalesAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer } = salesSlice;
