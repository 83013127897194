import styled from 'styled-components';
import { FlexWithAlign, scaleIn } from '../../../../utils/styleUtils';

export const MessengerWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
`;

export const MobileMessengerWrapper = styled.div`
  height: 100%;
`

export const Notify = styled(FlexWithAlign)`
  position: absolute;
  top: 10px;
  padding: 12px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background: ${(props) => props.theme.color.base01};
  z-index: 5;
  right: 10px;
  animation: ${scaleIn} 0.3s ease;
  transform-origin: right;
`;
