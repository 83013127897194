import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotesService } from '../../../../shared/api/model/service/notes.service';
import { NOTES_QUERY } from '../../../../shared/const/query.const';

export function useCreateNote() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { text: string }) => NotesService.createNotes(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [NOTES_QUERY] });
      }
    }
  });
}

export function useDeleteNote() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { id: string }) => NotesService.deleteNote(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [NOTES_QUERY] });
      }
    }
  });
}
