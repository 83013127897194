import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import { useFormik } from 'formik';

import { PaymentTypesWrapper, Wrapper } from './CreateKassaModal.styled';

import { cn } from '../../../../../../shared/lib';
import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../../../../shared/lib/hooks/useMatchMedia';
import { useOutside } from '../../../../../../shared/lib/hooks/useOutside';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import { Input, ModalSize, Text, Textarea, TextPType, TextType } from '../../../../../../shared/ui';
import DinamycHeight from '../../../../../../shared/ui/AutoHeight/DinamycHeight';
import CommonButton from '../../../../../../shared/ui/Button/ui/CommonButton';
import { MODAL_GAP, MODAL_TEXT_GAP } from '../../../../../../styles';
import { apiDelete, apiPost, apiPut } from '../../../../../../utils/apiInstance';
import { FlexWithAlign, FormStyle } from '../../../../../../utils/styleUtils';
import { EIcon, IconNew as IconInstance } from '../../../../../icons/medium-new-icons/icon';

interface IProps {
  closeModal?: () => void;
  edit?: boolean;
  modalPayload?: any;
}

const CreateKassaModal: React.FC<IProps> = (props) => {
  const { closeModal, edit, modalPayload } = props;
  const { t } = useTranslation();
  const { payments } = useTypedSelector((state) => state.payments);
  const { pathname } = useLocation();
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const { ref, isShow } = useOutside(true);
  const [additionalPaymentMethods, setAdditionalPaymentMethods] = useState<string[]>([]);
  const [isView, setIsView] = useState(false);
  const handleAdditionalPaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
  };
  const handleAdditionalPaymentBlur = () => {
    setIsView(false);
    const newPaymentMethod = formik.values.additionalPaymentMethod?.trim();
    const isDuplicate = additionalPaymentMethods.some((method) => method === newPaymentMethod);

    if (newPaymentMethod && !isDuplicate) {
      setAdditionalPaymentMethods((prevMethods) => [...prevMethods, newPaymentMethod]);
      formik.setFieldValue('additionalPaymentMethod', '');
    } else {
      toast('Такой способ оплаты уже используется');
    }
  };

  useEffect(() => {
    if (!isShow) {
      closeModal?.();
    }
  }, [isShow]);

  const [pending, setPending] = useState(false);
  const { kassa } = useTypedSelector((state) => state.finance);
  const { fetchListKassaAsync, fetchPaymentsAsync, openModal } = useActions();
  const fetchInfo = async () => {
    await fetchListKassaAsync();
  };

  useEffect(() => {
    fetchInfo();
  }, []);
  const initialValues = {
    name: '',
    comment: ''
  };
  const initialValuesPayments = {
    name: '',
    kassa: ''
  };

  const formikPayments = useFormik({
    initialValues: modalPayload ? modalPayload : initialValuesPayments,
    onSubmit: (values: any) => {}
  });

  const formik = useFormik({
    initialValues: modalPayload ? modalPayload : initialValues,
    onSubmit: (values: any) => {}
  });

  useEffect(() => {
    formik.setValues(modalPayload ? modalPayload : initialValues);

    const additionalMethods = payments?.filter((item) => item?.kassa === modalPayload?.id)?.map((item) => item.name) || [];
    setAdditionalPaymentMethods(additionalMethods);
    setIsView(additionalMethods.length === 0 ? true : false);
  }, [modalPayload]);

  const createKassa = async (values: any) => {
    const ExistingKassa = kassa && kassa?.some((item) => item.name === formik.values.name);
    if (ExistingKassa) {
      return toast('Нельзя создать две кассы с одинаковым названием');
    }
    setPending(true);
    const res = await apiPut('/kassa', values);
    if (res?.status === 200) {
      for (const paymentName of (additionalPaymentMethods || []).filter((item) => item !== formikPayments?.values?.name)) {
        const isPaymentExist = payments?.some((item) => item.name === paymentName);

        if (!isPaymentExist) {
          await apiPut('/payments', {
            kassa: res?.data?.id,
            name: paymentName
          });
        }
      }
      toast.success(`Касса успешно создана`);
      setPending(false);
      closeModal?.();
      fetchListKassaAsync();
      fetchPaymentsAsync();
    } else {
      setPending(false);
      toast.error(res.data.description);
    }
  };

  const updateKassa = async (values: any) => {
    setPending(true);
    const res = await apiPost(`/kassa/${modalPayload?.id}`, values);
    if (res?.status === 200) {
      for (const paymentName of (additionalPaymentMethods || []).filter((item) => item !== formikPayments?.values?.name)) {
        const isPaymentExist = payments?.some((item) => item.name === paymentName);

        if (!isPaymentExist) {
          await apiPut('/payments', {
            kassa: modalPayload?.id,
            name: paymentName
          });
        }
      }
      toast.success(`Касса успешно обновлена`);
      setPending(false);
      closeModal?.();
      fetchListKassaAsync();
      fetchPaymentsAsync();
    } else {
      setPending(false);
      toast.error(res.data.description);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (modalPayload?.id) {
      updateKassa(formik.values);
    } else {
      createKassa(formik.values);
    }
  };

  const deleteCassa = (e: any, info: string | undefined) => {
    e.preventDefault();
    e.stopPropagation();
    const filterPayments = payments?.filter((item) => item.kassa === info);
    if (filterPayments?.length !== 0) {
      toast('Для удаления кассы удалите все способы оплаты');
    } else {
      openModal({
        modalName: 'CONFIRM_DELETE',
        modalSize: ModalSize.XS,
        modalPayload: { id: info },
        actionName: 'KASSA',
        classModal: 'danger'
      });
    }
  };

  const deletePayment = async (e: any, info: string | undefined) => {
    e.preventDefault();
    const findPayment = payments?.find((item) => item.name === info)?.id;
    const res = await apiDelete(`/payments/${findPayment}`);
    if (res.status === 200) {
      setAdditionalPaymentMethods((prevMethods) => prevMethods.filter((method) => method !== info));
      fetchPaymentsAsync();
    }
  };

  return (
    <FlexWithAlign
      $column
      $gap={MODAL_GAP}
      ref={ref}
    >
      {isMobile ? null : (
        <Text
          $tag={TextType.H5}
          fontWeight={700}
        >
          {modalPayload ? t('Редактирование кассы') : t('Создание кассы')}{' '}
        </Text>
      )}
      <Wrapper>
        <FormStyle>
          <FlexWithAlign
            $column
            $gap={MODAL_TEXT_GAP}
          >
            <Text
              $tag={TextType.P}
              $pSize={isMobile ? TextPType.P15 : TextPType.P17}
              color={isMobile ? undefined : 'text02'}
              fontWeight={isMobile ? 700 : undefined}
            >
              {t('Наименование кассы')}
            </Text>
            <Input
              label={'Касса'}
              className={cn(formik.values.name !== '' && 'active')}
              value={formik.values.name}
              onChange={formik.handleChange}
              name='name'
            />
          </FlexWithAlign>
          <FlexWithAlign
            $gap={MODAL_TEXT_GAP}
            $column
          >
            <Text
              $tag={TextType.P}
              $pSize={isMobile ? TextPType.P15 : TextPType.P17}
              color={isMobile ? undefined : 'text02'}
              fontWeight={isMobile ? 700 : undefined}
            >
              Подключенные способы оплаты
            </Text>
            {additionalPaymentMethods.map((paymentMethod, index) => (
              <PaymentTypesWrapper key={index}>
                <label>
                  <input
                    value={paymentMethod}
                    readOnly
                  />
                </label>
                <button onClick={(e) => deletePayment(e, paymentMethod)}>
                  <IconInstance name={EIcon.closefill} />
                </button>
              </PaymentTypesWrapper>
            ))}

            <FlexWithAlign
              $column
              $gap={MODAL_TEXT_GAP}
            >
              {' '}
              <DinamycHeight>
                <div>
                  {isView && (
                    <Input
                      label={'Новый способ оплаты'}
                      className='active'
                      value={formik.values.additionalPaymentMethod}
                      onChange={handleAdditionalPaymentChange}
                      onBlur={handleAdditionalPaymentBlur}
                      name='additionalPaymentMethod'
                    />
                  )}
                </div>
              </DinamycHeight>
              <CommonButton
                typeBtn={'flat'}
                fullWidth
                gap={MODAL_TEXT_GAP}
                size={'M'}
                onClick={(e) => {
                  e.preventDefault();
                  setIsView(true);
                }}
              >
                <IconInstance name={EIcon.plus} />
                {t('Добавить способ')}
              </CommonButton>
            </FlexWithAlign>
          </FlexWithAlign>

          <Textarea
            placeholder={'Комментарий к кассе'}
            content={formik.values.comment}
            onChange={formik.handleChange}
            name='comment'
          />

          <FlexWithAlign
            $gap={MODAL_TEXT_GAP}
            $column
            className={'full'}
          >
            <CommonButton
              onClick={handleSubmit}
              typeBtn='primary'
              size={'M'}
              fullWidth
              type='submit'
            >
              {t('Сохранить')}
            </CommonButton>
            {modalPayload &&
              modalPayload?.name !== 'Касса наличные' &&
              modalPayload?.name !== 'Касса безналичные' &&
              modalPayload?.name !== 'Основная касса' &&
              modalPayload?.name !== 'Расчетный счет' && (
                <CommonButton
                  onClick={(e) => deleteCassa(e, modalPayload?.id)}
                  typeBtn={'danger'}
                  size={'M'}
                  fullWidth
                >
                  {t('Удалить кассу')}
                </CommonButton>
              )}
            <CommonButton
              typeBtn='gray'
              size={'M'}
              type='submit'
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                closeModal?.();
              }}
            >
              {t('Отменить')}
            </CommonButton>
          </FlexWithAlign>
        </FormStyle>
      </Wrapper>
    </FlexWithAlign>
  );
};

export default CreateKassaModal;
