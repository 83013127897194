import { FC } from 'react';
import { Link } from 'react-router-dom';

import { LinkProps, LinkSize } from './Link.props';
import { LinkItemWrapper } from './Link.styled';

import { CircleWrapper } from '../../../../styles/circles.styled';
import { FlexWithAlign } from '../../../../utils/styleUtils';
import { cn } from '../../../lib';
import { Text, TextPType, TextType } from '../../HTag';
import useMatchMedia from '../../../lib/hooks/useMatchMedia';

const LinkItem: FC<LinkProps> = ({ className, to, $type, $size, icon, onClick, $vertical, children, title, description }: LinkProps) => {
  const [isMiniLaptop] = useMatchMedia(['((max-width: 1024px))']);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  return (
    <LinkItemWrapper
      onClick={onClick}
      className={cn(className)}
      $size={$size}
      $type={$type}
      $vertical={$vertical}
    >
      {to && <Link to={to} />}
      <FlexWithAlign
        $gap={'0'}
        $column
      >
        <Text
          $tag={TextType.P}
          $pSize={TextPType.P17}
          small
          fontWeight={700}
        >
          {title}
        </Text>
        {description && (
          <Text
            $tag={TextType.P}
            small
            $pSize={TextPType.P15}
            color={'text02'}
          >
            {description}
          </Text>
        )}
      </FlexWithAlign>
      {icon && (
        <CircleWrapper
          $background={'thinBlue'}
          size={$size === LinkSize.S ? '40' : $size === LinkSize.M ? '48' : LinkSize.L && '64'}
          $colorSvg={'blue'}
        >
          {icon}
        </CircleWrapper>
      )}
      {children}
    </LinkItemWrapper>
  );
};

export default LinkItem;
