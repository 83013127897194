import styled from 'styled-components';

import { FONT_HEADING_1, FONT_HEADING_2, FONT_HEADING_3, FONT_HEADING_4, FONT_HEADING_5, FONT_HEADING_6 } from '../../../../styles';

interface IStyledHTagProps {
  fontFamily?: string;
  fontWeight?: 100 | 500 | 600 | 700 | 800 | 900;
  color?: 'primary' | 'accent' | 'text02' | 'base01' | 'black' | 'positive' | 'negative';
  fontSize?: string;
  $tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  $pSize?: 'p19' | 'p17' | 'p15' | 'p13' | 'p11' | 'p9';
  $small?: boolean;
}

export const StyledHTag = styled.h1<IStyledHTagProps>`
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  color: ${(props) => {
    if (props.color !== undefined) {
      switch (props.color) {
        case 'primary':
          return props.color ? props.theme.color.primary : props.theme.text.text01;
        case 'accent':
          return props.color ? props.theme.color.accent : props.theme.text.text01;
        case 'text02':
          return props.color ? props.theme.text.text02 : props.theme.text.text01;
        case 'base01':
          return props.color ? props.theme.color.base01 : props.theme.text.text01;
        case 'black':
          return props.color ? props.theme.color.black : props.theme.text.text01;
        case 'positive':
          return props.color ? props.theme.text.positive : props.theme.text.text01;
        case 'negative':
          return props.color ? props.theme.text.negative : props.theme.text.text01;

        default:
          return props.theme.text.text01;
      }
    } else {
      return props.theme.text.text01;
    }
  }};
  margin: 0;
  font: ${(props) => {
    if (props.$tag !== undefined && props.$tag !== 'p' && props.$tag !== 'span') {
      switch (props.$tag) {
        case 'h1':
          return props.$tag ? FONT_HEADING_1 : FONT_HEADING_4;
        case 'h2':
          return props.$tag ? FONT_HEADING_2 : FONT_HEADING_4;
        case 'h3':
          return props.$tag ? FONT_HEADING_3 : FONT_HEADING_4;
        case 'h4':
          return props.$tag ? FONT_HEADING_4 : FONT_HEADING_4;
        case 'h5':
          return props.$tag ? FONT_HEADING_5 : FONT_HEADING_4;
        case 'h6':
          return props.$tag ? FONT_HEADING_6 : FONT_HEADING_4;
        default:
          return FONT_HEADING_4;
      }
    } else if (props.$tag === 'p' && props.$pSize !== undefined) {
      switch (props.$pSize) {
        case 'p19':
          return props.$pSize ? '19px' : '13px';
        case 'p17':
          return props.$pSize ? '17px' : '13px';
        case 'p15':
          return props.$pSize ? '15px' : '13px';
        case 'p13':
          return props.$pSize ? '13px' : '13px';
        case 'p11':
          return props.$pSize ? '11px' : '13px';
        default:
          return '13px';
      }
    }
  }};

  font-size: ${(props) => {
    if (props.$tag !== undefined && props.$tag !== 'p' && props.$tag !== 'span') {
      switch (props.$tag) {
        case 'h1':
          return props.$tag ? '50px' : '20px';
        case 'h2':
          return props.$tag ? '44px' : '20px';
        case 'h3':
          return props.$tag ? '36px' : '20px';
        case 'h4':
          return props.$tag ? '28px' : '20px';
        case 'h5':
          return props.$tag ? '24px' : '20px';
        case 'h6':
          return props.$tag ? '20px' : '20px';
        default:
          return '20px';
      }
    } else if (props.$tag === 'p' && props.$pSize !== undefined) {
      switch (props.$pSize) {
        case 'p19':
          return props.$pSize ? '19px' : '13px';
        case 'p17':
          return props.$pSize ? '17px' : '13px';
        case 'p15':
          return props.$pSize ? '15px' : '13px';
        case 'p13':
          return props.$pSize ? '13px' : '13px';
        case 'p11':
          return props.$pSize ? '11px' : '13px';
        case 'p9':
          return props.$pSize ? '9px' : '13px';
        default:
          return '13px';
      }
    }
  }};
  line-height: ${(props) => {
    if (props.$tag !== undefined && props.$tag !== 'p' && props.$tag !== 'span') {
      switch (props.$tag) {
        case 'h1':
          return props.$tag ? '1.12' : '1.2';
        case 'h2':
          return props.$tag ? '1.1' : '1.2';
        case 'h3':
          return props.$tag ? '1.11' : '1.2';
        case 'h4':
          return props.$tag ? '1.14' : '1.2';
        case 'h5':
          return props.$tag ? '1.17' : '1.2';
        case 'h6':
          return props.$tag ? '1.2' : '1.2';
        default:
          return '20px';
      }
    } else if (props.$tag === 'p' && props.$pSize !== undefined) {
      if (props.$small) {
        switch ((props.$pSize, props.$small)) {
          case 'p17' && true:
            return props.$pSize ? '1.41' : '1.45';
          case 'p15' && true:
            return props.$pSize ? '1.33' : '1.45';
          case 'p13' && true:
            return props.$pSize ? '1.23' : '1.45';
          default:
            return '1.45';
        }
      }
      switch (props.$pSize) {
        case 'p19':
          return props.$pSize ? '1.47' : '1.45';
        case 'p17':
          return props.$pSize ? '1.65' : '1.45';
        case 'p15':
          return props.$pSize ? '1.6' : '1.45';
        case 'p13':
          return props.$pSize ? '1.54' : '1.45';
        case 'p11':
          return props.$pSize ? '1.45' : '1.45';
        default:
          return '1.45';
      }
    }
  }};
  letter-spacing: ${(props) => (props.$pSize === 'p11' ? '0.4px' : 'initial')};
  font-weight: ${(props) => props.fontWeight || 500};
  @media (max-width: 767px) {
    font-weight: calc(${(props) => props.fontWeight || 500} - 100);
  }
`;
