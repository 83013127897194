import styled from 'styled-components';
import { opacity } from '../../../../styles';

import { PageTitle } from '../../../../utils/styleUtils';

export const Wrapper = styled.div`
  //max-width: 482px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 26px;
  &.full {
    max-width: 1500px;
    padding: 0 30px;
  }
  .dropdown-export-clients {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    animation: ${opacity} 0.3s ease;
    .custom-select__value-container {
      padding: 0.8rem 1rem;
    }
  }
  @media (max-width: 1800px) {
    &.full {
      max-width: 1200px;
    }
    max-width: 1200px;
  }
  @media (max-width: 1500px) {
    &.full {
      max-width: 800px;
    }
    max-width: 800px;
  }
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: -20px;
  .tableWrapper {
    max-height: 580px;
    overflow: hidden;
  }
  .sex-input-wrap {
    gap: 20px;
    label {
      width: 231px;
      height: 50px;
      display: flex;
      border: 1px solid ${(props) => props.theme.color.secondaryLightMedium};
      align-items: center;
      padding: 0 15px;
      border-radius: 10px;
      justify-content: space-between;
    }
    input {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: #ecf1f7;
      border-radius: 50%;
      position: relative;
      width: 22px;
      min-width: 22px;
      height: 22px;
      outline: none;
      &:checked {
        background: ${(props) => props.theme.color.accent};
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: ${(props) => props.theme.color.mainDarkFull};
        }
      }

      transition: background 0.3s ease, border-color 0.3s ease;
    }
  }
  .messangers {
    gap: 40px;
    input {
      opacity: 0;
      width: 48px;
      height: 48px;
      z-index: 1;
      cursor: pointer;
    }
    label {
      position: relative;
      display: flex;
    }
    button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border: 2px solid ${(props) => props.theme.color.secondaryDark};
      border-radius: 8px;
      color: ${(props) => props.theme.color.secondaryDark};
      &.telegram-btn {
      }
      &.whatsapp-btn {
      }
      &.viber-btn {
      }
      svg {
        width: 24px;
        height: 24px;
        pointer-events: none;
      }
      &.active {
        background: ${(props) => props.theme.color.primary};
        &.telegram-btn {
          background: ${(props) => props.theme.color.primary};
          border: 2px solid ${(props) => props.theme.color.primary};
        }
        &.whatsapp-btn {
          background: ${(props) => props.theme.color.success};
          border: 2px solid ${(props) => props.theme.color.success};
        }
        &.viber-btn {
          background: rgba(155, 81, 224, 1);
          border: 2px solid rgba(155, 81, 224, 1);
        }
        svg {
          color: ${(props) => props.theme.color.base01};
        }
      }
    }
  }
`;
export const Title = styled(PageTitle)`
  margin: 0;
  font-size: 24px;
`;

export const TableWrapper = styled.div`
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    border-radius: 8px;
  }
  min-height: 400px;
`;
export const ExportTextarea = styled.textarea`
  height: 150px;
  border: 1px solid #eaebee;
  border-radius: 8px;
  padding: 8px 16px;
  resize: none;
  font-size: 13px;
  background: #f5f6f8;
  &:focus-within {
    border-color: #8f969e;
  }
  &:focus {
    outline: none !important;
    border-color: #8f969e;
    background: #fff;
  }
`;

export const ExportSelectWrap = styled.div`
  position: relative;
  padding-top: 30px;

  .filter-btn {
    position: absolute;
    top: 0;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
export const ExportSelect = styled.div`
  position: relative;

  height: 56px;
  width: 320px;
  min-width: 320px;
  > div {
    height: 56px;
    width: 320px;
  }
  // outline: none !important;
  // border: none !important;
  // border-bottom: 2px solid ${(props) => props.theme.color.primary} !important;
  // option {
  //   cursor: pointer;
  //   width: 300px;
  //   min-height: 60px;
  //   max-height: 300px;
  //   border-radius: 15px;
  //   background-color: rgb(250, 250, 250);
  //   box-shadow: 2px 4px 8px #c5c5c5;
  //   transition: all 300ms;
  // }
`;
export const ExportText = styled.p`
  color: rgba(108, 110, 124, 1);
  font-size: 13px;
`;
export const ExportFileWrapper = styled.div`
  position: relative;
  #inputGroupFile {
    position: absolute;
    width: 100%;
    opacity: 0;
    height: 100%;
    inset: 0;
  }
  label[for='inputGroupFile'] {
    vertical-align: middle;
    cursor: pointer;
    width: 100%;
    background: rgba(249, 249, 249, 1);
    border-radius: 8px;
    height: 150px;
    border: 2px dashed ${(props) => props.theme.color.secondaryDark};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    h5,
    svg {
      text-align: center;
      width: 320px;
      color: rgba(108, 110, 124, 1);
    }
  }
  label[for='inputGroupFile'] span {
    margin-left: 10px;
  }
`;
