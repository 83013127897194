import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserService } from '../../../../shared/api/model/service/user.service';
import { USER_PROFILE_QUERY, USER_SCHEDULE_QUERY, USERS_QUERY, USERS_SCHEDULE_QUERY } from '../../../../shared/const/query.const';
import { IUser, IUserSchedule } from '../user.interface';

export function useCreateUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUser) => UserService.createEmployeer(data),

    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [USERS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [USERS_SCHEDULE_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [USER_SCHEDULE_QUERY] });
      }
    }
  });
}

export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUser) => UserService.updateUser(data),
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [USERS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [USERS_SCHEDULE_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [USER_SCHEDULE_QUERY] });
      }
    }
  });
}

export function useUpdateProfileUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUser) => UserService.updateUser(data),
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [USER_PROFILE_QUERY] });
      }
    }
  });
}

export function useDeleteUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => UserService.deleteEmployeer(id),
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [USERS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [USERS_SCHEDULE_QUERY] });
      }
    }
  });
}

export function useUpdateUserSchedule() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: IUserSchedule[] }) => UserService.updateUserSchedule(id, data),

    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [USERS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [USERS_SCHEDULE_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [USER_SCHEDULE_QUERY] });
      }
    }
  });
}
