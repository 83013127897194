import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import InnerLayout from '../../../../components/layouts/InnerLayout';
import CommonModal from '../../../../components/shared/modal/CommonModal';
import CommonSidebar from '../../../../components/shared/modal/CommonSidebar';
import { routeConfig } from '../config/routeConfig';
import { Loader } from '../../../../shared/ui';

export const AppRouter = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const refreshTokenPathRegex = /^\/password\/update\/[^/]+$/;
    const userRecordingPathRegex = /^\/user-recording\/[^/]+\/[^/]+\/[^/]+\/[^/]+\/[^/]+$/;
    const userRecordingAllPathRegex = /^\/user-recording-all\/[^/]+\/[^/]+$/;

    if (!refreshTokenPathRegex.test(pathname) && !userRecordingPathRegex.test(pathname) && !userRecordingAllPathRegex.test(pathname)) {
      navigate('/auth');
    }
  }, []);

  return (
    <>
      <Routes>
        {Object.values(routeConfig).map(({ element, path, authOnly, title }) => (
          <Route
            key={path}
            path={path}
            element={
              authOnly ? (
                <InnerLayout>
                  <Helmet>
                    <title>{title}</title>
                  </Helmet>
                  <Suspense fallback={<Loader />}>
                    <div className='page-wrapper'>{element}</div>
                  </Suspense>
                  <CommonModal />
                  <CommonSidebar />
                </InnerLayout>
              ) : (
                <>
                  <Helmet>
                    <title>{title}</title>
                  </Helmet>
                  <Suspense fallback={<Loader />}>
                    <div className='page-wrapper'>{element}</div>
                  </Suspense>
                </>
              )
            }
          />
        ))}
      </Routes>
    </>
  );
};
