import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CabinetsService } from '../../../../shared/api/model/service/cabinets.service';
import { CABINETS_QUERY, SUBPRODUCTS_QUERY } from '../../../../shared/const/query.const';
import { ICabinet, ICabinetDelete } from '../cabinets.interface';

export function useCreateCabinet() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values: ICabinet) => CabinetsService.createCabinet(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CABINETS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCTS_QUERY] });
      }
    }
  });
}

export function useUpdateCabinet() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values: ICabinet) => CabinetsService.updateCabinet(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CABINETS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCTS_QUERY] });
      }
    }
  });
}

export function useDeleteCabinet() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values: ICabinetDelete) => CabinetsService.deleteCabinet(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CABINETS_QUERY] });
      }
    }
  });
}
