import dayjs from 'dayjs';

import { IUser, IUserAllScheduleQueryData, IUserSchedule, IUserScheduleQueryData } from '../../../../store/redux/user/user.interface';
import { apiGet, apiPost, apiPut, apiDelete } from '../../interceptors';

export const UserService = {
  async fetchUserById(id: string | undefined) {
    const response = await apiGet(`/user/${id}`);

    return response.data;
  },
  async fetchUsers() {
    const response = await apiGet(`/users`);

    return response.data;
  },

  async fetchEmployees() {
    const response = await apiGet(`/users`);
    const users = response.data.users || [];
    // const employees = users.filter((user: IUser) => user.role !== 'admin');
    const employees = users;

    return { users, employees };
  },

  async updateUser(data: IUser) {
    const response = await apiPost(`/user/${data.id}`, { ...data });

    return response.data;
  },

  async updateUserSchedule(id: string, data: IUserSchedule[]) {
    const formattedData = data.map((item) => ({
      date: dayjs(item.date).format('YYYY-MM-DD'),
      timestart: item.timestart,
      timeend: item.timeend
    }));
    const response = await apiPut(`/scheduser/${id}`, formattedData);

    return response.data;
  },

  async createEmployeer(data: IUser) {
    const response = await apiPut(`/user`, {
      ...data
    });
    return response.data;
  },

  async deleteEmployeer(id: string) {
    const response = await apiDelete(`/user/${id}`);
    return response.data;
  },

  async fetchUserScheduleForPeriod(data: IUserScheduleQueryData) {
    const { id, dateStart, dateEnd } = data;
    const response = await apiPost(`/scheduser/${id}`, {
      date: dayjs(dateStart).format('YYYY-MM-DD'),
      dateend: dayjs(dateEnd).format('YYYY-MM-DD')
    });

    return response.data;
  },

  async fetchUserSchedule(data: IUserAllScheduleQueryData) {
    const { ids, dateStart, dateEnd } = data;
    let resultArr: any[] = [];
    if ((ids && ids.length === 0) || !ids) return resultArr;

    const promises = ids.map((id) =>
      apiPost(`/scheduser/${id}`, {
        date: dayjs(dateStart).format('YYYY-MM-DD'),
        dateend: dayjs(dateEnd).format('YYYY-MM-DD')
      })
    );

    const values = await Promise.all(promises);
    if (values.length > 0) {
      resultArr = values.map((value) =>
        Array.isArray(value.data)
          ? value.data.map((item: any) => ({
              ...item,
              employeeId: value.config.url?.split('/scheduser/')[1]
            }))
          : { ...value.data, employeeId: value.config.url?.split('/scheduser/')[1] }
      );
    }

    return resultArr;
  }
};
