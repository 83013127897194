import React, { PropsWithChildren, Suspense, useRef } from 'react';

import CreateCabinetModal from './create/cabinet/CreateCabinetModal';
import { CreateClientModalAsync } from './create/client/CreateClientModal.async';
import CreateEmployeersModal from './create/employeers/CreateEmployeersModal';
import CreateFilialModal from './create/filial/CreateFilialModal';
import CreateFinancePaymentExpensesModal from './create/finance/finance-payment-expenses/CreateFinancePaymentExpensesModal';
import CreateFinancePaymentModal from './create/finance/finance-payment/CreateFinancePaymentModal';
import CreateKassaTransactionModal from './create/finance/kassa-transaction/CreateKassaTransactionModal';
import CreateKassaModal from './create/finance/kassa/CreateKassaModal';
import CreateFinanceProductModal from './create/finance/product/CreateFinanceProductModal';
import CreateKanbanTaskModal from './create/kanban/CreateKanbanTaskModal';
import { CreateRecordingBlockModalAsync } from './create/recording/block/CreateRecordingBlockModalAsync';
import { CreateRecordingGroupModalAsync } from './create/recording/group/CreateRecordingSingleModalAsync';
import { CreateRecordingSingleModalAsync } from './create/recording/single/CreateRecordingSingleModal.async';
import CreateServiceCategoryModal from './create/service/category/CreateServiceCategoryModal';
import CreateServiceModal from './create/service/CreateServiceModal';
import TicketModal from './create/ticket/TicketModal';
import CreateTimeTable from './create/timetable/CreateTimeTable';
import CommonConfirmDelete from './delete/CommonConfirmDelete';
import { ClientInfoAsync } from './info/client-info/ClientInfo.async';
import EditCabinetModal from './update/EditCabinetModal';
import EmployeeScheduleModal from './update/employee-schedule/EmployeeScheduleModal';
import EditFilialTimeModal from './update/filial/EditFilialTimeModal';
import ProfileManagementModal from './update/profile-management/ProfileManagementModal';
import ChangeTarifModal from './update/tarif/ChangeTarifModal';

import CreateExportModal from '../../../pages/ClientPage/lib/client-export-csv/CreateExportModal';
import { useActions } from '../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../shared/lib/hooks/useMatchMedia';
import { useTypedSelector } from '../../../shared/lib/hooks/useTypedSelector';
import { Drawer, Loader, Modal } from '../../../shared/ui';
import { ModalType } from '../../../shared/ui/Modal/ui/Modal.props';

import 'react-loading-skeleton/dist/skeleton.css';
import ConfirmActionModal from './create/recording/confirm/ConfirmActionModal';

interface IModalProps {
  children?: PropsWithChildren;
}

const CommonModal: React.FC<IModalProps> = (props) => {
  const { children } = props;
  const modalRef = useRef(null);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const { closeModal, setValue, setIsLocalDay } = useActions();
  const { showModal, modalName, modalSize, modalHeader, modalType, modalPayload, classModal, actionName, stateNum, skeleton } =
    useTypedSelector((state) => state.modal);

  if (!showModal) {
    // setValue('');
    isMobile && setIsLocalDay(false);
  }

  const modals = {
    CREATE_EMPLOYEE: <CreateEmployeersModal closeModal={closeModal} />,
    EDIT_FILIAL_TIME: (
      <EditFilialTimeModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CREATE_RECORDING_SINGLE: (
      <CreateRecordingSingleModalAsync
        onSuccess={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CREATE_RECORDING_GROUP: (
      <CreateRecordingGroupModalAsync
        onSuccess={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CREATE_RECORDING_BLOCK: (
      <CreateRecordingBlockModalAsync
        modalPayload={modalPayload}
        onSuccess={closeModal}
      />
    ),
    EDIT_EMPLOYEE: (
      <CreateEmployeersModal
        closeModal={closeModal}
        edit={true}
        modalPayload={modalPayload}
      />
    ),
    CREATE_CLIENT: (
      <CreateClientModalAsync
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CREATE_KASSA: (
      <CreateKassaModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CREATE_FINANCE_PAYMENT: (
      <CreateFinancePaymentModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CREATE_FINANCE_PAYMENT_EXPENSES: (
      <CreateFinancePaymentExpensesModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CREATE_FINANCE_PRODUCT: (
      <CreateFinanceProductModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CREATE_KASSA_TRANSACTION: <CreateKassaTransactionModal closeModal={closeModal} />,
    CREATE_KANBAN_TASK: (
      <CreateKanbanTaskModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    EXPORT_CSV_CLIENT: <CreateExportModal closeModal={closeModal} />,
    EDIT_CLIENT: (
      <CreateClientModalAsync
        closeModal={closeModal}
        edit={true}
        modalPayload={modalPayload}
      />
    ),
    CREATE_FILIAL: <CreateFilialModal closeModal={closeModal} />,
    EDIT_FILIAL: (
      <CreateFilialModal
        closeModal={closeModal}
        edit={true}
        modalPayload={modalPayload}
      />
    ),
    CREATE_CABINET: (
      <CreateCabinetModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    EDIT_CABINET: (
      <EditCabinetModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CONFIRM_ACTION: (
      <ConfirmActionModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CONFIRM_DELETE: (
      <CommonConfirmDelete
        closeModal={closeModal}
        modalPayload={modalPayload}
        actionName={actionName}
      />
    ),
    TICKET: (
      <TicketModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CREATE_SERVICE_CATEGORY: (
      <CreateServiceCategoryModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    EDIT_SERVICE_CATEGORY: (
      <CreateServiceCategoryModal
        closeModal={closeModal}
        modalPayload={modalPayload}
        edit={true}
      />
    ),
    CREATE_SERVICE: (
      <CreateServiceModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    EDIT_SERVICE: (
      <CreateServiceModal
        closeModal={closeModal}
        modalPayload={modalPayload}
        edit={true}
      />
    ),
    CREATE_SERVICE_ALL: (
      <CreateServiceModal
        closeModal={closeModal}
        modalPayload={modalPayload}
        allCategories={true}
      />
    ),
    PROFILE_MANAGEMENT: (
      <ProfileManagementModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    UPDATE_TARIF: <ChangeTarifModal modalPayload={modalPayload} />,
    UPDATE_TARIF_MODULES: (
      <ChangeTarifModal
        modalPayload={modalPayload}
        edit={true}
      />
    ),

    UPDATE_EMPLOYEE_SCHEDULE: (
      <EmployeeScheduleModal
        modalPayload={modalPayload}
        closeModal={closeModal}
        stateNum={stateNum}
      />
    ),
    ADD_TIMETABLE_SCHEDULE: (
      <CreateTimeTable
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    EDIT_TIMETABLE_SCHEDULE: (
      <CreateTimeTable
        modalPayload={modalPayload}
        closeModal={closeModal}
        edit={true}
      />
    ),
    CLIENT_INFO: (
      <ClientInfoAsync
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    '': ''
  };

  return (
    <>
      {isMobile ? (
        <>
          {showModal && (
            <Drawer
              lazy
              full={modalType === ModalType.TOP}
              isOpen={showModal}
              className={classModal ? classModal : 'common'}
              onClose={() => closeModal()}
              header={modalHeader}
            >
              <Suspense fallback={<Loader />}>
                <>
                  {modals[modalName]}
                  {children}
                </>
              </Suspense>
            </Drawer>
          )}
        </>
      ) : (
        <>
          {showModal && (
            <Modal
              isOpen={showModal}
              onClose={() => closeModal()}
              ref={modalRef}
              type={modalType}
              size={modalSize}
              className={classModal ? classModal : 'common'}
            >
              <Suspense fallback={skeleton ? skeleton : <Loader />}>
                <>
                  {modals[modalName]}
                  {children}
                </>
              </Suspense>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default CommonModal;
