import React, { FC, memo, useState } from 'react';

import dayjs from 'dayjs';

import { BubblesGroup, DateSeparator, Message, MessageBubble, NotTextChat, Scrollable } from './Messages.styled';

import { cn } from '../../../../../shared/lib';
import { useChatScroll } from '../../../../../shared/lib/hooks/useScrollTracker';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { getCookie } from '../../../../../utils/cookies';
import { BubblesInner } from '../../../../../utils/styleUtils';
import { formatMessageDate } from '../../../lib/messenger.helper';

const Messages: FC = memo(() => {
  const { messages, status } = useTypedSelector((state) => state.chat);
  const { user } = useTypedSelector((state) => state.user);
  const [lastAnimatedIndex, setLastAnimatedIndex] = useState(-1);
  const ref = useChatScroll(messages);
  const calculateAnimationDelay = (index: number) => {
    return messages && Math.min((messages?.length - index - 1) * 0.05, 0.3);
  };

  const groupAndSortMessagesByDay = () => {
    const sortedMessages = messages && messages.slice().sort((a, b) => dayjs(a.created_at).unix() - dayjs(b.created_at).unix());

    const groupedAndSortedMessages: { [key: string]: JSX.Element[] } = {};

    sortedMessages?.forEach((message, index) => {
      const day = dayjs(message.created_at).format('YYYY-MM-DD');

      if (!groupedAndSortedMessages[day]) {
        groupedAndSortedMessages[day] = [];
      }

      const shouldShowDateSeparator = index === 0 || day !== dayjs(sortedMessages[index - 1].created_at).format('YYYY-MM-DD');

      if (shouldShowDateSeparator) {
        groupedAndSortedMessages[day].push(
          <DateSeparator key={`date-separator-${message.id}`}>
            <div className={'bubble-content'}>
              <div className='service-msg'>{dayjs(day).format('DD MMMM YYYY')}</div>
            </div>
          </DateSeparator>
        );
      }

      groupedAndSortedMessages[day].push(
        <Message
          onAnimationEnd={() => {
            const shouldAnimate = groupedAndSortedMessages[day].length - 1 > lastAnimatedIndex;
            if (shouldAnimate) {
              setLastAnimatedIndex(lastAnimatedIndex + 1);
            }
          }}
          style={{ animationDuration: `${calculateAnimationDelay(groupedAndSortedMessages[day].length - 1)}s` }}
          className={cn(getCookie('id') === message.sender ? 'is-out' : 'is-in')}
          key={`message-${message.id}`}
        >
          <div className={'bubble-content-wrapper'}>
            <div className={'buttle-content'}>
              <div className='message'>
                <div dangerouslySetInnerHTML={{ __html: message.content }} />
                <div className={'time'}>
                  <div className={'time-inner'}>
                    <span className={'i18n'}>{formatMessageDate(message.created_at)}</span>
                  </div>
                </div>
              </div>

              {getCookie('id') !== message.sender && (
                <div className={'colored-name name floating-part'}>
                  <div className={'title-flex'}>
                    <span className={'peer-title'}>{user?.find((item) => item.id === message.sender)?.fio}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Message>
      );
    });

    return groupedAndSortedMessages;
  };

  return (
    <MessageBubble>
      <Scrollable ref={ref}>
        <BubblesInner className={'bubbles-inner has-rights'}>
          {Object.values(groupAndSortMessagesByDay()).map((groupedMessages) => (
            <BubblesGroup key={`bubbles-group-${groupedMessages[0].key}`}>{groupedMessages}</BubblesGroup>
          ))}
          {status !== 'pending' && messages && messages.length === 0 && <NotTextChat>В данном чате нет сообщений!</NotTextChat>}
        </BubblesInner>
      </Scrollable>
    </MessageBubble>
  );
});

Messages.displayName = 'Messages';
export default Messages;
