import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { useFormik } from 'formik';

import { Box, Breadcrumbs, ButtonInner, FormItem, InfoContent, InfoItem, Notice, Wrapper } from './CreateFilialModal.styled';

import { useActions } from '../../../../../shared/lib/hooks/useActions';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import { apiPost, apiPut } from '../../../../../utils/apiInstance';
import { DividerArrow, FormStyle, PageTitle } from '../../../../../utils/styleUtils';
import { Input, InputType } from '../../../../../shared/ui';

interface IProps {
  closeModal?: () => void;
  edit?: boolean;
  modalPayload?: any;
}

const CreateFilialModal: React.FC<IProps> = (props) => {
  // eslint-disable-next-line react/prop-types
  const { closeModal, edit, modalPayload } = props;
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);

  const [formValid, setFormValid] = useState(false);
  const { fetchFilialsAsync } = useActions();

  const setFocus = (ref: any) => {
    if (ref.current) {
      ref.current?.focus();
    }
  };
  const initialValues = {
    name: '',
    address: '',
    phone: '',
    rucovoditel: '',
    yurlico: ''
  };

  const formik = useFormik({
    initialValues: modalPayload ? modalPayload : initialValues,
    onSubmit: (values: any) => {}
  });

  useEffect(() => {
    if (formik.values.name && formik.values.address && formik.values.phone && formik.values.rucovoditel) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formik.values]);

  const createFilial = async (values: any) => {
    setPending(true);
    const res = await apiPut('/filial', values);
    if (res?.status === 200) {
      setPending(false);
      closeModal!();
      fetchFilialsAsync();
    } else {
      setPending(false);
      toast.error(res.data.description);
    }
  };

  const editFilial = async (values: any) => {
    setPending(true);
    const res = await apiPost(`/filial/${values.id}`, values);
    if (res?.status === 200) {
      setPending(false);
      closeModal!();
      fetchFilialsAsync();
    } else {
      setPending(false);
      toast.error(res.data.description);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (edit) {
      editFilial(formik.values);
    } else {
      createFilial(formik.values);
    }
  };

  return (
    <Wrapper>
      <PageTitle>{formik.values.name !== '' ? formik.values.name : t('Создание филиала')}</PageTitle>
      <Breadcrumbs>
        <span>{t('Управление')}</span>
        <DividerArrow></DividerArrow>
        <span>{t('Филиал')}</span>
        <DividerArrow></DividerArrow>
        <span className='active'>{edit ? t('Редактирование филиала') : t('Создание филиала')}</span>
      </Breadcrumbs>
      <FormStyle onSubmit={handleSubmit}>
        <Box className='form'>
          <FormItem>
            <Input
              size={InputType.M}

              label={t('Название филиала')}
              value={formik.values.name}
              onChange={formik.handleChange}
              name='name'
              placeholder={'...'}
            >
              <button
                type='button'
              ></button>
            </Input>
          </FormItem>

          <FormItem>
            <Input
              size={InputType.M}

              label={t('Адрес')}
              name='address'
              value={formik.values.address}
              onChange={formik.handleChange}
              placeholder={'...'}
            >
              <button
                type='button'
              ></button>
            </Input>
          </FormItem>

          <FormItem>
            <Input
              size={InputType.M}

              label={t('Телефон')}
              name='phone'
              value={formik.values.phone}
              onChange={formik.handleChange}
              type={'tel'}
              placeholder={'...'}
            >
              <button
                type='button'
              ></button>
            </Input>
          </FormItem>
          <FormItem>
            <Input
              size={InputType.M}

              label={t('Руководитель')}
              value={formik.values.rucovoditel}
              onChange={formik.handleChange}
              name='rucovoditel'
              placeholder={'...'}
            >
              <button
                type='button'
              ></button>
            </Input>
          </FormItem>
          <FormItem>
            <Input
              size={InputType.M}

              label={t('Юридическое лицо')}
              value={formik.values.yurlico}
              onChange={formik.handleChange}
              name='yurlico'
              placeholder={'...'}
            >
              <button
                type='button'
              ></button>
            </Input>
          </FormItem>
        </Box>
        <Box className='info'>
          <InfoContent>
            {formik.values &&
              Object.keys(formik.values).map((key) => (
                <>
                  {key === 'name' && (
                    <InfoItem className='head'>
                      <span>
                        {formik.values[key] !== '' ? formik.values[key] : <span className='empty'>{t('Название филиала')}...</span>}
                      </span>
                    </InfoItem>
                  )}
                  {key === 'address' && (
                    <InfoItem>
                      <span>{formik.values[key] !== '' ? formik.values[key] : <span className='empty'>...</span>}</span>
                    </InfoItem>
                  )}
                  {key === 'phone' && (
                    <InfoItem>
                      <span>{formik.values[key] !== '' ? formik.values[key] : <span className='empty'>...</span>}</span>
                    </InfoItem>
                  )}
                  {key === 'rucovoditel' && (
                    <InfoItem>
                      <span>{formik.values[key] !== '' ? formik.values[key] : <span className='empty'>...</span>}</span>
                    </InfoItem>
                  )}
                  {key === 'yurlico' && (
                    <InfoItem>
                      <span>{formik.values[key] !== '' ? formik.values[key] : <span className='empty'>...</span>}</span>
                    </InfoItem>
                  )}
                </>
              ))}
          </InfoContent>
          <Notice>{t('Проверьте правильность данных')}</Notice>
          <CommonButton
            typeBtn='success'
            type='submit'
            fullWidth
            disabled={!formValid}
          >
            <ButtonInner>
              <span>{edit ? t('Сохранить') : t('Добавить')}</span>
            </ButtonInner>
          </CommonButton>
        </Box>
      </FormStyle>
    </Wrapper>
  );
};

export default CreateFilialModal;
