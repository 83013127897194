import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { scaleIn } from '../../../shared/modal/create/service/sidebar/CreateServiceSidebar.styled';
import { scaleOut } from '../../../../utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > .button {
    transition: 0.3s ease;
    cursor: pointer;

    &:hover {
      background: ${(props) => props.theme.color.accent};
    }

    &.active {
      background: ${(props) => props.theme.color.accent};
      color: #fff;
    }
  }
`;
export const Menu = styled.div`
  position: absolute;
  top: calc(100% + 6px);
  z-index: 10;
  right: 120px;
  padding: 12px 0;
  border-radius: 8px;
  background: ${(props) => props.theme.color.base01};
  max-width: 313px;
  width: 100%;
  filter: drop-shadow(0px 4px 14px rgba(41, 47, 81, 0.14));
  transform-origin: top right;
  &.open {
    animation: ${scaleIn} 0.3s ease;
    visibility: visible;
  }
  &.close {
    animation: ${scaleOut} 0.3s ease;
    visibility: hidden;
    transition: 0.3s ease;
    a {
      visibility: hidden;
    }
  }
`;
export const Line = styled.span`
  position: relative;
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.color.base03};
  display: block;
  margin: 12px 0;
`;
export const Icon = styled.div`
  display: flex;
  svg {
    filter: grayscale(80%);
    width: 20px;
    height: 20px;
  }
`;
export const Container = styled.div`
  padding: 0 12px;
`;
export const Item = styled.div`
  display: flex;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  h4 {
    font-weight: 600;
  }
  &:hover {
    background: rgba(73, 111, 255, 0.1);
  }
`;
export const ItemLink = styled(Link)`
  display: flex;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  h4 {
    font-weight: 600;
  }
  &:hover {
    background: rgba(73, 111, 255, 0.1);
  }
`;
