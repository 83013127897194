import styled from 'styled-components';
import {
  DURATION,
  FONT_M,
  FONT_S,
  FONT_XS,
  HEIGHT_INPUT,
  HEIGHT_INPUT_M,
  HEIGHT_INPUT_S,
  RADIUS_M,
  SHADOW_INPUT,
  SHADOW_INPUT_HOGER
} from '../../../../styles';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input-field';

export interface IInputStyles {
  size?: 'l' | 'm' | 's';
}

export const InputContainer = styled.div<IInputStyles>`
  width: 100%;
  //min-width: 23rem;
  max-width: 100%;
  display: block;
  position: relative;
  appearance: none;
  transition: height ${DURATION} ease-in-out;
  border-radius: ${RADIUS_M};
  text-align: left;
  height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  min-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  max-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  &.error {
    height: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? `calc(0.25rem + 20px + ${HEIGHT_INPUT})` : `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
          case 'm':
            return props.size ? `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})` : `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
          case 's':
            return props.size ? `calc(0.25rem + 20px + ${HEIGHT_INPUT_S})` : `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
          default:
            return `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
        }
      } else {
        return `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
      }
    }};
    min-height: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? `calc(0.25rem + 20px + ${HEIGHT_INPUT})` : `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
          case 'm':
            return props.size ? `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})` : `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
          case 's':
            return props.size ? `calc(0.25rem + 20px + ${HEIGHT_INPUT_S})` : `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
          default:
            return `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
        }
      } else {
        return `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
      }
    }};
    max-height: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? `calc(0.25rem + 20px + ${HEIGHT_INPUT})` : `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
          case 'm':
            return props.size ? `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})` : `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
          case 's':
            return props.size ? `calc(0.25rem + 20px + ${HEIGHT_INPUT_S})` : `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
          default:
            return `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
        }
      } else {
        return `calc(0.25rem + 20px + ${HEIGHT_INPUT_M})`;
      }
    }};
  }
`;
export const InputContent = styled(InputMask)<IInputStyles>`
  padding: 1.25rem 1rem 0;
  background: transparent;
  inset: 0;
  transition: box-shadow 0.01s;
  margin: 0;
  border-width: 0;
  border-radius: inherit;
  background: none;
  font: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? FONT_M : FONT_S;
        case 'm':
          return props.size ? FONT_S : FONT_S;
        case 's':
          return props.size ? FONT_XS : FONT_S;
        default:
          return FONT_S;
      }
    } else {
      return FONT_S;
    }
  }};
  color: ${(props) => props.theme.text.text01};
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  -webkit-text-fill-color: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: transparent;
  border-inline-start-width: 0rem;
  border-inline-end-width: 0rem;
  text-indent: 0px;
  text-align: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-transform: inherit;
  resize: none;

  &.active {
    border-color: transparent;
  }

  &:focus {
    & ~ div {
      svg {
        color: ${(props) => props.theme.color.mainLight};
      }
    }
    &[type='date'] {
      &::-webkit-datetime-edit-year-field,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-day-field {
        color: ${(props) => props.theme.color.mainDark};
      }
    }
  }
  &:disabled {
    opacity: 0.3;
  }

  &[type='date']::-webkit-calendar-picker-indicator {
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  &[type='number']::-webkit-textfield-decoration-container {
  }
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-datetime-edit-year-field,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field {
    color: transparent;
  }
`;
export const Label = styled.label<IInputStyles>`
  width: 100%;
  max-width: 100%;
  display: block;
  transition-property: box-shadow, background;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  background: ${(props) => props.theme.color.inverse};
  color: ${(props) => props.theme.text.text01};
  box-shadow: ${SHADOW_INPUT};
  position: relative;
  appearance: none;
  border-radius: ${RADIUS_M};
  text-align: left;
  line-height: 1.25rem;
  height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  min-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  max-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  &:hover {
    box-shadow: ${SHADOW_INPUT_HOGER};
  }
  &:after {
    transition-property: color;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    color: ${(props) => props.theme.color.base03};
    position: absolute;
    inset: 0;
    content: '';
    border-radius: inherit;
    border: 1px solid currentColor;
    pointer-events: none;
  }
  &.error {
    &:after {
      color: ${(props) => props.theme.text.negative};
    }
  }

  &:focus-within {
    span {
      top: 15px;
      font: ${(props) => {
        if (props.size !== undefined) {
          switch (props.size) {
            case 'l':
              return props.size ? FONT_S : FONT_XS;
            case 'm':
              return props.size ? FONT_XS : FONT_XS;
            case 's':
              return props.size ? FONT_XS : FONT_XS;
            default:
              return FONT_XS;
          }
        } else {
          return FONT_XS;
        }
      }};
      position: absolute;
      line-height: 1.25rem;
      left: 1rem;
      z-index: 2;
    }
    &:after {
      border-width: 2px;
      color: ${(props) => props.theme.color.primary};
    }
  }
  span {
    position: absolute;
    top: 50%;
    color: ${(props) => props.theme.text.text02};
    transform: translateY(-50%);
    left: 1rem;
    pointer-events: none;
    font: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? FONT_M : FONT_S;
          case 'm':
            return props.size ? FONT_S : FONT_S;
          case 's':
            return props.size ? FONT_XS : FONT_S;
          default:
            return FONT_S;
        }
      } else {
        return FONT_S;
      }
    }};
    line-height: inherit;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    transition-property: transform, top, font;
  }
  &.active,
  &.error {
    span {
      color: #6c6f71;
      top: 15px;
      border-radius: 4px;
      font: ${(props) => {
        if (props.size !== undefined) {
          switch (props.size) {
            case 'l':
              return props.size ? FONT_S : FONT_XS;
            case 'm':
              return props.size ? FONT_XS : FONT_XS;
            case 's':
              return props.size ? FONT_XS : FONT_XS;
            default:
              return FONT_XS;
          }
        } else {
          return FONT_XS;
        }
      }};
      font-weight: 400;
      background: transparent;
      z-index: 2;
    }
  }
`;
export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  width: fit-content;
  margin-left: auto;
  justify-content: flex-end;
  padding-right: 12px;
  border: none !important;
  outline: none !important;
  z-index: 3;
  align-items: center;
  & .age {
    padding: 4px;
    background-color: ${(props) => props.theme.color.inverse};
    position: absolute;
    top: -32px;
    right: -10px;
    color: ${(props) => props.theme.color.mainLight};
    white-space: nowrap;
    &.record {
      top: 0;
      right: -24px;
    }
  }

  svg {
    color: ${(props) => props.theme.color.secondaryDark};
    display: block;
  }
  &.active {
    svg {
      color: ${(props) => props.theme.color.mainLight};
    }
  }
  &.active.error,
  &.error {
    svg {
      color: ${(props) => props.theme.text.negative};
    }
    .age {
      opacity: 0;
    }
  }
`;

export const ErrorText = styled.p<IInputStyles>`
  position: relative;
  margin-top: 0.25rem;
  width: 90%;
  white-space: pre-wrap;
  display: block;
  color: ${(props) => props.theme.text.negative};
  word-wrap: break-word;
  animation: present 1s infinite;
  left: 5px;
  font: ${FONT_S};

  @keyframes present {
    100% {
      content: '1';
    }
  }
`;

export const InputContentCurrency = styled(CurrencyInput)<IInputStyles>`
  padding: 1.25rem 1rem 0;
  background: transparent;
  inset: 0;
  transition: box-shadow 0.01s;
  margin: 0;
  border-width: 0;
  border-radius: inherit;
  background: none;
  font: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? FONT_M : FONT_S;
        case 'm':
          return props.size ? FONT_S : FONT_S;
        case 's':
          return props.size ? FONT_XS : FONT_S;
        default:
          return FONT_S;
      }
    } else {
      return FONT_S;
    }
  }};
  color: ${(props) => props.theme.text.text01};
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  -webkit-text-fill-color: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: transparent;
  border-inline-start-width: 0rem;
  border-inline-end-width: 0rem;
  text-indent: 0px;
  text-align: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-transform: inherit;
  resize: none;

  &.active {
    border-color: transparent;
  }

  &:disabled {
    opacity: 0.3;
  }

  &[type='number']::-webkit-textfield-decoration-container {
  }
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-datetime-edit-year-field,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field {
    color: transparent;
  }
`;
