import { useQuery } from '@tanstack/react-query';
import { NotesService } from '../../../../shared/api/model/service/notes.service';
import { NOTES_QUERY } from '../../../../shared/const/query.const';
import { INote } from '../notes.interface';

export function useNotesQuery() {
  return useQuery<INote[], Error>({
    queryKey: [NOTES_QUERY],
    queryFn: () => NotesService.fetchNotes(),
    select: (data: INote[]) => data
  });
}
