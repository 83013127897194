export const USERS_QUERY = 'user';
export const USERS_SCHEDULE_QUERY = 'user_all_schedule_query';
export const USER_SCHEDULE_QUERY = 'user_schedule_query';
export const USER_PROFILE_QUERY = 'user_profile';

export const FILIAL_QUERY = 'filial_query';

export const TARIF_QUERY = 'tarif';
export const MYTARIF_QUERY = 'my_tarif';
export const PAYMENT_HISTORY_QUERY = 'payment_history';
export const MYBALANCE_QUERY = 'mybalance_query';

export const SUBPRODUCTS_QUERY = 'subproducts';
export const SUBPRODUCT_QUERY = 'subproduct';

export const PRODUCTS_QUERY = 'products';

export const CLIENTS_QUERY = 'clients';
export const CLIENT_QUERY = 'client';

export const CABINETS_QUERY = 'cabinets';

export const NOTES_QUERY = 'notes';

export const CLASSIFICATORS_QUERY = 'classificators';
