import React from 'react';

export function SidebarOnlineRecording() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M22.0195 8.00003V13C22.0195 14.5913 21.3874 16.1175 20.2622 17.2427C19.137 18.3679 17.6108 19 16.0195 19H14.9095C14.5151 18.9982 14.1289 19.113 13.7995 19.33L10.3195 21.65C9.97112 21.8855 9.56004 22.0109 9.13953 22.01C8.57635 22.0074 8.03715 21.7818 7.63985 21.3826C7.24256 20.9835 7.01953 20.4432 7.01953 19.88V18.88C5.62754 18.6448 4.36308 17.9262 3.44855 16.8507C2.53403 15.7752 2.02802 14.4117 2.01953 13V8.00003C2.01953 6.40873 2.65167 4.88261 3.77689 3.75739C4.90211 2.63217 6.42823 2.00003 8.01953 2.00003H16.0195C16.6999 1.99791 17.3759 2.10944 18.0195 2.33003C19.1915 2.74436 20.2058 3.51245 20.9223 4.52815C21.6389 5.54385 22.0223 6.75702 22.0195 8.00003Z'
        fill='#467FF3'
      />
      <path
        d='M16.0195 9.48999H8.01953C7.75431 9.48999 7.49996 9.38463 7.31242 9.1971C7.12489 9.00956 7.01953 8.75521 7.01953 8.48999C7.01953 8.22477 7.12489 7.97042 7.31242 7.78288C7.49996 7.59535 7.75431 7.48999 8.01953 7.48999H16.0195C16.2847 7.48999 16.5391 7.59535 16.7266 7.78288C16.9142 7.97042 17.0195 8.22477 17.0195 8.48999C17.0195 8.75521 16.9142 9.00956 16.7266 9.1971C16.5391 9.38463 16.2847 9.48999 16.0195 9.48999Z'
        fill='#467FF3'
      />
      <path
        d='M13.0195 13.49H8.01953C7.75431 13.49 7.49996 13.3846 7.31242 13.1971C7.12489 13.0096 7.01953 12.7552 7.01953 12.49C7.01953 12.2248 7.12489 11.9704 7.31242 11.7829C7.49996 11.5953 7.75431 11.49 8.01953 11.49H13.0195C13.2847 11.49 13.5391 11.5953 13.7266 11.7829C13.9142 11.9704 14.0195 12.2248 14.0195 12.49C14.0195 12.7552 13.9142 13.0096 13.7266 13.1971C13.5391 13.3846 13.2847 13.49 13.0195 13.49Z'
        fill='#467FF3'
      />
    </svg>
  );
}
