import styled from 'styled-components';
import { FlexWithAlign } from '../../../utils/styleUtils';
import { DURATION, RADIUS_S, SHADOW, translateXIn, translateXOut, Z_INDEX } from '../../../styles';
import CommonButton from '../../../shared/ui/Button/ui/CommonButton';
import Slider from 'react-slick';

export const ModalGrid = styled.div`
  display: grid;
  gap: 26px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  &.gap26 {
    gap: 26px;
  }
  &.gap60 {
    grid-template-columns: auto 1fr;
    align-items: center;
    > div {
      p {
        display: flex;
      }
    }
  }
  @media (max-width: 1024px) {
    gap: 10px;
  }
  &.classificator {
    grid-template-columns: 1fr 1fr 0.1fr;
    align-items: center;
    button {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const MainColumn = styled.div`
  width: calc(100% - 80px);
  margin: 0 auto;
  @media (max-width: 767px) {
    width: calc(100% - 1rem);
    margin-left: auto;
    margin-right: 0;
    height: 100%;
  }
  .sticky-block {
    width: 100%;
  }
`;

export const MainColumnBottom = styled(FlexWithAlign)`
  margin-bottom: 12px;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const SidebarWrapper = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  max-width: 23rem;
  background: ${(props) => props.theme.color.base01};
  box-shadow: ${SHADOW};
  border-radius: 1rem 0 0 0;
  transition-property: opacity, width, padding;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  opacity: 0;
  z-index: ${Z_INDEX};
  width: 380px;
  display: none;
  padding: 2rem;
  pointer-events: auto;
  *,
  *:before,
  *:after {
    transition-property: visibility;
    transition-duration: ${DURATION};

    transition-timing-function: ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  animation-duration: ${DURATION};
  animation-timing-function: ease-in-out;
  animation-name: ${translateXOut};
  &.open {
    opacity: 1;
    display: block;
    animation-duration: ${DURATION};
    transition-duration: ${DURATION};
    transition-property: visibility;
    transition-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-name: ${translateXIn};
    visibility: visible;
    *,
    *:before,
    *:after {
      transition-property: visibility;
      transition-duration: ${DURATION};

      transition-timing-function: ease-in-out;
      visibility: visible;
      opacity: 1;
    }
  }
`;
export const RecordingFixedContainer = styled(FlexWithAlign)`
  background: ${(props) => props.theme.color.base01};
  z-index: 2;
  gap: 0;
  margin-bottom: 9px;
  @media (max-width: 767px) {
    padding-top: 5px;
    margin-bottom: 10px;
    &.sticky{
      left: 16px !important;
    }
    
  }
`;
export const TableRecordingHeader = styled(FlexWithAlign)`
  position: sticky;
  top: 0;
  height: 60px;
  border-top: 1px solid ${(props) => props.theme.color.base03};
  z-index: 2;
  background: ${(props) => props.theme.color.base01};
  @media (max-width: 767px) {
    border-top: none;
    margin-bottom: 20px;
    height: auto;
    transition: 0.3s ease-in-out;
  }
`;
export const TableRecordingDate = styled(FlexWithAlign)`
  width: 50px;
  min-width: 50px;
  &.hide {
    visibility: hidden;
  }
`;
export const WeekItem = styled(FlexWithAlign)`
  width: 100%;
  padding-bottom: 4px;
  background: ${(props) => props.theme.color.base01};
  cursor: pointer;
  border-radius: ${RADIUS_S};
  transition: background ${DURATION} ease-in-out;
  &:hover {
    background: ${(props) => props.theme.color.base02};
  }
`;

export const TodayBtn = styled(CommonButton)`
  position: fixed;
  bottom: 100px;
  right: 1rem;
  z-index: 9;
  &.center {
    transform: translateX(50%);
    right: 50%;
  }
  &.left {
    right: auto;
    left: 1rem;
  }
`;

export const DaySlider = styled(Slider)`
  padding: 0 !important;
  .slick-list,
  .slick-slider,
  .slick-track {
    padding: 0 !important;
  }
  max-width: calc(100% - 1rem);
  margin-bottom: 1rem;
  left: 0px;
  height: 50px;
  border-radius: 6px;
  position: relative;
  background: ${(props) => props.theme.color.base02};
  &.month {
    left: 0;
    margin: 0 auto 1rem;
    max-width: calc(100% - 2rem);
  }
`;
