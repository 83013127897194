import styled from 'styled-components';

import { FlexWithAlign } from '../utils/styleUtils';

interface IProps {
  color?: 21 | 2 | 22 | 23 | number | undefined;
  type?: 'employee' | 'clients' | 'finance' | 'recording' | 'client-info' | 'cash-register';
}

export const SystemIconWrapper = styled(FlexWithAlign)<Pick<IProps, 'color'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-inline-start: 20px;
  @media (max-width: 767px) {
    padding-inline-start: 12px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  &.online {
    &:before {
      background: ${(props) => props.theme.text.positive};
    }
  }
  &.offline {
    &:before {
      background: ${(props) => props.theme.text.negative};
    }
  }
  &.clients {
    &:before {
      background: ${(props) => {
        if (props.color) {
          switch (props.color) {
            case 2:
              return props.theme.text.positive;
            case 23:
              return props.theme.text.negative;
            case 22:
              return props.theme.color.primary;
            case 21:
              return props.theme.text.text01;
            default:
              return props.theme.text.text01;
          }
        } else {
          return props.theme.text.text01;
        }
      }};
    }
  }
`;

export const TableBody = styled.div`
  margin-top: 5px;
  overflow: hidden;
`;

export const TableHeader = styled.div<Pick<IProps, 'type'>>`
  display: grid;
  padding: 0 12px;
  grid-template-columns: ${(props) => {
    if (props.type) {
      switch (props.type) {
        case 'employee':
          return '4fr 1fr 2fr 2fr 0.5fr';
        case 'cash-register':
          return '3fr 1fr 3fr 3fr 0.5fr';
        case 'clients':
          return '3fr 1fr 3fr 3fr 0.5fr';
        case 'finance':
          return '1fr 1.5fr 2fr 1.5fr 2fr 1fr';
        case 'recording':
          return '3fr 1fr 3fr 1fr 2.5fr';
        case 'client-info':
          return '1fr 1fr 1fr 1fr 1fr';
        default:
          return '1fr 1fr';
      }
    } else {
      return '1fr 1fr';
    }
  }};
  width: 100%;
  &.cabinets {
    grid-template-columns: 6fr 1.5fr 1.5fr 1fr;
    gap: 20px;
    @media (max-width: 767px) {
      grid-template-columns: 3.5fr 2.9fr 2.4fr 0.5fr;
      gap: 10px;
    }
    & > div:not(:first-child) {
      padding: 0.5rem 0;
    }
  }
  gap: 24px;
  &.products {
    grid-template-columns: 7fr 1.5fr 4fr 0.5fr;
    gap: 20px;
  }
  @media (max-width: 1600px) {
    gap: 12px;
  }
  @media (max-width: 1440px) {
    grid-template-columns: ${(props) => {
      if (props.type) {
        switch (props.type) {
          case 'employee':
            return '2fr 1fr 2fr 1fr 0.5fr';
          case 'cash-register':
            return '3fr 1fr 3fr 3fr 0.5fr';
          case 'clients':
            return '3fr 1fr 3fr 3fr 0.5fr';
          case 'finance':
            return '1fr 1.5fr 2fr 1.5fr 2fr 1fr';
          case 'recording':
            return '3fr 2fr 3fr 1.25fr 2.5fr';
          case 'client-info':
            return '1fr 1fr 1fr 1fr 1fr';
          default:
            return '1fr 1fr 1fr';
        }
      } else {
        return '1fr 1fr';
      }
    }};
  }
  @media (max-width: 767px) {
    gap: 10px;
    padding: 0;
    grid-template-columns: ${(props) => {
      if (props.type) {
        switch (props.type) {
          case 'employee':
            return '2fr 1fr 2fr 2.2fr 0.5fr';
          case 'cash-register':
            return '4fr  4fr 0.5fr';
          case 'clients':
            return '4fr 2fr 3fr 0.5fr';
          case 'finance':
            return '5fr 8fr 4fr 2fr';
          case 'recording':
            return '3fr 2fr 3fr 1.25fr 2.5fr';
          case 'client-info':
            return '1fr 1fr 1fr 1fr';
          default:
            return '1fr 1fr 1fr';
        }
      } else {
        return '1fr 1fr';
      }
    }};
  }
`;

export const HeadItem = styled.div`
  text-align: left;
  //padding: 0.5rem 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  white-space: nowrap;
  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  height: 2.5rem;
  @media (max-width: 767px) {
    width: 100%;
    &.end-position {
      &:last-child {
        justify-content: flex-end;
        p {
          width: fit-content;
        }
      }
    }
    p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.mobile {
      &:first-child {
        max-width: 96px;
        width: 96px;
      }
    }
  }
`;

export const TableRow = styled.div<Pick<IProps, 'type'>>`
  padding: 11px 12px 12px;
  display: grid;
  gap: 24px;
  grid-template-columns: ${(props) => {
    if (props.type) {
      switch (props.type) {
        case 'employee':
          return '4fr 1fr 2fr 2fr 0.5fr';
        case 'cash-register':
          return '3fr 1fr 3fr 3fr 0.5fr';
        case 'clients':
          return '3fr 1fr 3fr 3fr 0.5fr';
        case 'finance':
          return '1fr 1.5fr 2fr 1.5fr 2fr 1fr';
        case 'recording':
          return '3fr 1fr 3fr 1fr 2.5fr';
        case 'client-info':
          return '1fr 1fr 1fr 1fr 1fr';
        default:
          return '1fr 1fr';
      }
    } else {
      return '1fr 1fr';
    }
  }};
  cursor: pointer;
  border-top: 1px solid ${(props) => props.theme.color.base04};
  transition: 0.3s ease;
  &:last-child {
    padding: 12px;
    border-bottom: 1px solid ${(props) => props.theme.color.base04};
    @media (max-width: 767px) {
      padding: 12px 0;
    }
  }
  &.cabinets {
    grid-template-columns: 6fr 1.5fr 1.5fr 1fr;
    gap: 20px;
    @media (max-width: 767px) {
      grid-template-columns: 3.5fr 2.9fr 2.4fr 0.5fr;
      gap: 10px;
    }
    & > div:not(:first-child) {
      padding: 0.5rem 0;
    }
  }
  &.products {
    grid-template-columns: 7fr 1.5fr 4fr 0.5fr;
    gap: 20px;
  }
  &:hover {
    background: ${(props) => props.theme.color.base02};
  }
  @media (max-width: 1600px) {
    gap: 12px;
  }
  @media (max-width: 1440px) {
    grid-template-columns: ${(props) => {
      if (props.type) {
        switch (props.type) {
          case 'employee':
            return '2fr 1fr 2fr 1fr 0.5fr';
          case 'cash-register':
            return '3fr 1fr 3fr 3fr 0.5fr';
          case 'clients':
            return '3fr 1fr 3fr 3fr 0.5fr';
          case 'finance':
            return '1fr 1.5fr 2fr 1.5fr 2fr 1fr';
          case 'recording':
            return '3fr 2fr 3fr 1.25fr 2.5fr';
          case 'client-info':
            return '1fr 1fr 1fr 1fr 1fr';
          default:
            return '1fr 1fr';
        }
      } else {
        return '1fr 1fr';
      }
    }};
  }
  @media (max-width: 767px) {
    gap: 10px;
    padding: 10px 0;
    &:hover {
      background: transparent;
    }
    grid-template-columns: ${(props) => {
      if (props.type) {
        switch (props.type) {
          case 'employee':
            return '2fr 1fr 2fr 2.2fr 0.5fr';
          case 'cash-register':
            return '4fr  4fr 0.5fr';
          case 'clients':
            return '4fr 2fr 3fr 0.5fr';

          case 'finance':
            return '1fr';
          case 'recording':
            return '3fr 2fr 3fr 1.25fr 2.5fr';
          case 'client-info':
            return '1fr 1fr 1fr 1fr';
          default:
            return '1fr 1fr 1fr';
        }
      } else {
        return '1fr 1fr';
      }
    }};
  }
`;

export const TableCol = styled.div<Pick<IProps, 'type'>>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-weight: 400;
  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.employee {
    @media (max-width: 767px) {
      > div {
        &:last-child {
          p {
            width: 100%;
            overflow: hidden;

            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  &:first-child {
    gap: 12px;
    @media (max-width: 767px) {
      > div {
        &:last-child {
          max-width: ${(props) => {
            if (props.type) {
              switch (props.type) {
                case 'employee':
                  return '64px';
                case 'client-info':
                  return '40px';
                case 'clients':
                  return '96px';
                default:
                  return 'auto';
              }
            } else {
              return 'auto';
            }
          }};
          width: ${(props) => {
            if (props.type) {
              switch (props.type) {
                case 'employee':
                  return '64px';
                case 'client-info':
                  return '40px';

                case 'clients':
                  return '96px';
                default:
                  return 'auto';
              }
            } else {
              return 'auto';
            }
          }};

          p {
            &:first-child {
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  &:last-child {
    justify-content: ${(props) => (props.type !== 'finance' ? 'flex-end' : 'flex-start')};
  }
  &.paid {
    justify-content: flex-start;
  }
  &.overflow {
    position: relative;
    max-width: 54px;
    @media (max-width: 767px) {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 40px;
      }
    }
  }
`;

export const Controls = styled(FlexWithAlign)`
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  svg {
    width: 16px;
    height: 16px;
  }
`;
export const ButtonEdit = styled.button`
  color: ${(props) => props.theme.text.text02};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: ${(props) => props.theme.color.primary};
  }
`;

export const ButtonDelete = styled.button`
  color: ${(props) => props.theme.text.text02};
  display: flex;
  align-items: center;
  justify-content: center;
  &:not([disabled]):hover {
    color: ${(props) => props.theme.text.negative};
  }
  &:disabled {
    cursor: no-drop;
    opacity: 0.5;
  }
`;
