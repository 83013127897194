import { useQuery } from '@tanstack/react-query';
import { ClassificatorsService } from '../../../../shared/api/model/service/classificators.service';
import { CLASSIFICATORS_QUERY } from '../../../../shared/const/query.const';
import { IClassificator } from '../classificators.interface';

export const useClassificatorsQuery = () => {
  return useQuery({
    queryKey: [CLASSIFICATORS_QUERY],
    queryFn: () => ClassificatorsService.fetchClassificators(),
    select: (data: IClassificator[]) => data
  });
};
