import styled from 'styled-components';
import { FlexWithAlign, scaleIn, scaleOut } from '../../../../../utils/styleUtils';
import { DURATION, RADIUS_M } from '../../../../../styles';
export const MessengerHeaderWrapper = styled(FlexWithAlign)`
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  height: 56px;
  min-height: 56px;
  z-index: 4;
  background: ${(props) => props.theme.color.base01};
  border-bottom: 1px solid ${(props) => props.theme.color.secondaryLightMedium};
  @media (max-width: 767px) {
    height: 50px;
    min-height: 50px;
    padding: 0 1rem;
    position: fixed;
    top: 0;
    width: 100%;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  height: 100%;
  flex: 0 0 auto;
  align-items: center;
  gap: 20px;
  @media (max-width: 767px) {
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: ${(props) => props.theme.text.text01};
        }
      }
    }
  }
`;

export const SettingsButton = styled.button`
  position: relative;
  padding: 6px;
  transition: 0.3s ease;
  &.open {
    svg {
      color: ${(props) => props.theme.color.primary};
    }
  }
  &.close {
  }
`;

export const SettingsOption = styled(FlexWithAlign)`
  position: absolute;
  top: 120%;
  gap: 0;
  right: 30px;
  padding: 4px;
  transform-origin: top right;
  border-radius: 8px;
  background: ${(props) => props.theme.color.base01};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &.open {
    animation: ${scaleIn} 0.3s ease;
    visibility: visible;
  }
  &.close {
    animation: ${scaleOut} 0.3s ease;
    visibility: hidden;
    transition: 0.3s ease;
  }
`;

export const OptionItem = styled.button`
  font-size: 13px;
  line-height: 24px;
  padding: 0.75rem 1rem;
  border-radius: ${RADIUS_M};
  transition: background ${DURATION} ease-in-out;
  &:hover {
    background: ${(props) => props.theme.color.secondary};
  }
`;
