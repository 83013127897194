import { IClient } from '../../../../store/redux/clients/clients.interface';
import { apiDelete, apiGet, apiPost, apiPut } from '../../interceptors';

export const ClientsService = {
  async fetchClients() {
    const response = await apiGet('/clients');

    return response.data && response.data.clients ? response.data.clients : [];
  },
  async fetchClientById(id: string) {
    const response = await apiGet(`/client/${id}`);

    return response.data;
  },
  async createClient(values: Omit<IClient, 'id'>) {
    const response = await apiPut('/client', values);

    return response.data;
  },
  async updateClient(values: IClient) {
    const { id, ...newValues } = values;
    const response = await apiPost(`/client/${id}`, newValues);

    return response.data;
  },
  async deleteClient(id: string) {
    const response = await apiDelete(`/client/${id}`);

    return response.data;
  }
};
