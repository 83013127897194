import styled from 'styled-components';
import { FlexWithAlign } from '../../../../utils/styleUtils';
import { DURATION, MODAL_GAP, MODAL_TEXT_GAP, RADIUS_M, RADIUS_S, SHADOW_INPUT } from '../../../../styles';

export const DialogContainer = styled.div`
  background: #fff;
  z-index: 10;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  
`;
export const DialogHeader = styled(FlexWithAlign)`
  @media (max-width: 767px) {
    display: none;
  }
`
export const DialogWrapper = styled(FlexWithAlign)`
  //max-width: 482px;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  gap: ${MODAL_GAP};
`;
export const UserCardWrapper = styled(FlexWithAlign)`
  max-height: 20rem;
  overflow-y: auto;
`;
export const UserCard = styled.div<{ isSelected: boolean }>`
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  padding:  12px 0;
  margin-inline: 12px;
  border-bottom: 1px solid ${(props) => props.theme.color.base03};
  cursor: pointer;
  transition: .3s ease;
  &:hover {
    background: ${(props) => props.theme.color.base02};
  }
  span {
    width: 24px;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 24px;
    background: transparent;
    border-radius: ${RADIUS_S};
    transition: background ${DURATION} ease-in-out;
    &:after {
      transition-property: border-color, color;
      transition-duration: ${DURATION};
      transition-timing-function: ease-in-out;
      position: absolute;
      pointer-events: none;
      inset: 0;
      content: '';
      border-radius: inherit;
      border: 1px solid currentColor;
      color: ${(props) => props.theme.color.base04};
    }
    svg {
      color: #fff;
      transition: scale ${DURATION} ease-in-out;
      transform: scale(0);
    }
    &.active {
      background: ${(props) => props.theme.text.link};
      &:after {
        border-color: ${(props) => props.theme.color.primary};
      }
      svg {
        transform: scale(1);
      }
    }
  }
  }
  //&:last-child {
  //  margin-bottom: 0;
  //}
`;

export const ConfirmDialog = styled.div`
  border-radius: 8px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  > ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${MODAL_TEXT_GAP};
    > div {
      padding: 6px 16px;
      width: 100%;
      box-shadow: ${SHADOW_INPUT};
      border-radius: ${RADIUS_M};
    }
  }
`;
