import { useQueries, useQuery } from '@tanstack/react-query';

import { SubproductsService } from '../../../../shared/api/model/service/subproducts.service';
import { SUBPRODUCTS_QUERY, SUBPRODUCT_QUERY } from '../../../../shared/const/query.const';
import { ISubproduct } from '../subproducts.interface';

export function useSubproductQuery(id: string) {
  return useQuery<ISubproduct[], Error>({
    queryKey: [SUBPRODUCTS_QUERY, id],
    queryFn: () => SubproductsService.fetchSubproducts(id),
    select: (data: ISubproduct[]) => data || [],
    enabled: !!id
  });
}

export function useSubproductAllQuery(ids: (string | undefined)[] | undefined) {
  return useQueries({
    queries: (ids ?? []).map((id) => {
      return {
        queryKey: [SUBPRODUCT_QUERY, id],
        queryFn: () => SubproductsService.fetchSubproducts(id!),
        select: (data: ISubproduct[]) => data
      };
    }),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).flat() || []
      };
    }
  });
}
