import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { useFormik } from 'formik';

import { Box, ButtonInner, CustomCheckboxWrap, InputUpload, UploadBlock, Wrapper } from './CreateEmployeersModal.styled';
import { cn } from '../../../../../shared/lib';
import { capitalizeEveryWord } from '../../../../../shared/lib/helpers/regex.helper';
import { IUserServices } from '../../../../../shared/types/user/user.interface';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import { EIcon, IconNew as IconInstance } from '../../../../icons/medium-new-icons/icon';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { useProductsIdsQuery } from '../../../../../store/redux/products/hooks/useProductsQuery';
import { useSubproductAllQuery } from '../../../../../store/redux/subproducts/hooks/useSubproductQuery';
import { ISubproduct } from '../../../../../store/redux/subproducts/subproducts.interface';
import { useCreateUser, useUpdateUser, useUpdateUserSchedule } from '../../../../../store/redux/user/hooks/useUsersMutation';
import { useProfileUserQuery, useUsersQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';
import { getCookie } from '../../../../../utils/cookies';
import { transformFetchFilialScheduleData } from '../../../../../utils/helperFunctions';
import { FlexWithAlign, FormStyle } from '../../../../../utils/styleUtils';
import { validationEmployeeSchema } from '../../../../../utils/validation-input';
import EmployeeImg from '../../../../../assets/images/employee.png';

import Dropdown from '../../../../../shared/ui/Dropdown/ui/Dropdown';
import { resizeAndConvertToBase64 } from '../../../../../shared/lib/helpers/convertToBase46';
import {
  Avatar,
  AvatarRounded,
  AvatarSize,
  Input,
  InputType,
  MultiSelect,
  PositionType,
  Text,
  TextPType,
  TextType
} from '../../../../../shared/ui';
import { MODAL_GAP, MODAL_GAP_L, MODAL_TEXT_GAP } from '../../../../../styles';
import DinamycHeight from '../../../../../shared/ui/AutoHeight/DinamycHeight';
import { DirectionType } from '../../../../../shared/ui/MultiSelect/ui/MultiSelect.props';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';
import Tooltip from '../../../../../shared/ui/Tooltip/ui/Tooltip';

interface IProps {
  closeModal?: () => void;
  edit?: boolean;
  modalPayload?: any;
}

const roleArray = [
  { label: 'Администратор', value: 'administrator' },
  { label: 'Менеджер', value: 'manager' }
];

const CreateEmployeersModal: FC<IProps> = (props) => {
  const { closeModal, edit, modalPayload } = props;
  const { t } = useTranslation();
  const { data: profileUser } = useProfileUserQuery(getCookie('id'));
  const { data: user } = useUsersQuery();
  const { data: ids } = useProductsIdsQuery();
  const { data: subproductsAll } = useSubproductAllQuery(ids);
  const { data: activeFilial } = useFilialQuery();
  const { mutateAsync: createUser, isPending } = useCreateUser();
  const { mutateAsync: updateUser } = useUpdateUser();
  const { mutate: updateUserSchedule } = useUpdateUserSchedule();
  const [pending, setPending] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const [selectedServices, setSelectedServices] = useState<(Pick<ISubproduct, 'id' | 'name'> | undefined)[]>(subproductsAll);
  const initialValues = {
    email: '',
    fio: '',
    role: '',
    id: '',
    idfilial: activeFilial?.id,
    phone: '',
    position: '',
    offline: false,
    services: false,
    idservices: '',
    avatar: ''
  };
  const formik = useFormik({
    initialValues: modalPayload
      ? {
          email: modalPayload.Email,
          fio: modalPayload.fio,
          role: modalPayload.role,
          id: modalPayload.id,
          idfilial: modalPayload.idfilial,
          phone: modalPayload.phone,
          position: modalPayload.position,
          offline: modalPayload.offline === '' || modalPayload.offline === 'no' ? true : false,
          services: modalPayload.services,
          idservices: '',
          avatar: modalPayload?.avatar
        }
      : initialValues,
    onSubmit: (values: any) => {},
    validationSchema: validationEmployeeSchema,
    enableReinitialize: true
  });

  useEffect(() => {
    formik.initialValues = initialValues;
  }, []);
  useEffect(() => {
    if (modalPayload && modalPayload.idservices) {
      const { idservices } = modalPayload;
      setSelectedServices(
        subproductsAll?.filter((item) => idservices.findIndex((itemService: IUserServices) => itemService.idservice === item?.id) > -1)
      );
    }
  }, [subproductsAll]);

  useEffect(() => {
    if (formik.values.fio && formik.values.email && formik.values.phone && (formik.values.idfilial || formik.values.idfilial === '')) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formik.values]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      try {
        const resizedBase64 = await resizeAndConvertToBase64(file, 200, 200);

        if (typeof resizedBase64 === 'string') {
          formik.setFieldValue('avatar', resizedBase64);
        } else {
          console.error('Error resizing and converting to base64: Failed to get a string');
        }
      } catch (error) {
        console.error('Error resizing and converting to base64:', error);
      }
    }
  };

  const createEmployee = async (values: any) => {
    const idfather = user?.filter((item) => item?.role === 'admin')[0].id;
    const offlineConvert = values.offline ? 'no' : 'yes';
    const name = capitalizeEveryWord(values.fio);
    const timeWorkDataArr: any[] = transformFetchFilialScheduleData(activeFilial);
    setPending(true);
    const res = await createUser({
      ...values,
      idfather: idfather,
      offline: offlineConvert,
      fio: name,
      idservices: values.services
        ? selectedServices.map((item) => {
            return {
              idservice: item?.id
            };
          })
        : []
    });

    if (res?.status === 'ok') {
      setPending(false);
      if (values.services && res.id) {
        updateUserSchedule({ id: res.id, data: timeWorkDataArr });
      }

      closeModal!();
      offlineConvert === 'no'
        ? toast.success('Приглашение отправлено на указанную почту!')
        : toast.success('Сотрудник добавлен в список без приглашения');
    } else {
      setPending(false);
      if (res.description === 'user not registry') {
        toast.error('Указанная электронная почта уже используется в системе');
      } else {
        toast.error(res.description);
      }
    }
  };
  const editEmployee = async (values: any) => {
    setPending(true);
    const offlineConvert = values.offline ? 'no' : 'yes';
    const { role, ...updatedValues } = values;
    const res = await updateUser({
      ...updatedValues,
      idservices: values.services
        ? selectedServices.map((item) => {
            return {
              idservice: item?.id
            };
          })
        : [],
      offline: offlineConvert
    });

    if (res?.status === 'ok') {
      toast.success('Данные о сотруднике обновлены');
      closeModal?.();
    } else {
      setPending(false);
      toast.error(res.description);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      !formValid ||
      formik.values.fio === '' ||
      formik.values.email === '' ||
      formik.values.phone === '' ||
      (formik.values.offline === true && formik.values.role === '') ||
      isPending
    ) {
      if (formik.values.fio === '') {
        setNameError(true);
      }
      if (formik.values.email === '') {
        setEmailError(true);
      }
      if (formik.values.phone === '') {
        setPhoneError(true);
      }
      if (formik.values.role === '' && formik.values.offline === true) {
        setRoleError(true);
      }
      return;
    }
    if (edit) {
      editEmployee(formik.values);
    } else {
      createEmployee(formik.values);
    }
  };
  useEffect(() => {
    if (nameError) {
      formik.values.fio !== '' && setNameError(false);
    }
  }, [formik.values.fio]);
  useEffect(() => {
    if (phoneError) {
      formik.values.phone !== '' && setPhoneError(false);
    }
  }, [formik.values.phone]);
  useEffect(() => {
    if (emailError) {
      formik.values.email !== '' && setEmailError(false);
    }
  }, [formik.values.email]);
  useEffect(() => {
    if (roleError) {
      formik.values.role !== '' && setRoleError(false);
    }
  }, [formik.values.role]);

  return (
    <DinamycHeight>
      <Wrapper>
        {isMobile ? null : (
          <Text
            $tag={TextType.H5}
            fontWeight={700}
          >
            {!edit ? t('Добавление сотрудника') : t('Редактирование данных сотрудника')}
          </Text>
        )}

        <FlexWithAlign>
          <FormStyle onSubmit={handleSubmit}>
            <UploadBlock
              $align={'center'}
              $gap={isMobile ? MODAL_TEXT_GAP : MODAL_GAP_L}
              $column={isMobile}
            >
              <Avatar
                size={isMobile ? AvatarSize.L : AvatarSize.XL}
                rounded={AvatarRounded.FULL}
                avatar={formik.values.avatar || EmployeeImg}
              />

              <FlexWithAlign
                className={'full'}
                $column
                $gap={MODAL_TEXT_GAP}
                $justify={'space-between'}
              >
                {!isMobile && (
                  <FlexWithAlign
                    $column
                    $gap={'0'}
                  >
                    <Text
                      $tag={TextType.P}
                      $pSize={TextPType.P15}
                    >
                      {t('Добавьте аватар')}
                    </Text>
                    <Text
                      $tag={TextType.P}
                      $pSize={TextPType.P13}
                      color={'text02'}
                    >
                      {t('Выберите ваше изображение для аватара.')}
                    </Text>
                  </FlexWithAlign>
                )}
                <InputUpload>
                  <label htmlFor={'avatarInput'}>
                    <Text
                      $tag={TextType.P}
                      $pSize={TextPType.P15}
                    >
                      {isMobile ? (
                        <span>{t('Добавьте аватар')}</span>
                      ) : (
                        <>
                          <span>{t('Выберите файл')}</span> {t('или перенесите')}
                        </>
                      )}
                    </Text>
                    <input
                      type='file'
                      id='avatarInput'
                      accept='image/*'
                      onChange={handleFileChange}
                    />
                  </label>
                </InputUpload>
              </FlexWithAlign>
            </UploadBlock>
            <Box className='form'>
              <Input
                size={InputType.M}
                label={t('Имя Фамилия сотрудника*')}
                value={formik.values.fio}
                onChange={formik.handleChange}
                name='fio'
                onBlur={formik.handleBlur}
                error={formik.errors.fio || (nameError && 'Введите Имя и Фамилию сотрудника')}
              />

              <Input
                size={InputType.M}
                label={t('Телефон*')}
                name='phone'
                value={formik.values.phone}
                onChange={formik.handleChange}
                type={'tel'}
                onBlur={formik.handleBlur}
                error={formik.errors.phone || (phoneError && 'Введите телефон')}
              />
              {modalPayload?.id !== getCookie('id') && (
                <Input
                  size={InputType.M}
                  label={'Email*'}
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  type={'email'}
                  onBlur={formik.handleBlur}
                  error={formik.errors.email || (emailError && 'Введите email')}
                />
              )}
              <Input
                size={InputType.M}
                label={t('Должность')}
                name='position'
                value={formik.values.position}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.position && formik.errors.position}
              />

              <FlexWithAlign
                $column
                $gap={MODAL_TEXT_GAP}
              >
                <FlexWithAlign
                  $align='center'
                  $gap='8px'
                  className='services-by-employee-wrap'
                >
                  <Text
                    $tag={TextType.P}
                    $pSize={TextPType.P15}
                  >
                    {t('Сотрудник оказывает услуги')}?
                  </Text>
                  <Tooltip
                    text='Данный сотрудник оказывает услуги клиентам и будет отображаться в журнале записей'
                    icon={<IconInstance name={EIcon.questionempty} />}
                  />
                </FlexWithAlign>

                <FlexWithAlign
                  className={'full'}
                  $gap={MODAL_GAP}
                >
                  <CustomCheckboxWrap
                    onClick={(e) => {
                      e.preventDefault();
                      formik.setFieldValue('services', true);
                    }}
                  >
                    <div className={cn('check-wrapper', formik.values.services === true && 'active')}>
                      <span></span>
                    </div>
                    <Text
                      $tag={TextType.P}
                      $pSize={TextPType.P15}
                    >
                      {t('Да')}
                    </Text>
                  </CustomCheckboxWrap>
                  <CustomCheckboxWrap
                    onClick={(e) => {
                      e.preventDefault();
                      formik.setFieldValue('services', false);
                    }}
                  >
                    <div className={cn('check-wrapper', formik.values.services === false && 'active')}>
                      <span></span>
                    </div>
                    <Text
                      $tag={TextType.P}
                      $pSize={TextPType.P15}
                    >
                      {t('Нет')}
                    </Text>
                  </CustomCheckboxWrap>
                </FlexWithAlign>
              </FlexWithAlign>
              {formik.values.services && (
                <MultiSelect
                  direction={DirectionType.VERTICAL}
                  size={InputType.M}
                  optionPosition={PositionType.BOTTOM}
                  options={subproductsAll ? subproductsAll : []}
                  value={selectedServices}
                  setValue={setSelectedServices}
                  placeholder={t('Услуги')}
                />
              )}

              <div className='inviteemployeer'>
                <input
                  type='checkbox'
                  name='offline'
                  value={'false'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='inviteemployeer-input'
                  disabled={(edit && modalPayload?.offline === 'no') || (profileUser && profileUser.role === 'manager')}
                  defaultChecked={formik.values?.offline === true}
                />
                <span className={cn(formik.values?.offline === true && 'active', 'custom-check')}>
                  <IconInstance name={EIcon.success} />
                </span>
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P15}
                >
                  {t('Пригласить в систему')}
                </Text>
                <Tooltip
                  text='Сотруднику будет направлен пароль от личного кабинета на электронную почту'
                  icon={<IconInstance name={EIcon.questionempty} />}
                />

                <div className={'icon-wrap flex'}></div>
              </div>

              {formik.values.offline === true && (
                <Dropdown
                  size={InputType.M}
                  onChange={(option: any) => {
                    formik.setFieldValue('role', option.value);
                  }}
                  error={roleError && 'Выберите Роль сотрудника'}
                  position={'top'}
                  options={roleArray}
                  currentValue={roleArray.find((item) => item.label === formik.values.role)?.label || formik.values.role}
                  labelTitle={t('Роль в системе')}
                  formik={formik}
                />
              )}

              <FlexWithAlign
                $align='center'
                className={cn(isMobile && 'column-reverse')}
                $gap={MODAL_GAP}
                $justify='flex-start'
              >
                <CommonButton
                  typeBtn='gray'
                  size={'M'}
                  fullWidth={isMobile}
                  onClick={() => closeModal?.()}
                >
                  {t('Отменить')}
                </CommonButton>
                <CommonButton
                  typeBtn='primary'
                  size={'M'}
                  fullWidth={isMobile}
                >
                  <ButtonInner>
                    <span>{edit ? t('Сохранить') : t('Добавить')}</span>
                  </ButtonInner>
                </CommonButton>
              </FlexWithAlign>
            </Box>
          </FormStyle>
        </FlexWithAlign>
      </Wrapper>
    </DinamycHeight>
  );
};

export default CreateEmployeersModal;
