import styled from 'styled-components';

export const Wrapper = styled.div`
  > .button {
    cursor: pointer;
    transition: .3s ease;
    &:hover {
      background: ${(props) => props.theme.color.accent};
    }
    &.active {
      background: ${(props) => props.theme.color.accent};
      color: #fff;
    }
  }
`;
export const Menu = styled.div`
  position: absolute;
  height: 100vh;
  top: calc(100%);
  z-index: 10;
  right: -100%;
  padding: 30px 20px;
  transition: 0.3s ease;
  background: ${(props) => props.theme.color.base01};
  max-width: 365px;
  //height: 100vh;
  width: 100%;
  border: 2px solid ${(props) => props.theme.color.base04};
  box-shadow: 0 4px 14px 0 rgba(41, 47, 81, 0.08);
  visibility: hidden;
  &.active {
    right: 0px;
    visibility: visible;
  }
  .header {
    justify-content: space-between;
    align-items: center;
    > div {
      button {
        svg {
          color: ${(props) => props.theme.color.mainDark};
        }
      }
    }
  }
  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 74px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 38px;
    .empty {
      max-width: 161px;
      width: 100%;
      margin-top: 100px;
      align-items: center;
      svg {
        color: ${(props) => props.theme.color.accent};
      }
    }
  }
`;
export const LoaderLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.color.yellow}1A;
  z-index: 10;
`;
