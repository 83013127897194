import { ReactElement } from 'react';

export enum PositionType {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export interface IToolTipProps {
  text: string;
  icon: ReactElement;
  $color?: string;
  $position?: PositionType;
  className?: string;
}

export interface IWrapperProps {
  $color: string;
}

export interface ITextProps {
  $position: PositionType;
}
