import React from 'react';

export function SidebarRecording() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M9.05141 20.7326C9.18313 20.8838 9.03103 21.1116 8.8386 21.0551C7.34566 20.6169 5.81173 20.3303 4.26008 20.2001C3.63946 20.1449 3.06238 19.858 2.64362 19.3966C2.22486 18.9353 1.99512 18.3332 2.00008 17.7101V6.71011C1.99712 6.35625 2.07377 6.00625 2.22437 5.68601C2.37496 5.36578 2.59564 5.08352 2.87008 4.86011C3.14379 4.63042 3.46569 4.46531 3.81192 4.37702C4.15815 4.28873 4.51981 4.27954 4.87008 4.35011C5.07008 4.35011 5.29008 4.43011 5.51008 4.49011V15.6901C5.5081 16.1776 5.60855 16.66 5.80494 17.1062C6.00132 17.5524 6.28926 17.9523 6.65008 18.2801C7.49767 19.0504 8.29946 19.8692 9.05141 20.7326Z'
        fill='#467FF3'
      />
      <path
        opacity='0.5'
        d='M22 6.74007V17.7401C22.0024 18.364 21.7705 18.9662 21.3501 19.4273C20.9297 19.8885 20.3515 20.1749 19.73 20.2301C17.5955 20.4058 15.4974 20.8874 13.5 21.6601L13.1582 21.7922C12.9616 21.8682 12.75 21.7232 12.75 21.5124V6.83909C12.75 6.6497 12.857 6.47657 13.0264 6.39187L13.33 6.24007C15.1804 5.37737 17.1326 4.7524 19.14 4.38007C19.488 4.31211 19.8467 4.3221 20.1903 4.40931C20.534 4.49651 20.8541 4.65878 21.1275 4.88443C21.401 5.11009 21.6211 5.39353 21.7719 5.71438C21.9228 6.03523 22.0007 6.38552 22 6.74007Z'
        fill='#467FF3'
      />
      <path
        d='M10.9455 5.47109C11.1453 5.7287 11.2507 6.04645 11.2507 6.37246V20.5189C11.2507 20.7091 11.0087 20.7931 10.8888 20.6453C9.89777 19.4236 8.81533 18.2785 7.65072 17.2201C7.439 17.0266 7.27138 16.7898 7.15923 16.5258C7.04708 16.2618 6.99302 15.9768 7.00072 15.6901V2.7901C7.00071 2.69864 7.01888 2.60809 7.05418 2.52372C7.08948 2.43934 7.1412 2.36283 7.20634 2.29862C7.27148 2.23442 7.34873 2.1838 7.4336 2.14972C7.51847 2.11564 7.60927 2.09877 7.70072 2.1001C7.7941 2.09832 7.88681 2.11608 7.97292 2.15225C8.05902 2.18841 8.13662 2.24218 8.20072 2.3101C9.17345 3.31233 10.0898 4.36762 10.9455 5.47109Z'
        fill='#467FF3'
      />
    </svg>
  );
}
