import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  //max-width: 482px;
  width: 100%;
  margin: 0 auto;
  gap: 26px;
  //.kassa .caUyYl {
  //  left: 40px;
  //}
  //.kassa .custom-select__value-container.custom-select__value-container--has-value {
  //  left: 26px;
  //}
  //.filled, .focused {
  //  .icon-wrapper {
  //    top: 60%;
  //  }
  //}
  .icon-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
      color: ${(props) => props.theme.color.primary};
    }
  }
  //> div {
  //  max-width: 90%;
  //}
  .btns {
    gap: 19px;
    button {
      background: transparent;
      height: 50px;
      border: 1px solid ${(props) => props.theme.color.secondaryLightMedium};
      width: 100%;
      padding: 9px 15px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        span {
          font-size: 13px;
          font-weight: 400;
          color: ${(props) => props.theme.color.mainDarkFull};
          &.gray {
            color: #858585;
          }
        }
      }
      .radio {
        height: 22px;
        width: 22px;
        background: #ecf1f7;
        border-radius: 50%;
        position: relative;
        transition: 0.3s ease;
        &:before {
          content: '';
          transition: 0.3s ease;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
          opacity: 0;
          border-radius: 50%;
          background: ${(props) => props.theme.color.mainDarkFull};
        }
      }
      &.active {
        .radio {
          background: ${(props) => props.theme.color.accent};
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
`;

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
  span {
    opacity: 0.5;
    &.active {
      opacity: 1;
      color: ${(props) => props.theme.color.primary};
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  &.info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(240 / 934 * 100%);
    flex-shrink: 0;
  }
  &.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(670 / 934 * 100%);
  }
`;

export const FormItem = styled.div`
  padding: 10px 18px;
  display: flex;
  gap: 12px;
  align-items: center;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.color.base03};
  & > div {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  label {
    padding: 0;
    position: initial;
    width: fit-content;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: ${(props) => props.theme.color.mainDark};
    &.active {
      color: ${(props) => props.theme.color.mainDark};
      & ~ div svg {
        color: inherit;
      }
    }
    & ~ div svg {
      opacity: 0.5;
    }
  }
  //input {
  //  padding-left: 0;
  //  border: none;
  //  height: 16px;
  //  color: #292f51;
  //  font-size: 14px;
  //  line-height: 16px;
  //  letter-spacing: 0.14px;
  //  &:focus::placeholder {
  //    opacity: 0;
  //  }
  //}
`;

export const InfoContent = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.color.base03};
`;

export const InfoItem = styled.div`
  padding: 0 20px;
  display: flex;
  gap: 12px;
  span {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.14px;
  }
  &.head {
    padding-bottom: 20px;
    border-bottom: 2px solid ${(props) => props.theme.color.base03};
    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }
  .empty {
    opacity: 0.5;
  }
`;

export const Notice = styled.div`
  color: ${(props) => props.theme.color.danger};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.14px;
`;
export const ButtonInner = styled.div`
  display: flex;
  gap: 12px;
`;
