import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { useFormik } from 'formik';

import { Form, Wrapper } from './CreateCabinetModal.styled';

import { EIcon, IconNew as IconInstance } from '../../../../../components/icons/medium-new-icons/icon';
import { ModalGrid } from '../../../../../pages/Recording/lib/CommonRecording.styled';
import { Input, MultiSelect, PositionType, Text, TextType } from '../../../../../shared/ui';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import Timepicker from '../../../../../shared/ui/Timepicker/ui/Timepicker';
import { ICabinet } from '../../../../../store/redux/cabinets/cabinets.interface';
import { useCreateCabinet } from '../../../../../store/redux/cabinets/hooks/useCabinetsMutation';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { useProductsIdsQuery } from '../../../../../store/redux/products/hooks/useProductsQuery';
import { useSubproductAllQuery } from '../../../../../store/redux/subproducts/hooks/useSubproductQuery';
import { ISubproduct } from '../../../../../store/redux/subproducts/subproducts.interface';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { cn } from '../../../../../shared/lib';
import { Calculate } from '../recording/single/client-info/RecordingModalSide.styled';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';
import { MODAL_GAP } from '../../../../../styles';

interface IProps {
  closeModal?: () => void;
  modalPayload?: any;
  edit?: any;
}
const CreateCabinetModal: React.FC<IProps> = ({ modalPayload, closeModal }) => {
  const [pending, setPending] = useState(false);
  const { t } = useTranslation();
  const { data: ids } = useProductsIdsQuery();
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const { data: subproductsAll } = useSubproductAllQuery(ids);
  const { data: activeFilial } = useFilialQuery();
  const { mutateAsync: createCabinetFn } = useCreateCabinet();
  const [selectedServices, setSelectedServices] = useState<(Pick<ISubproduct, 'id' | 'name'> | undefined)[]>(subproductsAll);
  const [nameError, setNameError] = useState(false);
  const [seatsLimitedError, setSeatsLimitedError] = useState(false);
  const initialValues = {
    filial: activeFilial?.id,
    id: '',
    name: '',
    seatsLimited: 1,
    workHoursEnd: '',
    workHoursStart: '',
    services: ''
  };
  const formik = useFormik({
    initialValues: modalPayload
      ? {
          ...modalPayload,
          services: ''
        }
      : initialValues,
    onSubmit: () => {}
  });
  const createCabinet = async (values: ICabinet) => {
    setPending(true);
    const res = await createCabinetFn({
      ...values,
      id: activeFilial?.id ? activeFilial?.id : '',
      idservices: selectedServices?.map((item) => {
        return {
          idservice: item?.id ? item?.id : ''
        };
      }),
      scheduleId: ''
    });

    if (res?.status === 'ok') {
      closeModal!();
      toast.success('Кабинет добавлен');
    } else {
      setPending(false);
      toast.error(res.description);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formik.values.name === '' || formik.values.seatsLimited < 1) {
      if (formik.values.name === '') {
        setNameError(true);
      }
      if (formik.values.seatsLimited < 1) {
        setSeatsLimitedError(true);
      }
      return;
    }

    createCabinet(formik.values);
  };

  useEffect(() => {
    if (nameError) {
      formik.values.name !== '' && setNameError(false);
    }
  }, [formik.values.name]);

  useEffect(() => {
    if (seatsLimitedError) {
      formik.values.seatsLimited > 0 && setSeatsLimitedError(false);
    }
  }, [formik.values.seatsLimited]);

  return (
    <Wrapper>
      <FlexWithAlign
        $align={'center'}
        $gap={'20px'}
      >
        {isMobile ? null : (
          <Text
            $tag={TextType.H5}
            fontWeight={700}
          >
            {t('Добавление кабинета')}
          </Text>
        )}
      </FlexWithAlign>

      <Form onSubmit={handleSubmit}>
        <Input
          label={t('Название*')}
          value={formik.values.name}
          onChange={formik.handleChange}
          name='name'
          autoFocus={true}
          error={nameError && 'Введите название'}
          type='text'
          autoComplete='off'
        />
        <Calculate>
          <Input
            label={'Вместимость'}
            value={formik.values.seatsLimited}
            onChange={formik.handleChange}
            name='seatsLimited'
            min={1}
            max={99}
            error={seatsLimitedError && 'Значение должно быть больше или равно 1'}
            onBlur={formik.handleBlur}
            type='number'
            autoComplete='off'
          />

          <FlexWithAlign
            $column
            $gap={'0'}
          >
            <button
              onClick={(e: any) => {
                e.preventDefault();
                formik.setFieldValue('seatsLimited', formik.values.seatsLimited + 1);
              }}
            >
              <IconInstance name={EIcon.chevronupsmall} />
            </button>

            <button
              className={cn(formik.values.seats === 0 && 'hidden')}
              onClick={(e: any) => {
                e.preventDefault();
                formik.values.seatsLimited >= 0 && formik.setFieldValue('seatsLimited', formik.values.seatsLimited - 1);
              }}
            >
              <IconInstance name={EIcon.chevrondownsmall} />
            </button>
          </FlexWithAlign>
        </Calculate>

        <ModalGrid>
          <Timepicker
            label={'Начало'}
            value={formik.values.workHoursStart}
            onChange={formik.handleChange}
            name='workHoursStart'
          />
          <Timepicker
            label={'Окончание'}
            value={formik.values.workHoursEnd}
            onChange={formik.handleChange}
            name='workHoursEnd'
          />
        </ModalGrid>

        <MultiSelect
          optionPosition={PositionType.TOP}
          options={subproductsAll ? subproductsAll : []}
          value={selectedServices}
          setValue={setSelectedServices}
          placeholder={t('Услуги')}
        />

        <FlexWithAlign
          $align='center'
          $justify='flex-start'
          className={cn(isMobile && 'column-reverse')}
          $gap={MODAL_GAP}
        >
          <CommonButton
            onClick={(e: any) => {
              e.preventDefault();
              closeModal!();
            }}
            fullWidth={isMobile}
            typeBtn='gray'
            size={'M'}
          >
            {t('Отменить')}
          </CommonButton>
          <CommonButton
            typeBtn='primary'
            size={'M'}
            fullWidth={isMobile}
            type='submit'
            disabled={pending}
          >
            <span>{t('Добавить')}</span>
          </CommonButton>
        </FlexWithAlign>
      </Form>
    </Wrapper>
  );
};

export default CreateCabinetModal;
