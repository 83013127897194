import React from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { CloseBtn, Container, Date as DateText } from './Note.styled';

import { Text, TextPType, TextType } from '../../../shared/ui';
import { INote } from '../../../store/redux/notes/notes.interface';
import { EIcon, IconNew as IconInstance } from '../../icons/medium-new-icons/icon';

dayjs.extend(utc);

interface IProps {
  note: INote;
  handleDelete: (data: any) => void;
}

const Note: React.FC<IProps> = ({ note, handleDelete }) => {
  const formattedDate: string | undefined = note?.date
    ? dayjs.utc(new Date(note.date).toUTCString()).format('DD.MM.YYYY HH:mm')
    : undefined;

  return (
    <Container>
      <CloseBtn onClick={() => handleDelete(note)}>
        <IconInstance name={EIcon.close} />
      </CloseBtn>
      <Text
        $tag={TextType.P}
        $pSize={TextPType.P13}
      >
        {note?.text}
      </Text>
      <DateText>
        <Text
          $tag={TextType.P}
          $pSize={TextPType.P11}
          color={'text02'}
        >
          {formattedDate}
        </Text>
      </DateText>
    </Container>
  );
};

export default Note;
