import styled from 'styled-components';
import { DURATION, RADIUS_M, SHADOW_INPUT, SHADOW_INPUT_HOGER, Z_INDEX } from '../../../../../../styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  form {
    height: 100%;
  }
  .kassaHeading {
    border-bottom: 1px solid ${(props) => props.theme.color.base03};
    padding: 46px 46px 21px;
  }
  .btns {
    border-top: 1px solid ${(props) => props.theme.color.base03};
    padding-top: 23px;
    width: 100%;
    .close {
      background: ${(props) => props.theme.color.base03};
      color: ${(props) => props.theme.color.secondaryDark};
      border: 1px solid ${(props) => props.theme.color.base03};

      &:hover {
        background: transparent;
        border: 1px solid ${(props) => props.theme.color.base03};
      }
    }
  }
`;

export const PaymentTypesWrapper = styled.div`
  label {
    border-radius: ${RADIUS_M};
    box-shadow: ${SHADOW_INPUT};
    overflow: hidden;
    &:after {
      transition-property: color;
      transition-duration: ${DURATION};
      transition-timing-function: ease-in-out;
      color: ${(props) => props.theme.color.base03};
      position: absolute;
      inset: 0;
      content: '';
      border-radius: inherit;
      border: 1px solid currentColor;
      pointer-events: none;
    }
    &:focus,
    &:focus-within,
    &.active {
      &:after {
        box-shadow: ${SHADOW_INPUT_HOGER};
        border-width: 2px;
        color: ${(props) => props.theme.color.primary};
      }
    }
  }
  input {
    height: 44px;
    width: 100%;
    border: none !important;
    outline: none !important;
    background: ${(props) => props.theme.color.inverse};
    color: ${(props) => props.theme.text.text01};
    border-radius: 8px;
    padding: 0px 1rem;
    font-size: 13px;
    font-weight: 500;
    position: relative;
  }
  position: relative;
  > button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    z-index: ${Z_INDEX};
    svg {
      color: ${(props) => props.theme.text.text02};
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  &.info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(240 / 934 * 100%);
    flex-shrink: 0;
  }
  &.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(670 / 934 * 100%);
  }
`;

export const FormItem = styled.div`
  padding: 10px 18px;
  display: flex;
  gap: 12px;
  align-items: center;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.color.base03};
  & > div {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  label {
    padding: 0;
    position: initial;
    width: fit-content;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: ${(props) => props.theme.color.mainDark};
    &.active {
      color: ${(props) => props.theme.color.mainDark};
      & ~ div svg {
        color: inherit;
      }
    }
    & ~ div svg {
      opacity: 0.5;
    }
  }
  //input {
  //  padding-left: 0;
  //  border: none;
  //  height: 16px;
  //  color: #292f51;
  //  font-size: 14px;
  //  line-height: 16px;
  //  letter-spacing: 0.14px;
  //  &:focus::placeholder {
  //    opacity: 0;
  //  }
  //}
`;

export const ButtonInner = styled.div`
  display: flex;
  gap: 12px;
`;
