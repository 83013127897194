import React from 'react';

import { Circles } from './actions/Circles';
import { CirclesEmpty } from './actions/CirclesEmpty';
import { Close } from './actions/Close';
import { CloseFill } from './actions/CloseFill';
import { DeleteIcon } from './actions/DeleteIcon';
import { DoubleCheck } from './actions/DoubleCheck';
import { Filter } from './actions/Filter';
import { ImportFileIcon } from './actions/ImportFileIcon';
import { LogoMark } from './actions/LogoMark';
import { LogoMarkWhite } from './actions/LogoMarkWhite';
import { MinusFill } from './actions/MinusFill';
import { MoreHorizontalLines } from './actions/MoreHorizontalLines';
import { MoreVerticalOutline } from './actions/MoreVerticalOutline';
import { OptionsWithCircle } from './actions/OptionsWithCircle';
import { Question } from './actions/Question';
import { QuestionEmpty } from './actions/QuestionEmpty';
import { RecordingSquareEmpty } from './actions/RecordingSquareEmpty';
import { RecordingSquareLine } from './actions/RecordingSquareLine';
import { RecordingSquareQr } from './actions/RecordingSquareQr';
import { Send } from './actions/Send';
import { ShapePlus } from './actions/ShapePlus';
import { Smile } from './actions/Smile';
import { StarOutline } from './actions/StarOutline';
import { Success } from './actions/Success';
import { SuccessFill } from './actions/SuccessFill';
import { ThumbsDownOutline } from './actions/ThumbsDownOutline';
import { ThumbsUpOutline } from './actions/ThumbsUpOutline';
import { UserOutline } from './actions/UserOutline';
import { WarningFill } from './actions/WarningFill';
import { Workbench } from './actions/Workbench';
import { Arrow2SquarepathOutline } from './arrows/Arrow2SquarepathOutline';
import { ArrowCalendarNext } from './arrows/ArrowCalendarNext';
import { ArrowCalendarPrev } from './arrows/ArrowCalendarPrev';
import { ArrowDownOutline } from './arrows/ArrowDownOutline';
import { ArrowDownToSquareOutline } from './arrows/ArrowDownToSquareOutline';
import { ArrowEnterSquareOutline } from './arrows/ArrowEnterSquareOutline';
import { ArrowLeftOutline } from './arrows/ArrowLeftOutline';
import { ArrowLeftSquareOutline } from './arrows/ArrowLeftSquareOutline';
import { ArrowRightCircleOutline } from './arrows/ArrowRightCircleOutline';
import { ArrowRightOutline } from './arrows/ArrowRightOutline';
import { ArrowRightSquareOutline } from './arrows/ArrowRightSquareOutline';
import { ArrowshapeLeft2outline } from './arrows/ArrowshapeLeft2outline';
import { ArrowTurnLeftOutline } from './arrows/ArrowTurnLeftOutline';
import { ArrowUpOutline } from './arrows/ArrowUpOutline';
import { ArrowUpRightOutline } from './arrows/ArrowUpRightOutline';
import { ArrowUturnLeftOutline } from './arrows/ArrowUturnLeftOutline';
import { ArrowUturnRightOutline } from './arrows/ArrowUturnRightOutline';
import { ChevronDownSmall } from './arrows/ChevronDownSmall';
import { ChevronLeftOutline } from './arrows/ChevronLeftOutline';
import { ChevronLeftSmall } from './arrows/ChevronLeftSmall';
import { ChevronLeftSquareOutline } from './arrows/ChevronLeftSquareOutline';
import { ChevronRightOutline } from './arrows/ChevronRightOutline';
import { ChevronRightSmall } from './arrows/ChevronRightSmall';
import { ChevronRightSquareOutline } from './arrows/ChevronRightSquareOutline';
import { ChevronUpSmall } from './arrows/ChevronUpSmall';
import { CloudArrowUpOutline } from './arrows/CloudArrowUpOutline';
import { DocumentArrowUpOutline } from './arrows/DocumentArrowUpOutline';
import { DoorArrowLeftOutline } from './arrows/DoorArrowLeftOutline';
import { DoorArrowRightOutline } from './arrows/DoorArrowRightOutline';
import { DownIcon } from './arrows/DownIcon';
import { DownloadCheckOutline } from './arrows/DownloadCheckOutline';
import { DownloadCloudOutline } from './arrows/DownloadCloudOutline';
import { DownloadCrossBadgeOutline } from './arrows/DownloadCrossBadgeOutline';
import { DownloadOutline } from './arrows/DownloadOutline';
import { ExportIcon } from './arrows/ExportIcon';
import { ExternalLinkOutline } from './arrows/ExternalLinkOutline';
import { FolderSimpleArrowRight } from './arrows/FolderSimpleArrowRight';
import { FolderSimpleArrowUpOutline } from './arrows/FolderSimpleArrowUpOutline';
import { ImportIcon } from './arrows/ImportIcon';
import { LeftIcon } from './arrows/LeftIcon';
import { LinkCircle } from './arrows/LinkCircle';
import { RefreshOutline } from './arrows/RefreshOutline';
import { Repeat1Outline } from './arrows/Repeat1Outline';
import { RepeatOutline } from './arrows/RepeatOutline';
import { ReplayOutline } from './arrows/ReplayOutline';
import { ReplyOutline } from './arrows/ReplyOutline';
import { RightWithSquareIcon } from './arrows/RightWithSquareIcon';
import { ShareExternalOutline } from './arrows/ShareExternalOutline';
import { ShareOutline } from './arrows/ShareOutline';
import { ShuffleOutline } from './arrows/ShuffleOutline';
import { SortOutline } from './arrows/SortOutline';
import { StatisticsOutline } from './arrows/StatisticsOutline';
import { UnarchiveOutline } from './arrows/UnarchiveOutline';
import { UploadOutline } from './arrows/UploadOutline';
import { EditHumanIcon } from './human/EditHumanIcon';
import { UserAdded } from './human/UserAdded';
import { UserAddedOutline } from './human/UserAddedOutline';
import { UserDropdown } from './human/UserDropdown';
import { UserFilled } from './human/UserFilled';
import { UserIcon } from './human/UserIcon';
import { UsersIcon } from './human/UsersIcon';
import { AnalyticIcon } from './icons/AnalyticIcon';
import { CalendarIcon } from './icons/CalendarIcon';
import { CalendarRecording } from './icons/CalendarRecording';
import { CartIcon } from './icons/CartIcon';
import { ChatIcon } from './icons/ChatIcon';
import { ClockIcon } from './icons/ClockIcon';
import { FileIcon } from './icons/FileIcon';
import { FilialIcon } from './icons/FilialIcon';
import { FinansIcon } from './icons/FinansIcon';
import { HomeIcon } from './icons/HomeIcon';
import { LogoFirstIcon } from './icons/LogoFirstIcon';
import { LogoIcon } from './icons/LogoIcon';
import { ManagementIcon } from './icons/ManagementIcon';
import { MarketingIcon } from './icons/MarketingIcon';
import { MessageIcon } from './icons/MessageIcon';
import { ModuleSettingsIcon } from './icons/ModuleSettingsIcon';
import { PayIcon } from './icons/PayIcon';
import { PaymentAddIcon } from './icons/PaymentAddIcon';
import { PayOutlineIcon } from './icons/PayOutlineIcon';
import { PhoneDop } from './icons/PhoneDop';
import { PhoneIcon } from './icons/PhoneIcon';
import { PostIcon } from './icons/PostIcon';
import { ProcentIcon } from './icons/ProcentIcon';
import { RecordingIcon } from './icons/RecordingIcon';
import { SearchModalIcon } from './icons/SearchModalIcon';
import { SettingPortalIcon } from './icons/SettingPortalIcon';
import { SettingsIcon } from './icons/SettingsIcon';
import { StarIcon } from './icons/StarIcon';
import { SuccessOutlineIcon } from './icons/SuccessOutlineIcon';
import { SwitchIcon } from './icons/SwitchIcon';
import { TabIcon } from './icons/TabIcon';
import { TasksIcon } from './icons/TasksIcon';
import { TicketIcon } from './icons/TicketIcon';
import { TrashIcon } from './icons/TrashIcon';
import { BillheadOutline } from './objects/BillheadOutline';
import { BookSpreadOutline } from './objects/BookSpreadOutline';
import { CalendarAddOutline } from './objects/CalendarAddOutline';
import { CheckShieldOutline } from './objects/CheckShieldOutline';
import { ComputerOutline } from './objects/ComputerOutline';
import { CrossShieldOutline } from './objects/CrossShieldOutline';
import { MarketOutline } from './objects/MarketOutline';
import { MoneyRequestOutline } from './objects/MoneyRequestOutline';
import { MoneySendOutline } from './objects/MoneySendOutline';
import { Planet } from './objects/Planet';
import { Product } from './objects/Product';
import { SquaresTwo } from './objects/SquaresTwo';
import { Category } from './products/Category';
import { ProductsMenuIcon } from './products/ProductsMenuIcon';
import { Schedule } from './products/Schedule';
import { Service } from './products/Service';
import { MinusIcon } from './signs/MinusIcon';
import { MinusOutlineIcon } from './signs/MinusOutlineIcon';
import { PlusIcon } from './signs/PlusIcon';
import { PlusOutlineIcon } from './signs/PlusOutlineIcon';
import { PlusSquareIcon } from './signs/PlusSquareIcon';
import { WarningIcon } from './signs/WarningIcon';
import { SmallCalendar } from './small-icons/SmallCalendar';
import { SmallEditIcon } from './small-icons/SmallEditIcon';
import { SmallInfo } from './small-icons/SmallInfo';
import { SmallTrashIcon } from './small-icons/SmallTrashIcon';
import { AvitoIcon } from './social/AvitoIcon';
import { MailStackOutline } from './social/MailStackOutline';
import { NewChat } from './social/NewChat';
import { SupportIcon } from './social/SupportIcon';
import { SupportIcon20 } from './social/SupportIcon20';
import { TelegramEmptyIcon } from './social/TelegramEmptyIcon';
import { TelegramIcon } from './social/TelegramIcon';
import { TelegramIcon20 } from './social/TelegramIcon20';
import { ViberIcon } from './social/ViberIcon';
import { VkIcon } from './social/VKIcon';
import { AllCategoriesOutline } from './unsorted/AllCategoriesOutline';
import { MoonIcon } from './icons/MoonIcon';
import { WhatsUpIcon } from '../social/WhatsUpIcon';
import { Menu } from './actions/Menu';
import { CartTarifIcon } from './icons/CartTarifIcon';
import { StarTarifIcon } from './icons/StarTarifIcon';
import { Megafon } from './signs/Megafon';
import { Chein } from './social/Chein';
import { LogoTLBN } from '../LogoTLBN';
import { ArrowLeftOutline48 } from './arrows/ArrowLeftOutline48';
import { Services2 } from './objects/Services2';
import { DisabledIcon } from './signs/DisabledIcon';
import { EditPen } from './actions/EditPen';
import { DayIcon } from './products/DayIcon';
import { ListIcon } from './products/ListIcon';
import { WeekIcon } from './products/WeekIcon';
import { BackgroundBorRecording } from './unsorted/BackgroundBorRecording';
import { FunnyGhost } from './human/funny-ghost';
import { ProfileUser } from './human/ProfileUser';
import { TapBarRecording } from './mobile/TapBarRecording';
import { TapBarChat } from './mobile/TapBarChat';
import { TapBarSchedule } from './mobile/TapBarSchedule';
import { TapBarMenu } from './mobile/TapBarMenu';
import { SidebarAnalytics } from './sidebar/SidebarAnalytics';
import { SidebarClients } from './sidebar/SidebarClients';
import { SidebarCompany } from './sidebar/SidebarCompany';
import { SidebarEmployee } from './sidebar/SidebarEmployee';
import { SidebarFinance } from './sidebar/SidebarFinance';
import { SidebarMessenger } from './sidebar/SidebarMessenger';
import { SidebarOnlineRecording } from './sidebar/SidebarOnlineRecording';
import { SidebarRecording } from './sidebar/SidebarRecording';
import { SidebarSchedule } from './sidebar/SidebarSchedule';
import { SidebarTasks } from './sidebar/SidebarTasks';
import { TapBarChatGray } from './mobile/TapBarChatGray';
import { TapBarMenuGray } from './mobile/TapBarMenuGray';
import { TapBarRecordingGray } from './mobile/TapBarRecordingGray';
import { TapBarScheduleGray } from './mobile/TapBarScheduleGray';
import { ListRecording } from './products/ListRecording';
import { CompanyGray } from './objects/CompanyGray';
import { NotifySuccess } from './signs/NotifySuccess';
import { NotifyError } from './signs/NotifyError';

export enum EIcon {
  arrow2squarepathoutline,
  listrecording,
  companygray,
  notifysuccess,
  sidebaranalytics,
  sidebarclients,
  tapbarchatgray,
  tapbarmenugray,
  tapbarrecordinggray,
  tapbarschedulegray,
  sidebarcompany,
  sidebaremployee,
  sidebarfinance,
  sidebarmessenger,
  sidebaronlinerecording,
  sidebarrecording,
  sidebarschedule,
  sidebartasks,
  calendaraddoutline,
  tapbarrecording,
  tapbarchat,
  tapbarmenu,
  tapbarschedule,
  funnyghost,
  services2,
  chein,
  profileuser,
  day,
  backgroundborrecording,
  list,
  week,
  disabled,
  megafon,
  cartTarif,
  starTarif,
  moon,
  squaretwo,
  product,
  smallinfo,
  menu,
  calendarrecording,
  doublecheck,
  send,
  smile,
  telegramempty,
  questionempty,
  success,
  circlesempty,
  morehorizontallines,
  warningfill,
  smallcalendar,
  userfilled,
  category,
  shapeplus,
  circles,
  service,
  question,
  tasks,
  schedule,
  checkshieldoutline,
  computeroutline,
  crossshieldoutline,
  mailstackoutline,
  marketoutline,
  useraddedoutline,
  arrowleft,
  arrowdown,
  arrowrightwithsquare,
  paymentadd,
  tab,
  calendar,
  cart,
  clock,
  minusoutline,
  minus,
  payoutline,
  phone,
  plus,
  plusoutline,
  plussquare,
  post,
  procent,
  star,
  succesoutline,
  trash,
  user,
  users,
  warning,
  searchmodal,
  smalltrash,
  smalledit,
  penedit,
  productsmenu,
  edithuman,
  import,
  export,
  analytic,
  chat,
  finans,
  home,
  management,
  recordingsettings,
  settingprotal,
  settings,
  logo,
  logofirst,
  file,
  filial,
  ticket,
  message,
  telegram,
  watsapp,
  viber,
  phonedop,
  switch,
  thumbsdownoutline,
  thumbsupoutline,
  modulesettings,
  moreverticaloutline,
  arrowdownoutline,
  arrowdowntosquareoutline,
  arrowentersquareoutline,
  arrowleftoutline,
  arrowleftsquareoutline,
  arrowrightcircleoutline,
  arrowrightoutline,
  arrowupoutline,
  arrowrightsquareoutline,
  arrowturnleftoutline,
  arrowuprightoutline,
  arrowuturnleftoutline,
  arrowuturnrightoutline,
  chevrondownsmall,
  chevronleftoutline,
  chevronleftsmall,
  chevronleftsquareoutline,
  chevronrightoutline,
  chevronrightsmall,
  chevronrightsquareoutline,
  chevronupsmall,
  cloudarrowupoutline,
  documentarrowupoutline,
  doorarrowleftoutline,
  doorarrowrightoutline,
  downloadcheckoutline,
  downloadcloudoutline,
  downloadcrossbadgeoutline,
  downloadoutline,
  externallinkoutline,
  foldersimplearrowright,
  foldersimplearrowupoutline,
  linkcircle,
  refreshoutline,
  repeat1outline,
  repeatoutline,
  replayoutline,
  replyoutline,
  shareexternaloutline,
  shareoutline,
  shuffleoutline,
  sortoutline,
  statisticsoutline,
  unarchiveoutline,
  uploadoutline,
  importfile,
  billheadoutline,
  bookspreadoutline,
  moneyrequestoutline,
  moneysendoutline,
  allcategoriesoutline,
  logomark,
  logomarkwhite,
  optionswithcircle,
  recordingsquareempty,
  recordingsquareline,
  recordingsquareqr,
  useroutline,
  staroutline,
  close,
  arrowshapeleft2outline,
  useradded,
  closefill,
  minusfill,
  successfill,
  workbench,
  arrowcalendarnext,
  arrowcalendarprev,
  userdropdown,
  deleteicon,
  supporticon,
  avitosocial,
  vksocial,
  newchat,
  planet,
  pay,
  marketing,
  notifyerror,
  filter,
  supporticon20,
  telegram20,
  logotlbn,
  arrowleftoutline48
}

const ICONS: Record<EIcon, any> = {
  [EIcon.arrow2squarepathoutline]: Arrow2SquarepathOutline,
  [EIcon.sidebaranalytics]: SidebarAnalytics,
  [EIcon.companygray]: CompanyGray,
  [EIcon.sidebarclients]: SidebarClients,
  [EIcon.sidebarcompany]: SidebarCompany,
  [EIcon.sidebaremployee]: SidebarEmployee,
  [EIcon.tapbarchatgray]: TapBarChatGray,
  [EIcon.tapbarmenugray]: TapBarMenuGray,
  [EIcon.tapbarrecordinggray]: TapBarRecordingGray,
  [EIcon.tapbarschedulegray]: TapBarScheduleGray,
  [EIcon.sidebarfinance]: SidebarFinance,
  [EIcon.sidebarmessenger]: SidebarMessenger,
  [EIcon.sidebaronlinerecording]: SidebarOnlineRecording,
  [EIcon.sidebarrecording]: SidebarRecording,
  [EIcon.sidebarschedule]: SidebarSchedule,
  [EIcon.sidebartasks]: SidebarTasks,
  [EIcon.logomark]: LogoMark,
  [EIcon.tapbarchat]: TapBarChat,
  [EIcon.tapbarschedule]: TapBarSchedule,
  [EIcon.tapbarrecording]: TapBarRecording,
  [EIcon.megafon]: Megafon,
  [EIcon.doublecheck]: DoubleCheck,
  [EIcon.product]: Product,
  [EIcon.telegramempty]: TelegramEmptyIcon,
  [EIcon.planet]: Planet,
  [EIcon.service]: Service,
  [EIcon.funnyghost]: FunnyGhost,
  [EIcon.circlesempty]: CirclesEmpty,
  [EIcon.questionempty]: QuestionEmpty,
  [EIcon.marketing]: MarketingIcon,
  [EIcon.send]: Send,
  [EIcon.disabled]: DisabledIcon,
  [EIcon.smile]: Smile,
  [EIcon.tapbarmenu]: TapBarMenu,
  [EIcon.shapeplus]: ShapePlus,
  [EIcon.category]: Category,
  [EIcon.schedule]: Schedule,
  [EIcon.question]: Question,
  [EIcon.circles]: Circles,
  [EIcon.calendarrecording]: CalendarRecording,
  [EIcon.squaretwo]: SquaresTwo,
  [EIcon.tasks]: TasksIcon,
  [EIcon.morehorizontallines]: MoreHorizontalLines,
  [EIcon.logomarkwhite]: LogoMarkWhite,
  [EIcon.smallcalendar]: SmallCalendar,
  [EIcon.success]: Success,
  [EIcon.optionswithcircle]: OptionsWithCircle,
  [EIcon.recordingsquareline]: RecordingSquareLine,
  [EIcon.recordingsquareempty]: RecordingSquareEmpty,
  [EIcon.recordingsquareqr]: RecordingSquareQr,
  [EIcon.notifysuccess]: NotifySuccess,
  [EIcon.notifyerror]: NotifyError,
  [EIcon.useroutline]: UserOutline,
  [EIcon.moon]: MoonIcon,
  [EIcon.day]: DayIcon,
  [EIcon.list]: ListIcon,
  [EIcon.week]: WeekIcon,
  [EIcon.listrecording]: ListRecording,
  [EIcon.backgroundborrecording]: BackgroundBorRecording,
  [EIcon.arrowleft]: LeftIcon,
  [EIcon.profileuser]: ProfileUser,
  [EIcon.warningfill]: WarningFill,
  [EIcon.userfilled]: UserFilled,
  [EIcon.calendaraddoutline]: CalendarAddOutline,
  [EIcon.checkshieldoutline]: CheckShieldOutline,
  [EIcon.computeroutline]: ComputerOutline,
  [EIcon.crossshieldoutline]: CrossShieldOutline,
  [EIcon.mailstackoutline]: MailStackOutline,
  [EIcon.marketoutline]: MarketOutline,
  [EIcon.useraddedoutline]: UserAddedOutline,
  [EIcon.arrowdown]: DownIcon,
  [EIcon.arrowrightwithsquare]: RightWithSquareIcon,
  [EIcon.arrowdownoutline]: ArrowDownOutline,
  [EIcon.chein]: Chein,
  [EIcon.arrowdowntosquareoutline]: ArrowDownToSquareOutline,
  [EIcon.arrowentersquareoutline]: ArrowEnterSquareOutline,
  [EIcon.arrowleftoutline]: ArrowLeftOutline,
  [EIcon.arrowleftsquareoutline]: ArrowLeftSquareOutline,
  [EIcon.arrowrightcircleoutline]: ArrowRightCircleOutline,
  [EIcon.arrowrightoutline]: ArrowRightOutline,
  [EIcon.arrowrightsquareoutline]: ArrowRightSquareOutline,
  [EIcon.arrowupoutline]: ArrowUpOutline,
  [EIcon.arrowturnleftoutline]: ArrowTurnLeftOutline,
  [EIcon.arrowuprightoutline]: ArrowUpRightOutline,
  [EIcon.arrowuturnleftoutline]: ArrowUturnLeftOutline,
  [EIcon.arrowuturnrightoutline]: ArrowUturnRightOutline,
  [EIcon.chevrondownsmall]: ChevronDownSmall,
  [EIcon.chevronleftoutline]: ChevronLeftOutline,
  [EIcon.chevronleftsmall]: ChevronLeftSmall,
  [EIcon.smallinfo]: SmallInfo,
  [EIcon.menu]: Menu,
  [EIcon.chevronleftsquareoutline]: ChevronLeftSquareOutline,
  [EIcon.chevronrightoutline]: ChevronRightOutline,
  [EIcon.chevronrightsmall]: ChevronRightSmall,
  [EIcon.chevronrightsquareoutline]: ChevronRightSquareOutline,
  [EIcon.chevronupsmall]: ChevronUpSmall,
  [EIcon.cloudarrowupoutline]: CloudArrowUpOutline,
  [EIcon.documentarrowupoutline]: DocumentArrowUpOutline,
  [EIcon.doorarrowleftoutline]: DoorArrowLeftOutline,
  [EIcon.doorarrowrightoutline]: DoorArrowRightOutline,
  [EIcon.downloadcheckoutline]: DownloadCheckOutline,
  [EIcon.downloadcloudoutline]: DownloadCloudOutline,
  [EIcon.downloadcrossbadgeoutline]: DownloadCrossBadgeOutline,
  [EIcon.downloadoutline]: DownloadOutline,
  [EIcon.externallinkoutline]: ExternalLinkOutline,
  [EIcon.foldersimplearrowright]: FolderSimpleArrowRight,
  [EIcon.foldersimplearrowupoutline]: FolderSimpleArrowUpOutline,
  [EIcon.linkcircle]: LinkCircle,
  [EIcon.refreshoutline]: RefreshOutline,
  [EIcon.repeat1outline]: Repeat1Outline,
  [EIcon.repeatoutline]: RepeatOutline,
  [EIcon.replayoutline]: ReplayOutline,
  [EIcon.replyoutline]: ReplyOutline,
  [EIcon.shareexternaloutline]: ShareExternalOutline,
  [EIcon.shareoutline]: ShareOutline,
  [EIcon.shuffleoutline]: ShuffleOutline,
  [EIcon.sortoutline]: SortOutline,
  [EIcon.statisticsoutline]: StatisticsOutline,
  [EIcon.unarchiveoutline]: UnarchiveOutline,
  [EIcon.uploadoutline]: UploadOutline,
  [EIcon.paymentadd]: PaymentAddIcon,
  [EIcon.tab]: TabIcon,
  [EIcon.calendar]: CalendarIcon,
  [EIcon.cart]: CartIcon,
  [EIcon.cartTarif]: CartTarifIcon,
  [EIcon.starTarif]: StarTarifIcon,
  [EIcon.clock]: ClockIcon,
  [EIcon.minusoutline]: MinusOutlineIcon,
  [EIcon.minus]: MinusIcon,
  [EIcon.payoutline]: PayOutlineIcon,
  [EIcon.pay]: PayIcon,
  [EIcon.phone]: PhoneIcon,
  [EIcon.plussquare]: PlusSquareIcon,
  [EIcon.plus]: PlusIcon,
  [EIcon.plusoutline]: PlusOutlineIcon,
  [EIcon.post]: PostIcon,
  [EIcon.procent]: ProcentIcon,
  [EIcon.star]: StarIcon,
  [EIcon.succesoutline]: SuccessOutlineIcon,
  [EIcon.trash]: TrashIcon,
  [EIcon.user]: UserIcon,
  [EIcon.users]: UsersIcon,
  [EIcon.warning]: WarningIcon,
  [EIcon.searchmodal]: SearchModalIcon,
  [EIcon.smalledit]: SmallEditIcon,
  [EIcon.smalltrash]: SmallTrashIcon,
  [EIcon.productsmenu]: ProductsMenuIcon,
  [EIcon.import]: ImportIcon,
  [EIcon.export]: ExportIcon,
  [EIcon.edithuman]: EditHumanIcon,
  [EIcon.analytic]: AnalyticIcon,
  [EIcon.chat]: ChatIcon,
  [EIcon.finans]: FinansIcon,
  [EIcon.home]: HomeIcon,
  [EIcon.management]: ManagementIcon,
  [EIcon.recordingsettings]: RecordingIcon,
  [EIcon.settingprotal]: SettingPortalIcon,
  [EIcon.settings]: SettingsIcon,
  [EIcon.logo]: LogoIcon,
  [EIcon.services2]: Services2,
  [EIcon.logofirst]: LogoFirstIcon,
  [EIcon.file]: FileIcon,
  [EIcon.filial]: FilialIcon,
  [EIcon.ticket]: TicketIcon,
  [EIcon.message]: MessageIcon,
  [EIcon.telegram]: TelegramIcon,
  [EIcon.watsapp]: WhatsUpIcon,
  [EIcon.viber]: ViberIcon,
  [EIcon.phonedop]: PhoneDop,
  [EIcon.switch]: SwitchIcon,
  [EIcon.thumbsdownoutline]: ThumbsDownOutline,
  [EIcon.thumbsupoutline]: ThumbsUpOutline,
  [EIcon.modulesettings]: ModuleSettingsIcon,
  [EIcon.moreverticaloutline]: MoreVerticalOutline,
  [EIcon.importfile]: ImportFileIcon,
  [EIcon.billheadoutline]: BillheadOutline,
  [EIcon.bookspreadoutline]: BookSpreadOutline,
  [EIcon.moneysendoutline]: MoneySendOutline,
  [EIcon.moneyrequestoutline]: MoneyRequestOutline,
  [EIcon.allcategoriesoutline]: AllCategoriesOutline,
  [EIcon.staroutline]: StarOutline,
  [EIcon.close]: Close,
  [EIcon.arrowshapeleft2outline]: ArrowshapeLeft2outline,
  [EIcon.useradded]: UserAdded,
  [EIcon.minusfill]: MinusFill,
  [EIcon.workbench]: Workbench,
  [EIcon.closefill]: CloseFill,
  [EIcon.successfill]: SuccessFill,
  [EIcon.arrowcalendarprev]: ArrowCalendarPrev,
  [EIcon.arrowcalendarnext]: ArrowCalendarNext,
  [EIcon.userdropdown]: UserDropdown,
  [EIcon.supporticon]: SupportIcon,
  [EIcon.deleteicon]: DeleteIcon,
  [EIcon.avitosocial]: AvitoIcon,
  [EIcon.vksocial]: VkIcon,
  [EIcon.newchat]: NewChat,
  [EIcon.filter]: Filter,
  [EIcon.supporticon20]: SupportIcon20,
  [EIcon.telegram20]: TelegramIcon20,
  [EIcon.logotlbn]: LogoTLBN,
  [EIcon.arrowleftoutline48]: ArrowLeftOutline48,
  [EIcon.penedit]: EditPen
};

interface IIconProps {
  name: EIcon;
}

export function IconNew(props: IIconProps) {
  const { name } = props;

  const ChosenIcon = ICONS[name];

  return <ChosenIcon />;
}
