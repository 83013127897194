export const formatMessageDate = (dateString: string) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return '';
  }

  return date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const firstTwoLetters = (chatName: string | undefined): string => {
  return chatName
    ? chatName
        .split(' ')
        .slice(0, 2)
        .map((word: string) => word.charAt(0))
        .join('')
        .toUpperCase()
    : '';
};

export function getColorForSymbol(symbol: string) {
  const colors = ['#fea802', '#fe7434', '#ea515f', '#d94a8c', '#b4418e', '#6fb1a0', '#15a2a2', '#1184a7', '#046dc8', '#0450b4'];

  const index = symbol.charCodeAt(0) % colors.length;

  return colors[index];
}
