import styled, { keyframes } from 'styled-components';

export const MainColumn = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex: 3;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-row-start: 1;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 767px) {
    top: 55px;
    max-height: calc(100% - 38px);
    min-height: auto;
  }
`;
export const ChatsContainer = styled.div`
  min-width: 100%;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
`;
export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-start: 1;
  grid-column-start: 1;
  overflow: hidden;
  align-items: center;
  width: 100%;
  transition: transform 0.2s ease-in-out, filter 0.2s ease-in-out;
`;

export const ChatBackground = styled.div`
  border-radius: 1rem;
  position: absolute;
  inset: 0;
  overflow: hidden;
  user-select: none;
`;

export const layout = keyframes`
  0%{
    transform: translate3d(0,0 , 0)
  }
  100%{
    transform: translate3d(1,1 , 1)
  }
`;

export const MessengerSide = styled.div`
  position: relative;
  transition: 0.3s ease;
  &.open {
    width: 420px;
  }
  &.close {
    width: 0;
  }
`;
