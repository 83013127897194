import styled from 'styled-components';

import { FlexWithAlign } from '../../../../utils/styleUtils';
import { scaleIn } from '../../../shared/modal/create/service/sidebar/CreateServiceSidebar.styled';

export const Header = styled.header`
  margin-bottom: 0;
  gap: 10px;
  position: relative;
  padding: 0 12px;
  min-height: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Info = styled.div`
  position: absolute;
  top: 150%;
  left: -50px;
  animation: ${scaleIn} 0.3s ease;
  padding: 2px 4px;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  line-height: 12px;
  height: 44px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  z-index: 4;
  background: #5b636a;
  &:before {
    content: '';
    width: 11px;
    height: 11px;
    background: #5b636a;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
`;

export const FilialWrapper = styled(FlexWithAlign)`
  padding: 6px 12px;
`;
export const TodayWrapper = styled(FlexWithAlign)`
  p {
    text-transform: capitalize;
  }
`;
