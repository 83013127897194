import styled from 'styled-components';

import { scaleIn } from '../sidebar/CreateServiceSidebar.styled';
import { checkAnim } from '../../../../../../utils/styleUtils';
import {
  DURATION,
  FONT_S,
  HEIGHT_INPUT_M,
  MODAL_GAP,
  MODAL_TEXT_GAP,
  RADIUS_S,
  SHADOW_INPUT,
  SHADOW_INPUT_HOGER
} from '../../../../../../styles';

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  .classificatorWrapper {
    animation: ${scaleIn} 0.3s ease;
  }
  .classificator {
    row-gap: 16px;
  }
  .checked {
    align-items: center;
    justify-content: center;
    p {
      font-size: 16px;
      color: rgba(108, 110, 124, 1);
    }
    .checkbox-container {
      display: inline-block;
      position: relative;
      padding-left: 26px;
      margin-bottom: 19px;
      cursor: pointer;
      font-size: 16px;
      user-select: none;
      .custom-checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &.green:checked ~ .checkmark {
          background-color: ${(props) => props.theme.color.primary};
        }
        &:checked ~ .checkmark {
          background-color: ${(props) => props.theme.color.primary};
        }
        &:checked ~ .checkmark:after {
          display: block;
        }
        &:checked ~ .checkmark:after {
          animation: ${checkAnim} 0.2s forwards;
        }
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border-radius: 4px;
        transition: background-color 0.3s;
        &:after {
          content: '';
          position: absolute;
          display: none;
          left: 7px;
          top: 3px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  .inviteemployeer {
    position: relative;
    width: fit-content;
    display: flex;
    height: ${HEIGHT_INPUT_M};
    border-radius: ${RADIUS_S};
    box-shadow: ${SHADOW_INPUT};
    padding-inline: 1rem;
    align-items: center;
    gap: ${MODAL_TEXT_GAP};
    &:after {
      transition-property: color;
      transition-duration: ${DURATION};
      transition-timing-function: ease-in-out;
      color: ${(props) => props.theme.color.base03};
      position: absolute;
      inset: 0;
      content: '';
      border-radius: 12px;
      border: 1px solid currentColor;
      pointer-events: none;
    }
    &:hover {
      box-shadow: ${SHADOW_INPUT_HOGER};
    }
    &-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      &:checked + h5 {
        color: ${(props) => props.theme.color.base01};
      }
      &:hover + button {
        border-color: ${(props) => props.theme.color.primary};
      }
      &:disabled + button {
        opacity: 0.5;
      }
    }
    span {
      width: 24px;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 24px;
      background: transparent;
      border-radius: ${RADIUS_S};
      transition: background ${DURATION} ease-in-out;
      &:after {
        transition-property: border-color, color;
        transition-duration: ${DURATION};
        transition-timing-function: ease-in-out;
        position: absolute;
        pointer-events: none;
        inset: 0;
        content: '';
        border-radius: inherit;
        border: 1px solid currentColor;
        color: ${(props) => props.theme.color.base04};
      }
      svg {
        color: #fff;
        transition: scale ${DURATION} ease-in-out;
        transform: scale(0);
      }
      &.active {
        background: ${(props) => props.theme.text.link};
        &:after {
          border-color: ${(props) => props.theme.color.primary};
        }
        svg {
          transform: scale(1);
        }
      }
    }
  }
  &.form {
    display: flex;
    flex-direction: column;
    gap: ${MODAL_GAP};
  }
`;

export const FormItem = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  & > div {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const ButtonInner = styled.div`
  display: flex;
  gap: 12px;
`;
export const NameClassifInput = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.mainLight};
  height: 44px;
  display: flex;
  padding-inline: 1rem;
  font: ${FONT_S};
  align-items: center;
`;
export const ColorClassifInput = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.mainLight};
  height: 44px;
  display: flex;
  padding-inline: 1rem;
  font: ${FONT_S};
  gap: ${MODAL_TEXT_GAP};
  align-items: center;
  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    position: relative;
  }
`;

export const ColoredCircle = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  position: relative;
`;
