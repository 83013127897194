import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { fetchMonthDays, getMonday, getMonthFirstAndLastDays } from './calendar.actions';
import { CalendarState } from './calendar.interface';

import { addDateBy, generateDaysArray } from '../../../utils/date-events';

const initialState: CalendarState = {
  activeDate: new Date().toString(),
  financeActiveDate: new Date().toString(),
  chosenWeek: generateDaysArray(getMonday(new Date()), addDateBy(getMonday(new Date()), 6)),
  chosenMonth: generateDaysArray(getMonthFirstAndLastDays(new Date())[0], getMonthFirstAndLastDays(new Date())[1]),
  trigger: false
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setActiveDate: (state, action: PayloadAction<string>) => {
      state.activeDate = action.payload;
    },
    setTrigger: (state, action: PayloadAction<boolean>) => {
      state.trigger = action.payload;
    },
    setWeek: (state, action: PayloadAction<string[]>) => {
      state.chosenWeek = action.payload;
    },
    setFinanceActiveDate: (state, action: PayloadAction<string>) => {
      state.financeActiveDate = action.payload;
    },
    setNextDay: (state) => {
      state.activeDate = dayjs(state.activeDate).add(1, 'day').toISOString();
    },
    setPrevDay: (state) => {
      state.activeDate = dayjs(state.activeDate).subtract(1, 'day').toISOString();
    },
    setFinanceNextDay: (state) => {
      state.financeActiveDate = dayjs(state.financeActiveDate).add(1, 'day').toISOString();
    },
    setFinancePrevDay: (state) => {
      state.financeActiveDate = dayjs(state.financeActiveDate).subtract(1, 'day').toISOString();
    },
    setMonthDays: (state, action: PayloadAction<string[]>) => {
      state.chosenMonth = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMonthDays.fulfilled, (state, action) => {
      state.chosenMonth = action.payload;
    });
  }
});

export const { reducer, actions } = calendarSlice;
export const { setActiveDate, setWeek, setFinanceActiveDate, setNextDay, setPrevDay, setFinanceNextDay, setFinancePrevDay, setMonthDays } =
  actions;
