import { keyframes } from 'styled-components';

export const translateYIn = keyframes`
  0%{
    transform: translateY(30px);
   opacity: .5;
  }
  100%{
   transform: translateY(0);
    opacity: 1;
  }
`;

export const translateYOut = keyframes`
  0%{
    transform: translateY(0);

  }
  100%{
   transform: translateY(30px);
    opacity: 0;
   
  }
`;
export const translateXIn = keyframes`
  0%{
    transform: translateX(30px);
   opacity: .5;
  }
  100%{
   transform: translateY(0);
    opacity: 1;
  }
`;

export const translateXOut = keyframes`
  0%{
    transform: translateX(0);

  }
  100%{
   transform: translateY(30px);
    opacity: 0;
   
  }
`;

export const opacity = keyframes`
  0%{
    opacity: 0;

  }
  100%{
    opacity: 1;
  }
`;

export const fadeIn = keyframes`
  0%{
    transform: scale(0);
  }

  80%{
    transform: scale(0);
  }
  100%{
    transform: scale(1);
  }
`;

export const retrigger = keyframes`
  0%{
     left: 1px;
  }
  
  100%{
    left: 2px
  }
`;
