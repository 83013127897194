import * as authActions from './auth/auth.actions';
import * as cabinetsActions from './cabinets/cabinets.actions';
import { calendarSlice } from './calendar/calendar.slice';
import { callRecordsSlice } from './call-records/callRecords.slice';
import * as classificatorsActions from './classificators/classificators.actions';
import * as clientsActions from './clients/clients.actions';
import * as filialActions from './filial/filial.actions';
import * as financeActions from './finance/finance.actions';
import * as lessonsActions from './lessons/lessons.actions';
import { modalSlice } from './modal/modal.slice';
import * as notesActions from './notes/notes.actions';
import * as paymentsActions from './payments/payments.actions';
import * as productsAction from './products/product.actions';
import { recordingSlice } from './recording/recording.slice';
import * as saleproductsAction from './saleproducts/saleproducts.actions';
import * as salesActions from './sales/sales.actions';
import { sidebarSlice } from './sidebar/sidebar.slice';
import * as subproductsActions from './subproducts/subproducts.actions';
import * as tarifActions from './tarif/tarif.actions';
import * as tasksActions from './tasks/tasks.actions';
import * as userActions from './user/user.actions';
import * as chatActions from './websocket/chat-reducer';
import { chatSlice } from './websocket/chat-slice';

export const allActions = {
  ...calendarSlice.actions,
  ...recordingSlice.actions,
  ...callRecordsSlice.actions,
  ...modalSlice.actions,
  ...sidebarSlice.actions,
  ...chatSlice.actions,
  ...paymentsActions,
  ...notesActions,
  ...classificatorsActions,
  ...authActions,
  ...userActions,
  ...filialActions,
  ...subproductsActions,
  ...clientsActions,
  ...cabinetsActions,
  ...financeActions,
  ...lessonsActions,
  ...tasksActions,
  ...productsAction,
  ...saleproductsAction,
  ...salesActions,
  ...tarifActions,
  ...chatActions
};
