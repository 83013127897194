import { ProductState } from './products.interface';
import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';
import { createSlice } from '@reduxjs/toolkit';
import { fetchProductsAsync } from './product.actions';

const initialState: ProductState = {
  products: [],
  state: PENDING
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchProductsAsync.fulfilled, (state, action) => {
        state.products = action.payload?.sort((a: { order: number }, b: { order: number }) => (a.order > b.order ? 1 : -1));
        state.state = FULFILLED;
      })
      .addCase(fetchProductsAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer } = productsSlice;
