import React, { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useSubmenuData } from './sidebar.data';
import { useMobileSubmenuData } from './sidebar.mobile.data';
import {
  BottomTab,
  Burger,
  Container,
  Head,
  Icon,
  Line,
  LogoLink,
  MenuItem,
  MenuLink,
  MenuSubMenu,
  MobileTabs,
  PortalLinkInner,
  Wrapper
} from './Sidebar.styled';

import { cn } from '../../../shared/lib';
import { useActions } from '../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../shared/lib/hooks/useMatchMedia';
import { useTypedSelector } from '../../../shared/lib/hooks/useTypedSelector';
import { Portal, Text, TextPType, TextType } from '../../../shared/ui';
import CommonButton from '../../../shared/ui/Button/ui/CommonButton';
import { CircleWrapper } from '../../../styles/circles.styled';
import { getCookie } from '../../../utils/cookies';
import { FlexWithAlign } from '../../../utils/styleUtils';
import { EIcon, IconNew as IconInstance } from '../../icons/medium-new-icons/icon';
import { sendGetRecordsRequest, startMessagesListening, stopMessagesListening } from '../../../store/redux/websocket/chat-reducer';
import { useDispatch } from 'react-redux';
import { useFilialQuery } from '../../../store/redux/filial/hooks/useFilialQuery';

interface ISidebarProps extends PropsWithChildren {
  className?: string;
}

const Sidebar: React.FC<ISidebarProps> = ({ className }) => {
  const { data: activeFilial, isLoading: filialLoading } = useFilialQuery();
  const { setActiveDate, setActiveFilial } = useActions();
  const { submenu } = useSubmenuData();
  const location = useLocation();
  const { submenuMobile } = useMobileSubmenuData();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeTarif } = useTypedSelector((state) => state.tarif);
  const { profileUser, state: userState } = useTypedSelector((state) => state.user);
  const { websocketroomId, userChats } = useTypedSelector((state) => state.chat);
  const { fetchUserByIdAsync, setIspathname } = useActions();
  const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') === 'open' ? true : false);
  const [isTablet, isTabletLandscape] = useMatchMedia(['(min-width: 768px)', '(min-width: 900px)']);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const [isDesktop] = useMatchMedia(['((min-width: 901px))']);
  const [isMiniLaptop] = useMatchMedia(['((max-width: 1024px))']);
  const userRole = profileUser.role;
  const isTechSupport = profileUser?.Email === 'hello@telebon.ru' && activeFilial?.name === 'Telebon';
  let totalUnread = 0;

  const fetchUserInfo = async () => {
    await fetchUserByIdAsync(getCookie('id'));
  };

  const isLinkActive = (to: string, pathname: string) => {
    const toPath = to.split('/')[1];
    const locationPath = pathname.split('/')[1];

    return toPath === locationPath;
  };

  const onToggle = () => {
    setCollapsed((prev) => !prev);
    localStorage.setItem('collapsed', collapsed ? 'close' : 'open');
  };

  userChats?.forEach((chat) => {
    totalUnread += chat.unread;
  });

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (isMiniLaptop) {
      setCollapsed(true);
      localStorage.setItem('collapsed', collapsed ? 'close' : 'open');
    } else {
      setCollapsed(false);
      localStorage.setItem('collapsed', collapsed ? 'open' : 'close');
    }
  }, [isMiniLaptop]);

  useEffect(() => {
    setActiveFilial(activeFilial?.id || '');
  }, [filialLoading]);

  useEffect(() => {
    dispatch(startMessagesListening(profileUser?.fio) as any);
    if (profileUser?.role) {
      dispatch(sendGetRecordsRequest({ filialId: activeFilial?.id || '', filialName: activeFilial?.name || '', userRole }) as any);
    }

    return () => {
      dispatch(stopMessagesListening() as any);
    };
  }, [userState, filialLoading]);

  return (
    <>
      {isDesktop && (
        <Container className={cn(collapsed && 'collapsed', className ? className : '')}>
          <Wrapper>
            <Head className='flex'>
              {isMiniLaptop ? (
                <div className={'logotlbn'}>
                  <IconInstance name={EIcon.logotlbn} />
                </div>
              ) : (
                <>
                  {(isDesktop || isTablet) && (
                    <CommonButton
                      onlyIcon
                      typeBtn={'gray'}
                      size={'S'}
                      className={cn(collapsed && 'collapsed')}
                      onClick={onToggle}
                    >
                      <IconInstance name={EIcon.menu} />
                    </CommonButton>
                  )}
                  {isDesktop ||
                    (isTablet && (
                      <Burger
                        className={cn(collapsed && 'collapsed')}
                        onClick={onToggle}
                      >
                        <div className='line first'></div>
                        <div className='line middle'></div>
                        <div className='line last'></div>
                      </Burger>
                    ))}
                  <IconInstance name={EIcon.logo} />
                </>
              )}
              <LogoLink to={'/products/employee/calendar'} />
            </Head>

            <MenuSubMenu>
              {activeTarif && activeTarif.name === 'Free'
                ? submenu.map(({ title, click, icon, mark, to, endTab }) => (
                    <Fragment key={title}>
                      <MenuItem
                        $align={'center'}
                        $gap={'8px'}
                        className={cn(isLinkActive(to, location.pathname) && 'active', 'flex', !click && 'disable')}
                      >
                        {!click ? null : title === 'Финансы' && profileUser && profileUser.role === 'manager' ? null : (
                          <MenuLink
                            onClick={() =>
                              to === '/products/cabinets' &&
                              location.pathname !== '/products/cabinets' &&
                              setActiveDate(new Date().toISOString())
                            }
                            className='flex'
                            to={to}
                          />
                        )}
                        <Icon className={cn(isLinkActive(to, location.pathname) && 'active')}>{icon}</Icon>
                        {!collapsed && (
                          <Text
                            $tag={TextType.P}
                            $pSize={TextPType.P13}
                          >
                            {t(title)}
                          </Text>
                        )}
                        {mark && isTechSupport && (
                          <div className={'message-unread'}>
                            <Text
                              $tag={TextType.P}
                              $pSize={TextPType.P13}
                              color={'base01'}
                              fontWeight={600}
                            >
                              {totalUnread}
                            </Text>
                          </div>
                        )}
                      </MenuItem>
                      {endTab && !isMiniLaptop && <Line />}
                    </Fragment>
                  ))
                : submenu.map(({ title, click, icon, to, endTab, mark }) => (
                    <Fragment key={title}>
                      <MenuItem
                        $align={'center'}
                        $gap={'8px'}
                        className={cn(isLinkActive(to, location.pathname) && 'active', 'flex', !click && 'disable')}
                      >
                        {!click ? null : title === 'Финансы' && profileUser && profileUser.role === 'manager' ? null : (
                          <MenuLink
                            onClick={() =>
                              to === '/products/cabinets' &&
                              location.pathname !== '/products/cabinets' &&
                              setActiveDate(new Date().toISOString())
                            }
                            className='flex'
                            to={to}
                          />
                        )}
                        <Icon className={cn(isLinkActive(to, location.pathname) && 'active')}>{icon}</Icon>
                        {!collapsed && (
                          <Text
                            $tag={TextType.P}
                            $pSize={TextPType.P13}
                          >
                            {t(title)}
                          </Text>
                        )}
                        {mark && isTechSupport && totalUnread !== 0 && (
                          <div className={'message-unread'}>
                            <Text
                              $tag={TextType.P}
                              $pSize={TextPType.P13}
                              color={'base01'}
                              fontWeight={600}
                            >
                              {totalUnread}
                            </Text>
                          </div>
                        )}
                      </MenuItem>
                      {endTab && !isMiniLaptop && <Line />}
                    </Fragment>
                  ))}
            </MenuSubMenu>
          </Wrapper>
          <PortalLinkInner
            $gap={'12px'}
            className={cn(
              isLinkActive('/portalsettings/tarif', location.pathname) && 'active',
              profileUser && profileUser.role === 'admin' ? 'flex' : 'flex disabled',
              'portal'
            )}
          >
            <MenuLink to={`${profileUser && profileUser.role === 'admin' ? '/portalsettings/tarif' : '#'}`} />
            <CircleWrapper
              $background={'thinBlue'}
              size={'32'}
            >
              <IconInstance name={EIcon.logomarkwhite} />
            </CircleWrapper>
            {!collapsed && (
              <FlexWithAlign
                $column
                $gap={'0'}
              >
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P13}
                  color={'primary'}
                >
                  {t('Настройки портала')}
                </Text>
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P11}
                  className={'colored'}
                >
                  {t('Центр поддержки')}
                </Text>
              </FlexWithAlign>
            )}
          </PortalLinkInner>
        </Container>
      )}
      {isMobile && (
        <Portal>
          <MobileTabs
            className={cn(
              (location.pathname === `/messenger-list/${websocketroomId}` ||
                location.pathname === `/messenger-list/telebot` ||
                location.pathname === `/messenger-list/support`) &&
                'hidden'
            )}
          >
            {submenuMobile.map((item, index) => (
              <BottomTab key={index}>
                <MenuLink
                  onClick={() => {
                    item.to === '/products/cabinets' &&
                      location.pathname !== '/products/cabinets' &&
                      setActiveDate(new Date().toISOString());
                    setIspathname(location.pathname);
                  }}
                  to={item.to}
                />
                {item.mark && isTechSupport && totalUnread !== 0 && (
                  <div className={'message-unread'}>
                    <Text
                      $tag={TextType.P}
                      $pSize={TextPType.P11}
                      color={'base01'}
                      fontWeight={600}
                    >
                      {totalUnread}
                    </Text>
                  </div>
                )}
                <Icon>{isLinkActive(item.to, location.pathname) ? item.icon : item.iconGray}</Icon>
                <Text
                  className={cn(isLinkActive(item.to, location.pathname) && 'active')}
                  $tag={TextType.P}
                  $pSize={TextPType.P11}
                  small
                >
                  {item.title}
                </Text>
              </BottomTab>
            ))}
          </MobileTabs>
        </Portal>
      )}
    </>
  );
};

export default Sidebar;
