import styled from 'styled-components';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';
import { darken } from 'polished';

export const PaymentModalConfirm = styled.div`
  width: 100%;
  gap: 30px;
  display: flex;
  flex-direction: column;
  > div {
    width: 100%;
  }
  .grid {
    display: flex;
    align-items: center;
  }
  .backgroundGray {
    background: ${(props) => props.theme.color.base02};
    height: 50px;
    border-radius: 10px;
    color: ${(props) => props.theme.color.mainDarkFull};
    padding: 0 15px;
    width: 100%;
    > p {
      font-size: 13px;
      font-weight: 500;
    }
  }
  .group-pays-table {
    width: 100%;
    border-radius: 2px;
    position: relative;
    padding: 0;
    &.middle {
      padding: 0 0 2px 0;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      .deleteIcon,
      .successIcon {
        cursor: pointer;
        svg {
          transition: 0.3s ease;
          color: ${(props) => props.theme.color.mainDark};
          width: 10px;
          height: 10px;
        }
        &:hover {
          svg {
            transform: scale(1.2);
          }
        }
      }
      .successIcon {
        svg {
          color: ${(props) => props.theme.color.success};
          width: 17px;
          height: 17px;
        }
      }
    }
  }
  .gridFive {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr 1fr 0.5fr;
    width: 100%;
    color: #000;
    font-weight: 500;

    > div {
      display: flex;
      justify-content: center;
      &:nth-child(4) {
        justify-content: flex-end;
        width: 100px;
        cursor: pointer;
        transition: 0.3s ease;
        align-items: center;
        &:hover {
          color: ${(props) => props.theme.color.mainLight};
        }
      }
      &:nth-child(3) {
        justify-content: flex-end;
        cursor: pointer;
        transition: 0.3s ease;
        align-items: center;
        &:hover {
          color: ${(props) => props.theme.color.mainLight};
        }
      }
      &:nth-child(2) {
        justify-content: flex-end;
      }
    }
    &.middle {
      color: ${(props) => props.theme.color.mainDark};
      padding: 6px 16px;
      align-items: center;
      background: ${(props) => props.theme.color.base02};
      font-size: 13px;
      .indicator {
        span {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background: ${(props) => props.theme.color.secondaryLightMedium};
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }
          &.group {
            &:before {
              background: ${(props) => props.theme.color.mainLight};
            }
          }
          &.single {
            &:before {
              background: ${(props) => props.theme.color.accent};
            }
          }
        }
      }
      input {
        border: none;
        background: transparent;
        width: 66px;
      }
      > div {
        &:first-child {
          justify-content: flex-start;
        }
        &:nth-child(2) {
          justify-content: flex-start;
        }

        &:last-child {
          justify-content: center;
          align-items: center;
        }
      }
    }
    &.bottom {
      font-size: 13px;
    }
  }
  .gray {
    background: #ecf1f7;
    transition: 0.3s ease;
    font-size: 13px;
    font-weight: 500;
    height: 50px;
    border-radius: 10px;
    &:hover {
      background: ${(props) => props.theme.color.secondaryLightMedium};
    }
  }
  .bottom {
    font-size: 13px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 57px;
  }
`;

export const InputWrapper = styled(FlexWithAlign)`
  //max-width: 482px;
  width: 100%;
  .selectedService {
    width: 100%;
    .css-1jqq78o-placeholder {
      display: block !important;
      color: ${(props) => props.theme.color.mainDarkFull};
      font-weight: 500;
      font-size: 13px;
    }
    .css-1fdsijx-ValueContainer {
      .css-1dimb5e-singleValue {
        &:before {
          content: 'Добавить услугу';
          position: absolute;
          display: flex !important;
          align-items: center;
          z-index: 2;
          height: 100%;
          font-size: 13px !important;
          color: ${(props) => props.theme.color.mainDarkFull};
          background: #fff;
          width: 100%;
          text-align: left;

          font-weight: 500;
          left: 0;
        }
      }
    }
    .custom-select__control--menu-is-open {
      .css-1fdsijx-ValueContainer {
        .css-1dimb5e-singleValue {
          &:before {
            background: #fff !important;
          }
        }
      }
    }
    .filled {
      .css-1fdsijx-ValueContainer {
        .css-1dimb5e-singleValue {
          &:before {
            background: #ecf1f7;
          }
        }
      }
    }
    &.selectedService2 {
      .css-1jqq78o-placeholder {
      }
      .css-1fdsijx-ValueContainer {
        .css-1dimb5e-singleValue {
          &:before {
            content: 'Сотрудник';
          }
        }
      }
    }
  }
`;

export const EmployeeTableItem = styled(FlexWithAlign)`
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      width: 10px;
      height: 10px;
      position: relative;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  &.end {
    width: 100%;
    justify-content: flex-end;
  }
`;
export const DinamycWrapper = styled.div`
  width: 100%;
  > div {
    overflow: visible !important;
  }
`;
export const GroupModalPageOne = styled.div`
  width: 100%;
  gap: 30px;
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
  }
  .grid {
    display: flex;
    align-items: center;
  }
`;
export const GroupTableContent = styled(FlexWithAlign)`
  flex-direction: column;
  gap: 2px;
  .employee {
    display: flex;
    flex-direction: column;
    gap: 8px;
    > div {
      background: ${(props) => props.theme.color.base02};
      display: flex;
      align-items: center;
      height: 34px;
      color: ${(props) => props.theme.color.mainDarkFull};
      padding: 0 15px;
      width: 100%;
      p {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
  .grid {
    .totalPrice {
      font-weight: 500;
      font-size: 13px;
      margin-right: 20px;
      margin-left: auto;
    }
  }
  .gridWrapper {
    width: 100%;
    border-radius: 2px;
    position: relative;
    padding: 0;
    &.middle {
      padding: 0 0 2px 0;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      .deleteIcon,
      .successIcon {
        cursor: pointer;
        svg {
          transition: 0.3s ease;
          color: ${(props) => props.theme.color.mainDark};
          width: 10px;
          height: 10px;
        }
        &:hover {
          svg {
            transform: scale(1.2);
          }
        }
      }
      .successIcon {
        svg {
          color: ${(props) => props.theme.color.success};
          width: 17px;
          height: 17px;
        }
      }
    }
  }
  .gridFive {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr 1fr 0.5fr;
    width: 100%;
    color: #000;
    font-weight: 500;

    > div {
      display: flex;
      justify-content: center;
      &:nth-child(4) {
        justify-content: flex-end;
        width: 100px;
        cursor: pointer;
        transition: 0.3s ease;
        align-items: center;
        &:hover {
          color: ${(props) => props.theme.color.mainLight};
        }
      }
      &:nth-child(3) {
        justify-content: flex-end;
        cursor: pointer;
        transition: 0.3s ease;
        align-items: center;
        &:hover {
          color: ${(props) => props.theme.color.mainLight};
        }
      }
      &:nth-child(2) {
        justify-content: flex-end;
      }
    }
    &.middle {
      color: ${(props) => props.theme.color.mainDark};
      padding: 6px 16px;
      align-items: center;
      background: ${(props) => props.theme.color.base02};
      font-size: 13px;
      .indicator {
        span {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background: ${(props) => props.theme.color.secondaryLightMedium};
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }
          &.group {
            &:before {
              background: ${(props) => props.theme.color.mainLight};
            }
          }
          &.single {
            &:before {
              background: ${(props) => props.theme.color.accent};
            }
          }
        }
      }
      input {
        border: none;
        background: transparent;
        width: 66px;
      }
      > div {
        &:first-child {
          justify-content: flex-start;
        }
        &:nth-child(2) {
          justify-content: flex-start;
        }

        &:last-child {
          justify-content: center;
          align-items: center;
        }
      }
    }
    &.bottom {
      font-size: 13px;
    }
  }
`;
export const Table = styled(FlexWithAlign)`
  height: auto;
  width: 100%;
  transition: 0.3s ease;
  border-radius: 8px;
  cursor: pointer;
  .header {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 0.5fr;
    justify-items: start;
    div {
      position: relative;

      p {
        span {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
  .header {
    background: rgba(237, 241, 255, 1);
    padding: 16px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      color: ${(props) => props.theme.color.mainLight};
      font-weight: 600;
      text-align: center;
    }
    border-radius: 8px;
  }
  .gridWrapper {
    width: 100%;
    border-radius: 2px;
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &.middle {
      padding: 0 0 2px 0;
      align-items: center;
      margin-bottom: 5px;
      .deleteIcon,
      .successIcon {
        cursor: pointer;
        svg {
          transition: 0.3s ease;
          color: ${(props) => props.theme.color.mainDark};
          width: 10px;
          height: 10px;
        }
        &:hover {
          svg {
            transform: scale(1.2);
          }
        }
      }
      .successIcon {
        svg {
          color: ${(props) => props.theme.color.success};
          width: 17px;
          height: 17px;
        }
      }
    }
  }
  .gridFive {
    display: grid;
    grid-template-columns: 5fr 0.1fr 0.1fr;
    width: 100%;
    color: #000;
    font-weight: 500;
    .paymentComplete {
      > div {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 50%;
        svg {
          width: 12px;
          height: 12px;
        }
        &.activeY {
          svg {
            color: ${(props) => props.theme.color.success};
          }
        }
        &.activeN {
          svg {
            color: ${(props) => props.theme.color.danger};
          }
        }
      }
    }
    > div {
      display: flex;
      justify-content: center;
      &:nth-child(4) {
        justify-content: flex-end;
        width: 80px;
        cursor: pointer;
        transition: 0.3s ease;
        align-items: center;
        &:hover {
          color: ${(props) => props.theme.color.mainLight};
        }
      }
      &:nth-child(2) {
        justify-content: flex-end;
      }
    }
    &.middle {
      color: ${(props) => props.theme.color.mainDark};
      padding: 6px 16px;
      align-items: center;
      transition: 0.3s ease;
      &:hover {
        background: ${(props) => darken(0.1, props.theme.color.secondaryMediumLight)};
        padding-right: 26px;
      }
      background: ${(props) => props.theme.color.base02};
      font-size: 13px;
      .indicator {
        span {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background: ${(props) => props.theme.color.secondaryLightMedium};
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }
          &.group {
            &:before {
              background: ${(props) => props.theme.color.mainLight};
            }
          }
          &.single {
            &:before {
              background: ${(props) => props.theme.color.accent};
            }
          }
        }
      }
      input {
        border: none;
        background: transparent;
        width: 66px;
      }
      > div {
        &:first-child {
          justify-content: flex-start;
        }
        &:nth-child(2) {
          justify-content: flex-start;
        }

        &:last-child {
          justify-content: center;
          align-items: center;
        }
      }
    }
    &.bottom {
      font-size: 13px;
    }
  }
  .gridContent {
    padding: 6px 12px;
    display: grid;
    grid-template-columns: 2fr 1fr 0.3fr 0.3fr;
    align-items: center;
    > div {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      p {
        &.opacity {
          opacity: 0.5;
        }
      }
    }
    .client {
      align-items: center;
    }
    .confirmation {
      display: flex;
      align-items: flex-start;
      justify-content: start;
      div {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 8px;
        &.activeY {
          color: ${(props) => props.theme.color.mainLight};
          svg {
            color: ${(props) => props.theme.color.mainLight};
          }
        }
        &.activeN {
          color: ${(props) => props.theme.color.mainLight};
          svg {
            color: ${(props) => props.theme.color.mainLight};
          }
        }
        svg {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .paymentComplete {
      display: flex;
      align-items: center;
      justify-content: start;
      div {
        padding: 2px 4px;
        border-radius: 4px;
        font-weight: 600;
        p {
          font-size: 12px;
        }
        &.activeY {
          background: ${(props) => props.theme.color.success};
          color: ${(props) => props.theme.color.base01};
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.activeN {
          background: ${(props) => props.theme.color.danger};
          color: ${(props) => props.theme.color.base01};
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

export const GroupModalScreenSecondWrapper = styled(FlexWithAlign)`
  .backgroundGray {
    background: ${(props) => props.theme.color.base02};
    color: ${(props) => props.theme.color.mainDarkFull};
    padding: 0 15px;
    height: 50px;
    border-radius: 10px;
    p {
      font-size: 13px;
      font-weight: 500;
    }
  }
`;
