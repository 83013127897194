import { Link, NavLink } from 'react-router-dom';

import styled from 'styled-components';

import { RADIUS_S, Z_INDEX } from '../../../styles';
import { FlexWithAlign } from '../../../utils/styleUtils';
import { scaleIn } from '../../shared/modal/create/service/sidebar/CreateServiceSidebar.styled';

export const Container = styled.div`
  z-index: 5;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-width: 230px;
  max-width: 230px;
  grid-area: sidebar;
  position: sticky;
  top: 0;
  height: 100vh;
  transition: 0.3s ease;
  &.with-expire-tarif {
    height: fit-content;
    min-height: calc(100vh - 50px);
  }
  @media (max-width: 767px) {
    &.with-expire-tarif {
      min-height: calc(100vh - 70px);
    }
  }
  &.collapsed {
    min-width: 60px;
    max-width: 60px;
  }
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    animation: ${scaleIn} 0.25s forwards ease-in-out;
    transform-origin: left;
  }
  @media (max-width: 1024px) {
    max-width: 175px;
    min-width: 175px;
  }
`;

export const Wrapper = styled.div`
  padding: 0;
  flex: 1 0 auto;
`;

export const MenuSubMenu = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 2px;
  @media (max-width: 1500px) {
    gap: 0;
  }
`;

export const Head = styled.div`
  margin-bottom: 20px;
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 13px;
  gap: 10px;
  .logotlbn {
    svg {
      width: 50px;
    }
  }
  button {
    z-index: ${Z_INDEX};
    border-radius: ${RADIUS_S};
  }
  > div {
    h3 {
      text-transform: uppercase;
      position: relative;
      width: 130px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

export const LogoLink = styled(Link)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

export const MenuItem = styled(FlexWithAlign)`
  max-width: calc(100% - 8px);
  position: relative;
  margin-left: 10px;
  width: 100%;
  border-radius: 8px;
  padding: 10px 12px;
  .message-unread {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    right: 10px;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.color.accent};
  }
  &.disable {
    color: rgba(148, 151, 168, 1);
    &:hover {
      svg {
        color: rgba(148, 151, 168, 1);
      }
      cursor: no-drop;
      color: rgba(148, 151, 168, 1);
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    animation: ${scaleIn} 0.3s ease;
    z-index: -1;
    border-radius: 0 20px 20px 0;
    left: -9px;
    width: 2px;
    height: 100%;
    transition: 0.3s ease;
  }
  &:hover {
    background: ${(props) => props.theme.color.base03};
  }
  &.active {
    background: ${(props) => props.theme.color.base03};
    &:before {
      background: ${(props) => props.theme.color.accent};
    }
    &:hover {
      &:before {
        background: ${(props) => props.theme.color.accent} !important;
      }
    }
  }
`;

export const MenuLink = styled(NavLink)`
  font-weight: 500;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const PortalLinkInner = styled(FlexWithAlign)`
  padding-inline-start: 20px;
  padding-bottom: 20px;
  flex: 0 0 auto;
  &.disable {
    color: rgba(148, 151, 168, 1);
    &:hover {
      svg {
        color: rgba(148, 151, 168, 1);
      }
      cursor: no-drop;
      color: rgba(148, 151, 168, 1);
    }
  }
  .colored {
    color: ${(props) => props.theme.text.text02};
  }
`;

export const Line = styled.span`
  position: relative;
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background: ${(props) => props.theme.color.base03};
  @media (max-width: 1500px) {
    margin: 10px 0;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 24px;
  height: 24px;
  svg {
    filter: grayscale(80%);
  }
  &.active {
    svg {
      filter: grayscale(0);
      color: ${(props) => props.theme.text.text01};
    }
  }
  &.portal {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Title = styled.div`
  font-weight: 500;
  padding-left: 10px;
  font-size: 13px;
  &.portal {
    font-weight: 700;
    padding-left: 10px;
    font-size: 20px;
  }
`;

export const MobileTabs = styled.div`
  height: 80px;
  border-top: 1.5px solid ${(props) => props.theme.color.base03};
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${(props) => props.theme.color.base01};
  z-index: 9;
  position: fixed;
  padding: 7px 5px 0 5px;
  justify-content: space-between;
  display: flex;
  margin: 0 auto;
  align-items: start;

  &.hidden {
    display: none;
  }
`;
export const BottomTab = styled(FlexWithAlign)`
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 76px;
  max-width: 76px;
  gap: 5px;
  .message-unread {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: flex;
    right: 10px;
    top: 0;
    z-index: 1;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.color.accent};
    p {
      color: ${(props) => props.theme.color.base01};
    }
  }
  > div {
    svg {
      filter: grayscale(0);
      width: 28px;
      height: 28px;
    }
  }
  p {
    color: #999999;
  }
  p.active {
    filter: grayscale(0);
    color: ${(props) => props.theme.text.link};
  }
`;

export const Burger = styled.div`
  cursor: pointer;
  width: fit-content;
  position: relative;
  z-index: 4;
`;
