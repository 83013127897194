import styled from 'styled-components';

import themelight from '../../../../../app/providers/ThemeProvider/ui/theme';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { DURATION } from '../../../../../styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .employee-dropdown {
    height: 44px;
    min-height: auto;
    border-radius: 20px;
    &::after {
      border-radius: 20px;
      border: none;
    }
    .custom-select__value-container {
      padding: 0 0 0 1rem;
    }
    .custom-select__placeholder {
      display: block;
    }
    .custom-select__indicators {
      opacity: 1;
    }
    .custom-select__indicator {
      padding: 4px;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      color: ${themelight.color.sidebarMedium};
      svg {
        width: 14px;
      }
    }
    .additionalSvg {
      position: absolute;
      top: 53%;
      left: 22px;
      transform: translateY(-50%);
    }
  }
  .filled div.custom-select__control.css-13cymwt-control {
    border: none !important;
  }
  .additionalSvg svg {
    left: 24px;
  }
  .custom-select__control {
    padding: 10px 20px;
    border-radius: 20px;
    height: 44px;
    min-height: auto;
    box-shadow: none;
    background-color: ${themelight.color.base02} !important;
    &::after {
      border-radius: 20px;
    }
  }
  .custom-select__single-value {
    font-size: 15px;
    line-height: 24px;
  }
  .additionalHidden span {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px; /* 200% */
  }
`;

export const MobileAbsoluteDropdown = styled.div`
  //width: calc(100% - 2rem);
  margin: 0 auto;
  position: relative;
  height: 100%;
  max-height: 100%;

  //top: 50px;
  //z-index: 6;
  > div {
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: ${(props) => props.theme.text.text01};
      }
    }
  }
  &.padding {
    width: 100%;
  }
  &.padding-bottom {
    padding-bottom: 50px;
  }
`;
export const MobileSheetTable = styled(FlexWithAlign)`
  position: relative;
  gap: 0;
  > div {
    display: flex;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 0;
    border-bottom: 1px solid ${(props) => props.theme.color.base04};
    padding-block: 0.5rem;
  }
`;
export const MobileCenter = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;
export const Content = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  // overflow: hidden;
  padding: 0 40px;
  @media (max-width: 767px) {
    padding: 0 1rem 80px 1rem;
  }
  &::after {
    content: '';
    position: absolute;
    top: 64px;
    left: 40px;
    right: 40px;
    height: 1px;
    background: ${(props) => props.theme.color.lightBg};
    z-index: 10;
    @media (max-width: 767px) {
      content: none;
    }
  }
`;

export const EmployeeAddBtn = styled(CommonButton)`
  // transform: translateY(-2px);
  // & + button {
  //   transform: translateY(-2px);
  // }
`;
export const EmployeeWrapper = styled.div`
  position: relative;
  padding-top: 85px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  max-width: 329px;
  min-width: 329px;
  flex-shrink: 0;
  &::after {
    content: '';
    position: absolute;
    top: 64px;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.color.lightBg};
    width: 1px;
    @media (max-width: 767px) {
      content: none;
    }
  }

  .employee-skeleton {
    span {
      height: 48px;
    }
  }
  @media (max-width: 1024px) {
    max-width: 250px;
    min-width: 250px;
  }
  @media (max-width: 767px) {
    max-width: 42px;
    min-width: 42px;
    padding-top: 54px;
    padding-right: 0;
    .employee-skeleton {
      gap: 4px;
      span {
        height: 64px;
      }
    }
  }
`;

export const EmployeeList = styled.ul`
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: 767px) {
    width: 42px;
    padding: 0;
    gap: 10px;
  }
`;

export const EmployeeItem = styled.li`
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  > div {
    svg {
      width: 24px;
      height: 24px;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 2px;
    height: 58px;
    gap: 0;
    p {
      max-width: 64px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .name {
    font-size: 15px;
    line-height: 24px;
  }
  .position {
    font-size: 13px;
    line-height: 20px;
    color: rgba(27, 31, 59, 0.65);
  }
  .avatar {
    width: 48px;
    height: 48px;
    background-color: rgba(112, 182, 246, 0.12);
    border-radius: 50%;
  }
  .name-wrap {
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      max-width: 34px;
      > p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 500;
      }
    }
  }
`;

export const ScheduleWrap = styled.div`
  padding: 0 0 10px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  z-index: 5;
  gap: 10px;
  .schedule-skeleton-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .schedule-skeleton {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;
    padding: 0 7.5px;
    span {
      height: 58px;
      width: 46px;
      border-radius: 8px;
      flex-shrink: 0;
    }
  }
  @media (max-width: 767px) {
    .schedule-skeleton-wrap {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .schedule-skeleton {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 4px;

      span {
        height: 58px;
        width: 46px;
        border-radius: 8px;
        flex-shrink: 0;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6.25rem;
    border: 0.25rem solid transparent;
    opacity: 0.2;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.color.grayD7};
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.color.grayD7};
  }
  &:hover::-webkit-scrollbar-track {
    background: transparent;
  }

  &:hover {
    --fix: ;
  }

  &.open-modal {
    width: calc(82% - 388px);
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .react-calendar {
    max-width: 100%;
    padding-top: 85px;
    @media (max-width: 767px) {
      padding-top: 0px;
    }
  }

  .react-calendar__viewContainer {
    padding: 0;
  }

  .react-calendar__navigation {
    position: absolute;
    top: 0;
    left: 40px;
    padding: 0 16px 24px 0;
    border-radius: 8px;
    gap: 16px;
    background-color: transparent;
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  .react-calendar__navigation__label {
    flex-grow: 0;
  }

  //.react-calendar__navigation__arrow svg {
  //  width: 24px;
  //  height: 24px;
  //}
  .react-calendar__month-view__weekdays {
    display: none !important;
  }
  .react-calendar__month-view__days {
    gap: 0;
    flex-wrap: nowrap !important;
    & button:first-child {
      margin-inline: 0 !important;
    }
    & > button.react-calendar__month-view__days__day {
      flex-direction: column;
      padding: 7px;
      width: 61px;
      height: 48px;
      max-width: 61px;
      min-width: 61px;
      max-height: 44px;
      background: transparent;
      border-radius: 8px;
      @media (max-width: 767px) {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
      }
      &:hover,
      &:focus {
        background: rgba(255, 127, 65, 0.5);
      }
      &.react-calendar__tile--now {
        background: rgba(255, 127, 65, 0.5);
        @media (max-width: 767px) {
          background: transparent;
        }
        abbr {
          border: none;
        }
      }
      abbr {
        display: none;
      }
      span {
        text-align: center;
        font-size: 13px;
        line-height: 20px;

        &.day {
          font-weight: 500;
          @media (max-width: 767px) {
            color: ${(props) => props.theme.text.text01};
            &.prev_day {
              color: #c5c5c5e5;
            }
          }
        }
        &.weekday {
          color: rgba(27, 31, 59, 0.65);
          text-transform: initial;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.4000000059604645px;
          text-transform: capitalize;
          @media (max-width: 767px) {
            color: ${(props) => props.theme.text.text01} !important;
            &.prev_day {
              color: #c5c5c5e5 !important;
            }
          }
        }
      }
    }
  }

  .react-calendar__navigation__label {
    flex-grow: 0 !important;
  }
`;

export const ScheduleTable = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ScheduleTableRow = styled.li`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

export const ScheduleTableCellWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 61px;
  flex-shrink: 0;
  @media (max-width: 767px) {
    width: 50px;
  }
`;

export const ScheduleTableCell = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 46px;
  height: 58px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  cursor: pointer;
  flex-shrink: 0;
  transition: all 0.3s ease;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  
  text-align: center;
  position: relative;
  &:hover {
    border-color: #30d15880;
  }
  &.active,
  &.edit {
    background-color: #c9f5d0;
    color: #3fa66e;
  }

  &.chosen {
    background-color: #c9f5d0;
    border: 1px dashed #3fa66e;
    .non-working {
      background-color: #c9f5d0;
      border-color: #c9f5d0;
    }
  }
  @media (max-width: 767px) {
    transition: ${DURATION} ease-in-out;
    &:hover {
      border: none;
    }
    &.active {
      background-color: #a0c5df0d;
      border-color: #a0c5df0d;
      color: ${(props) => props.theme.text.text02};
    }
    &.edit {
      background-color: #f5f5f5;
      color: ${(props) => props.theme.text.text01};
    }
    &.chosen {
      background-color: #4ac99b59;
      border: none;
    }
  }
  &.dark {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0.2);
    &:hover {
      border-color: rgba(0, 0, 0, 0.2);
    }
    .non-working {
      background-color: rgba(0, 0, 0, 0.2);
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
  .non-working {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.04);
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    @media (max-width: 767px) {
      background: transparent;
      border-color: #00000014;
    }
  }
  &.disabled {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: default;
    @media (max-width: 767px) {
      background-color: #a0c5df0d;
      border-color: #a0c5df0d;
    }
    &:hover {
      border-color: rgba(0, 0, 0, 0.04);
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  @media (max-width: 767px) {
    font-size: 9px;
    line-height: 12px;
    font-weight: 500;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const MobileEmployeePosition = styled(FlexWithAlign)`
  padding-block: 2rem;
  max-height: 30rem;
  overflow-y: auto;
  justify-content: flex-start;
  gap: 0;
  > button {
    border-top: 1px solid ${(props) => props.theme.color.base04};
    padding-block: 1rem;
    justify-content: flex-start !important;
    &:last-child {
      border-bottom: 1px solid ${(props) => props.theme.color.base04};
    }
  }
`;
export const TargetElement = styled.div<{ left: string }>`
  position: relative;
  top: 0;
  left: calc((${(props) => props.left} - 1) * 50px);
  width: 46px;
  height: 0;
  visibility: hidden;
  background-color: red;
`;
