import { useQuery } from '@tanstack/react-query';

import { ProductService } from '../../../../shared/api/model/service/product.service';
import { PRODUCTS_QUERY } from '../../../../shared/const/query.const';
import { IProduct } from '../products.interface';

export function useProductsIdsQuery() {
  return useQuery({
    queryKey: [PRODUCTS_QUERY],
    queryFn: () => ProductService.fetchProducts(),
    select: (data: IProduct[]) => data.map((item) => item.id)
  });
}

export function useProductsQuery() {
  return useQuery({
    queryKey: [PRODUCTS_QUERY],
    queryFn: () => ProductService.fetchProducts(),
    select: (data: IProduct[]) => data.sort((a, b) => a.order - b.order)
  });
}
