import { ReactNode } from 'react';

export enum FilterSize {
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs'
}

export interface FilterProps {
  children?: ReactNode;
  $fSize?: FilterSize;
  label?: string | undefined;
  $counter?: number;
  className?: string;
  onClick?: (arg: any) => void;
  color?: 'blue' | 'orange' | 'gray';
}
