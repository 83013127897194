import React from 'react';

export function Success() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.2304 7.31701C18.6076 7.72041 18.5864 8.35322 18.183 8.73043L11.558 16.7304C11.1736 17.0898 10.5765 17.0899 10.1921 16.7305L6.81709 13.6405C6.41365 13.2633 6.39234 12.6305 6.7695 12.2271C7.14667 11.8236 7.77947 11.8023 8.18292 12.1795L10.8749 14.631L16.817 7.26958C17.2204 6.89237 17.8532 6.91361 18.2304 7.31701Z'
        fill='currentColor'
      />
    </svg>
  );
}
