export const fullHd = 48;
export const laptop = 42;
const events = [{ title: 'fdsf', start: getDate('YEAR-MONTH-03') }];
function getDate(dayString: string) {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = '0' + month;
  }

  return dayString.replace('YEAR', year).replace('MONTH', month);
}

export default events;

export const range = (keyCount: any) => Array.from({ length: keyCount }, (_, i) => i);

export const timeStringToNumber = (timeString: string) => {
  const [hours, minutes] = timeString.split(':').map(Number);

  return hours;
};
export const calculateEventWidth = (startTime: number, endTime: number) => {
  const windowWidth = window.innerWidth;
  const minutesDifference = endTime - startTime;
  const baseWidth = windowWidth > 1500 ? fullHd : laptop;
  const additionalWidth = Math.max(0, minutesDifference) * (baseWidth + 11) - (baseWidth + 11);

  return minutesDifference === 1 ? baseWidth : baseWidth + additionalWidth;
};

export const addDateBy = (date: Date, count: number) => {
  const d = new Date(date);

  return new Date(d.setDate(d.getDate() + count));
};

export const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit' };
  const date = new Date(dateString);

  return date.toLocaleDateString(undefined, options).replace(/\//g, '.');
};

export const optionsDateInModal: any = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
};

export const generateDaysArray = (startDate: Date, endDate: Date) => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(currentDate.toDateString());
    currentDate = addDateBy(currentDate, 1);
  }

  return dates;
};
